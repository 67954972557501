import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import Styles from 'components/chatSideBar/chatParticipants/ChatParticipants.module.scss';
import superHostKey from 'assets/images/superhost_user_key.png';
import { IMAGE_URL } from 'store/services/URL';
import { getDistance } from 'helpers/utils';
import { useGetMessages } from 'store/services/firebase-broadcast';
import { updateRoomStatus } from 'store/services/firebase-broadcast';
import moment from 'moment';
import { isArray } from 'lodash';
interface ChatParticipantsProps {
  isSuperHost?: boolean;
  offLineImageCss?: boolean;
  itemIndex: boolean;
  handleChangeIndex: Function;
  id: number;
  item: any;
  activateRoom?: any;
  setActiveRoom?: any;
  callBack?: any;
  selected?: any;
}

const ChatParticipants: FC<ChatParticipantsProps> = (props: ChatParticipantsProps) => {
  const { isSuperHost, offLineImageCss, itemIndex, handleChangeIndex, item,callBack, activateRoom, setActiveRoom, selected} = props;
  const [avatar, setAvatar] = useState('');
  const [name, setName] = useState('');
  // const [activateRoom, setActiveRoom] = useState<any>(null);
  const [hover, setHover] = useState(item?.pin ?? false);
  const user = JSON.parse(localStorage.getItem('user') as string);
  const [chatItem, setChatItem] = useState<any>(0)
  const getAdmin = () => {
    const host = item.host;
    setAvatar(host.profile);
    setName(host.name);
  };

  const chat = useGetMessages(item?.pk);
  let unreadMessage = 0;
  if (chat?.length > 0 && item?.pk !== selected?.pk) {
    let lastSeenMessageId: any;
    // Check if i am host or the participant,then get the lastseen message id
    if (item?.host?.pk === user?.pk) {
      lastSeenMessageId = item?.host?.lastSeenMessage;
    } else {
      if(isArray(item?.participants)) {
        const meUser = item?.participants?.findIndex((p: any) => p?.pk == user?.pk) ?? -1;
        if (meUser !== -1) {
          lastSeenMessageId = item.participants[meUser].lastSeenMessage
        }
      }
    }
    // The message count after the last seen
    let countActive = false;
    chat.reverse().forEach((x: any) => {
      if (countActive) {
        unreadMessage +=1;
      }
      if (x.id === lastSeenMessageId) {
        countActive = true;
      }
    })
    chat.reverse();
  }

  React.useEffect(() => {
    getAdmin();
  }, []);

  const activeRoomFun = async (item: any) => { 
    //  setActiveRoom(item)
  }

  let participant;
  if (item?.participants?.length > 0) {
    participant = item?.participants.find((r: any) => r?.pk === user?.pk)
  }

  // const clickHandler = (item: any) => {
  //   let updateRoom = {
  //     room: item,
  //     pin: !hover,
  //     timeStamp: !hover ? moment().format(): ""
  //   };
  //   updateRoomStatus(updateRoom);
  //   setHover(!hover);
  // };
  return (
    <li>
      <div
        className={`${Styles.listingInner} ${
          itemIndex ? Styles.activeChat : ""
        }`}
        onKeyPress={() => handleChangeIndex(item)}
        onClick={() => (
          handleChangeIndex(item), activeRoomFun(item)//, clickHandler(item)//, callBack(item)
        )}
        tabIndex={0}
        role="button"
      >
        <div className={Styles.userImageWrapper}>
          <img
            src={`${IMAGE_URL}${item?.host?.profile ?? ""}`}
            alt="card img"
            className={`${Styles.userImage} ${
              offLineImageCss ? Styles.offlineUserImage : ""
            }`}
          />
          {
            <img
              src={superHostKey}
              alt="super host"
              className={Styles.superHostImg}
            />
          }
          {/* {isSuperHost && (
            <img
              src={superHostKey}
              alt="super host"
              className={Styles.superHostImg}
            />
          )} */}
        </div>
        <div className={Styles.userDetails}>
          <div className={Styles.userInner}>
            <h5>{item.topic}</h5>
            <p>
              {item?.host?.name ?? ""}
              &lsquo;s Room
            </p>
          </div>

          {
          unreadMessage > 0
          ? (
            <div className={Styles.notifications}>
              <span className={`badge badge-pill ${Styles.badgeDanger}`}>
                {unreadMessage}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </li>
  );
};
export default ChatParticipants;
