import axios from 'axios';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      const user = getItemFromLocalStorage('user');
      if (user) {
        localStorage.clear();
      }
    }
    return Promise.reject(error);
  },
);

export default axios;