import { connect} from 'react-redux';
import { joinRoomRequest } from 'store/actions/conversation.actions';
import { roomJoined } from 'store/selector/conversation.selector';
import NearbyItem from 'views/conversationsNearby/nearbyItem/NearbyItem';

const mapDispatchToProps = { joinRequest: joinRoomRequest };

const mapStateToProps = (state: any) => {
  const isJoined = roomJoined(state);
  return { isJoined };
};

export default connect(mapStateToProps, mapDispatchToProps)(NearbyItem);
