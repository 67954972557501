import React, {FunctionComponent, useEffect, useState} from 'react';
import Styles from 'views/emailNotifications/EmailNotifications.module.scss';
import Button from 'components/button/Button';
import SwitchButton from 'components/button/switchButton/SwitchButton';
import { emailNotifications } from 'store/services/firebase-broadcast';
import _ from 'lodash';
import Toast from 'components/toast/Tost';

interface EmailNotificationProps {
  getSettingRequest: Function;
  updateSetting: Function;
  // loading: boolean;
  setting: boolean;
  settingSuccess: string;
  checkEmailNotify?: any
}

const EmailNotifications: FunctionComponent<EmailNotificationProps> = (props: EmailNotificationProps) => {
  const { settingSuccess, checkEmailNotify } = props;
  const user = JSON.parse(localStorage.getItem('user') || '');
  
  // let notifyBtn: Person =  checkEmailNotify ?? {} 
  const [checked, setChecked] = useState(checkEmailNotify?.checked ?? true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [toast, setToast] = useState(false);
  const [toastValues, setToastValues] = useState({message: '', type: ''});
  const handleCancel = () => {};

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleUpdate = () => {
    setLoading(true)
    setTimeout(() => {
      const data = {
        pk: user.pk,
        email: user.email,
        checked: checked
      }
      emailNotifications(data);
      setToast(true);
      setToastValues({
        message: "Your changes have been saved.",
        type: "Success",
      });  
      setLoading(false)
    }, 2000);
    setShow(true);
  };

  useEffect(() => {
    if (settingSuccess) {
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [loading]);
  return (
    <>
      <div className="borderStyle d-flex column-gap-15 justify-content-between align-items-center">
        <div className="fontSixteen text-black poppins-bold">Turn on all Email notifications?</div>
        <SwitchButton isChecked={checked} handleChange={handleChange} />
      </div>
      {/*{show && <p className="mt-2" style={{color: 'green'}}>{settingSuccess}</p>}*/}
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} showLoading={loading} clickHandler={handleUpdate} />
      </div>
      {toast && (
        <Toast
          message={toastValues.message}
          position="topRight"
          type={toastValues.type}
          handleCloseToast={setToast}
        />
      )}
    </>
  );
};

export default EmailNotifications;
