import userImage from 'assets/images/image1.png';

export const conversations = [
  {
    id: 1,
    title: 'Hiking',
    isSuperHost: true,
    active: true,
    users: [
      {
        id: 1,
        name: 'Lance',
        avatar: userImage,
        isAdmin: true,
      },
      {
        id: 2,
        name: 'Dave',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 3,
        name: 'Kelly',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 4,
        name: 'Leah',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 5,
        name: 'Dennis',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 6,
        name: 'Jenny',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 7,
        name: 'Bryan',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 8,
        name: 'Stan',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 9,
        name: 'Lean',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 10,
        name: 'Jim',
        avatar: userImage,
        isAdmin: false,
      },
    ],
  },
  {
    id: 2,
    title: 'Restaurants in San Francisco',
    isSuperHost: false,
    active: true,
    users: [
      {
        id: 1,
        name: 'Katie',
        avatar: userImage,
        isAdmin: true,
      },
      {
        id: 2,
        name: 'Jamie',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 3,
        name: 'Willliam',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 4,
        name: 'Hannah',
        avatar: userImage,
        isAdmin: false,
      },
    ],
  },
  {
    id: 3,
    title: 'Motorcycles',
    isSuperHost: false,
    active: true,
    users: [
      {
        id: 1,
        name: 'Steve',
        avatar: userImage,
        isAdmin: true,
      },
      {
        id: 2,
        name: 'Grant',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 3,
        name: 'Ben',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 4,
        name: 'Jake',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 5,
        name: 'David',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 6,
        name: 'Rebecca',
        avatar: userImage,
        isAdmin: false,
      },
    ],
  },
  {
    id: 4,
    title: 'Concerts in Central Park',
    isSuperHost: false,
    active: true,
    users: [
      {
        id: 1,
        name: 'Vincent',
        avatar: userImage,
        isAdmin: true,
      },
      {
        id: 2,
        name: 'Cole',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 3,
        name: 'James',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 4,
        name: 'Jane',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 5,
        name: 'Yvonne',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 6,
        name: 'Denver',
        avatar: userImage,
        isAdmin: false,
      },
    ],
  },
  {
    id: 5,
    title: 'Ladies Only',
    isSuperHost: true,
    active: false,
    users: [
      {
        id: 1,
        name: 'Lana',
        avatar: userImage,
        isAdmin: true,
      },
      {
        id: 2,
        name: 'Anna',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 3,
        name: 'Bella',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 4,
        name: 'Vivian',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 5,
        name: 'Mike',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 6,
        name: 'Deepa',
        avatar: userImage,
        isAdmin: false,
      },
    ],
  },
  {
    id: 6,
    title: 'Music',
    isSuperHost: false,
    active: false,
    users: [
      {
        id: 1,
        name: 'Samantha',
        avatar: userImage,
        isAdmin: true,
      },
      {
        id: 2,
        name: 'Johnny',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 3,
        name: 'Zoe',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 4,
        name: 'Kevin',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 5,
        name: 'Alana',
        avatar: userImage,
        isAdmin: false,
      },
    ],
  },
  {
    id: 7,
    title: 'Sound',
    isSuperHost: false,
    active: false,
    users: [
      {
        id: 1,
        name: 'Lance',
        avatar: userImage,
        isAdmin: true,
      },
      {
        id: 2,
        name: 'Dave',
        avatar: userImage,
        isAdmin: false,
      },
      {
        id: 3,
        name: 'Kelly',
        avatar: userImage,
        isAdmin: false,
      },
    ],
  },
];

export const conversation = {
  host: {
    pk: 0,
    name: '',
    email: '',
    role: 0,
    gold_coin: '0',
    silver_coin: '0',
    is_super_host: false,
    latitude: null,
    longitude: null,
    profile: '',
  },
  pk: 0,
  topic: '',
  participants: [],
  latitude: null,
  longitude: null,
  is_deleted: false,
  created_at: '',
  timeStamp: ''
};