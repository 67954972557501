import createSelector from 'utils/reselect';

const userStateData = (state: any) => {
  const { users } = state;
  return users || {};
};

export const getUser = createSelector(userStateData, (users) => {
  const u = users.get('user') || null;
  if (u) {
    return JSON.parse(JSON.stringify(u));
  }
  return u;
});

export const getLoading = createSelector(userStateData, (users) => users.get('loading') || false);

export const getEmailVerified = createSelector(userStateData, (users) => users.get('isEmailVerified') || false);

export const emailVerificationError = createSelector(userStateData, (users) => users.get('emailVerificationError') || '');

export const getOTPVerified = createSelector(userStateData, (users) => users.get('isOTPVerified') || false);

export const otpVerificationError = createSelector(userStateData, (users) => users.get('otpVerificationError') || '');

export const getLoader = createSelector(userStateData, (users) => users.get('loading') || false);

export const getUnSubLoader = createSelector(userStateData, (users) => users.get('unSubLoading') || false);

export const loginError = createSelector(userStateData, (users) => users.get('authError'));

export const getRestoreError = createSelector(userStateData, (users) => users.get('error') || '');

export const getRegistrationConfirm = createSelector(userStateData, (users) => users.get('isConfirm') || false);

export const getResendCodeLoader = createSelector(userStateData, (users) => users.get('resendLoading') || false);

export const getRegistrationMessage = createSelector(userStateData, (users) => users.get('message') || false);

export const getRegisterLoader = createSelector(userStateData, (users) => users.get('isRegisterConfirm') || false);

export const getRestoreEmail = createSelector(userStateData, (users) => users.get('restoreEmail') || false);

export const updateProfile = createSelector(userStateData, (users) => users.get('updateProfile') || '');

export const publicProfile = createSelector(userStateData, (users) => users.get('publicProfile') || {});

export const getRechargePlans = createSelector(userStateData, (users) => users.get('rechargePlans'));

export const getSubscription = createSelector(userStateData, (users) => users.get('subscription'));

export const getCard = createSelector(userStateData, (users) => users.get('card'));

export const getSubscriptionStatus = createSelector(userStateData, (users) => users.get('subscribe' || ''));

export const getRechargeStatus = createSelector(userStateData, (users) => users.get('rechargeStatus'));

export const getUnsubscribeStatus = createSelector(userStateData, (users) => users.get('unsubscribe'));
