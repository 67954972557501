import React, { FC, useEffect, useRef, useState } from 'react';
import attachImage from 'assets/svg/attach file.svg';
import sendImage from 'assets/svg/send_violet.svg';
import Styles from 'components/chatContent/chatFooter/ChatFooter.module.scss';
import { randomString, getCurrentDate } from 'helpers/utils';
import { sendFCMMessage, sendThreadMessage, updateNewRoomStatus, uploadImage } from 'store/services/firebase-broadcast';
import { Attachment } from 'modules/types/types';
import { attachment } from 'helpers/data/general';
import moment from 'moment';
import { invitePeople, invitePeopleRequest } from 'store/actions/general.actions';
import { connect } from 'react-redux';

interface ChatFooterProps {
  typing: Function;
  selected: any;
  chats?: any;
  isThread?: boolean;
  parentMessage?: object;
  images?: Function;
  activateRoom?: any;
  invitePeople: Function;
  attachedImages?: any;
}

const ChatFooter: FC<ChatFooterProps> = (props: ChatFooterProps) => {
  const { typing, selected, chats, isThread, parentMessage, images, activateRoom, invitePeople, attachedImages } = props;
  const [file, setFile] = useState<Attachment>(attachment);
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState<any>([]);
  const [send, setSend] = useState(false);
  const inputRef: any = useRef(null);
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [sender] = useState({
    id: user?.pk,
    name: user.name,
    image: user.profile,
    isHost: user?.pk === selected.host?.pk ?? true,
  });

  const handleMessage = async () => {
    if (isThread) {
      sendThread();
    } else {
      sendMessage();
      let updateRoom = {
        room:activateRoom,
        activeRoom: moment().format()
      };
      updateNewRoomStatus(updateRoom)
    }
  };

  const handleAttachment = (e: any) => {
    if (e.target.files.length > 0) {
      let type = 'image';
      let fileType = e.target.files[0].type;
      fileType = fileType.split('/');
      if (fileType[0] === 'video') type = 'video';
      const obj = {
        id: `${selected?.pk}-${randomString()}`,
        name: e.target.files[0].name,
        path: `${URL.createObjectURL(e.target.files[0])}`,
        file: e.target.files[0],
        type: type,
      };
      setFile(obj);
      setAttachments([...attachments, obj.path]);
      images && images(obj);
    }
  };

  const sendMessage = () => {
    const msg = {
      roomId: selected?.pk,
      id: `${selected?.pk}-${randomString()}`,
      sender: sender,
      receiver: {},
      message: message,
      time: getCurrentDate(),
      is_delete: false,
      attachments: [file],
      threads: [],
    };
    sendFCMMessage(msg).then((e) => console.log());
    if (file.id !== '') {
      uploadImage(msg, file).then()
    }
    setMessage('');
    setFile(attachment);
  };

  const sendThread = () => {
    const { id }: any =  parentMessage;
    const msg = {
      parentId: id,
      roomId: selected?.pk,
      id: `${selected?.pk}-${randomString()}`,
      sender: sender,
      receiver: {},
      message: message,
      time: getCurrentDate(),
      is_delete: false,
      attachments: [],
      threads: [],
    };
    sendThreadMessage(msg).then();
    setMessage('');
  };

  useEffect(() => {
    if (message.length > 0) {
      setSend(true);
      typing(true);
    } else {
      setSend(false);
      typing(false);
    }
  }, [message]);

  useEffect(() => {
    const noFile = attachedImages.filter((i:any) => i.file === inputRef?.current?.files[0]);
    if (noFile.length === 0) {
      inputRef.current.value = '';
    }
  },[attachedImages])
  return (
    <div className={`${Styles.ChatFooter}`}>
      <div className={`${Styles.messageBox} position-relative`}>
        <input
          value={message}
          type="text"
          placeholder="Type your message…"
          className={`w-100 ${Styles.typingArea}`}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className={Styles.attachedFilesWrapper}>
          <div className={`position-relative ${Styles.attachedFiles}`}>
            <input ref={inputRef} type="file" onChange={handleAttachment} />
            {!isThread && <img src={attachImage} alt="send icon" />}
          </div>
        </div>
      </div>
      {send || attachments.length > 0 ? (
        <div onClick={handleMessage} onKeyPress={handleMessage} role="button" tabIndex={0}>
          <img src={sendImage} className={Styles.sendIcon} alt="send icon" />
        </div>
      ) : (
        <div>
          <img src={sendImage} className={Styles.sendIcon} alt="send icon" />
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = { invitePeople: invitePeopleRequest };
export default connect(null, mapDispatchToProps)(ChatFooter);
