import React, { FC, useEffect, useState } from "react";
import Styles from "components/chatSideBar/ChatSideBar.module.scss";
import ChatParticipants from "./chatParticipants/ChatParticipants";
import { getCurrentDate, getDistance } from "helpers/utils";
import { updateNewRoomStatus } from "store/services/firebase-broadcast";
import moment from "moment";

interface ChatSideBarProps {
  cssClass?: string;
  conversations: any;
  setSelected: Function;
  activateRoom?: any;
  setActiveRoom?: any;
  callBack?: any;
  selected?: any;
}
const user = JSON.parse(localStorage.getItem("user") as string);
const ChatSideBar: FC<ChatSideBarProps> = (props: ChatSideBarProps) => {
  const { cssClass, conversations, setSelected, activateRoom, setActiveRoom, callBack, selected } = props;
  const [selectedChat, setSelectedChat] = useState(-1);
  
  const handleChangeChat = (i: any) => {
    callBack(i)
    setSelectedChat(i.pk);
    setSelected(i);
    let updateRoom = {
      room:i,
      activeRoom: moment().format()
    };
    updateNewRoomStatus(updateRoom)
  };

  useEffect(() => {
      setSelectedChat(selected.pk);
  }, [selected])

  let conversationVideo = conversations?.length > 0 ? conversations.filter((r: any) => r.status === 'VIDEO') : []

  return (
    <aside>
      <div className={Styles.nearbyRooms}>
        <h6 className="m-0 font-weight-600">Nearby Rooms</h6>
      </div>
      <div className={Styles.nearbyRoomsListing}>
        <p
          className={`${Styles.status} ${cssClass} ${Styles.success} text-uppercase mb-0`}
        >
          Video
        </p>
        <ul className={`${Styles.activeListing} customScrollBar list-unstyled`}>
          {/* The running conversations */}
          {conversations.map((i: any, index: number) => {
            if (!i.host || i.status !== 'VIDEO') {
              return null;
            }
            return (
              <ChatParticipants
                item={i}
                key={index}
                handleChangeIndex={handleChangeChat}
                itemIndex={i.pk === selectedChat}
                isSuperHost={i.host.is_super_host}
                id={i.pk}
                activateRoom={activateRoom}
                setActiveRoom={setActiveRoom}
                callBack={callBack}
                selected={selected}
              />
            );
          })}
        </ul>
        <p className={`${Styles.notActive} ${Styles.status} text-uppercase mb-0`}>Chat</p>
        <ul className={`${
            conversationVideo?.length < 1
              ? Styles.offlineListingFull
              : conversationVideo?.length === 1
              ? Styles.offlineListingOne
              : conversationVideo?.length === 2
              ? Styles.offlineListingTwo
              : conversationVideo?.length === 3
              ? Styles.offlineListingThree
              : Styles.offlineListing
          }  customScrollBar list-unstyled mb-0`}>
          {/*  Ended conversations */}
        {conversations
         .map((i: any, index: number) => {
          if (!i.host || i.status === 'VIDEO') {
            return null;
          }
          return (
           <ChatParticipants
             item={i}
             key={index}
             handleChangeIndex={handleChangeChat}
             itemIndex={i.pk === selectedChat}
             isSuperHost={i.host.is_super_host}
             id={i.pk}
             offLineImageCss
             selected={selected}
           />
         )
         })}
        </ul>
      </div>
    </aside>
  );
};
export default ChatSideBar;
