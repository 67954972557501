import Mockup1 from 'assets/images/Mockup1.png';
import Mockup2 from 'assets/images/Mockup2.png';
import Mockup3 from 'assets/images/Mockup3.png';
import Mockup4 from 'assets/images/Mockup4.png';
// import laptopHomeImage from 'assets/images/HomeScreenDashboard.png';

export const attachment = {
  id: '',
  name: '',
  path: '',
  file: '',
};

export const demoImages = [
  {
    image: Mockup1,
  },
  {
    image: Mockup2,
  },
  {
    image: Mockup3,
  },
  {
    image: Mockup4,
  }
];
