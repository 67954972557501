import React, {FunctionComponent, useEffect, useState} from 'react';
import Styles from 'views/notifications/Notifications.module.scss';
import Button from 'components/button/Button';
import SwitchButton from 'components/button/switchButton/SwitchButton';
import Toast from 'components/toast/Tost';

interface NotificationProps {
  getSettingRequest: Function;
  updateSetting: Function;
  loading: boolean;
  setting: boolean;
  settingSuccess: string;
}
const Notifications: FunctionComponent<NotificationProps> = (props: NotificationProps) => {
  const { getSettingRequest, updateSetting, loading, setting, settingSuccess } = props;
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [toast, setToast] = useState(false);
  const [toastValues, setToastValues] = useState({message: '', type: ''});
  const handleCancel = () => {};

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleUpdate = () => {
    setShow(true);
    updateSetting({});
    setToast(true);
    setToastValues({
      message: "Your changes have been saved.",
      type: "Success",
    });
  };

  useEffect(() => {
    getSettingRequest({});
  }, []);

  useEffect(() => {
    setChecked(setting);
  }, [setting]);

  useEffect(() => {
    if (settingSuccess) {
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [loading]);
  return (
    <>
      <div className="borderStyle d-flex column-gap-15 justify-content-between align-items-center">
        <div className="fontSixteen text-black poppins-bold">Turn on all notifications?</div>
        <SwitchButton isChecked={checked} handleChange={handleChange} />
      </div>
      {/*{show && <p className="mt-2" style={{color: 'green'}}>{settingSuccess}</p>}*/}
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} showLoading={loading} clickHandler={handleUpdate} />
      </div>
      {toast && (
        <Toast
          message={toastValues.message}
          position="topRight"
          type={toastValues.type}
          handleCloseToast={setToast}
        />
      )}
    </>
  );
};

export default Notifications;
