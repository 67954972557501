import React, { FC } from 'react';
import Styles from 'components/rechargeAccountItem/RechargeAccountItem.module.scss';

interface rechargeAccountProps {
  item: any;
  selected: any;
  handleSelect: Function;
}
const RechargeAccountItem: FC<rechargeAccountProps> = (props: rechargeAccountProps) => {
  const { item, selected, handleSelect } = props;
  return (
    <>
      <div
        className={`${Styles.rechargeCardInner} ${selected === item.pk && Styles.blueBorder}`}
        onClick={() => handleSelect(item.pk)}
        onKeyPress={() => handleSelect(item.pk)}
        role="button"
        tabIndex={0}
      >
        {item.discount.isDiscount && (
          <div className={Styles.saveText}>
            SAVE &nbsp;
            {item.discount.amount}
          </div>
        )}
        <h6>{item.plan}</h6>
        <img src={item.image} alt="gold piece" />
        <p>{item.price}</p>
      </div>
    </>
  );
};
export default RechargeAccountItem;
