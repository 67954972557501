import { JitsiMeeting } from '@jitsi/web-sdk/lib/components';
import { connect } from 'react-redux';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { User } from 'modules/user/types';
import participantImg from 'assets/svg/user_white.svg';
import CustomModal from 'components/customModal/CustomModal';
import InviteFriend from 'views/inviteFriend';
import { useHistory } from 'react-router';
import { deleteRoom, updateParticipantsInRoom, updateCallStatus, useGetCallStatus } from 'store/services/firebase-broadcast';
import Styles from 'components/Jitsi/Jitsi.module.scss';
import Button from 'components/button/Button';
import { IMAGE_URL } from 'store/services/URL';
import { setLoggedTimeRequest } from 'store/actions/general.actions';
import { useDispatch } from 'react-redux';
import { getLoggedTimeStatus } from 'store/selector/general.selector';
import api from 'store/services/conversation.service';

type participant = { id: string; name: string };

interface JitsiProps {
  user: User;
  broadCast: any;
  handleClose: Function;
  loggedTime?: string;
}

const Jitsi: FunctionComponent<JitsiProps> = (props: JitsiProps) => {
  const { user, broadCast, handleClose, loggedTime } = props;
  const dispatch = useDispatch();
  const room = broadCast;
  const apiRef = useRef<any>();
  const [host, setHost] = useState<any>({});
  const [name, setName] = useState('');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [confirmEndCall, setConfirmEndCall] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(true);
  const [logItems, updateLog] = useState<string[]>([]);
  const [noOfParticipants, setNoOfParticipants] = useState(0);
  const [knockingParticipants, updateKnockingParticipants] = useState<participant[]>([]);
  const history = useHistory();
  const [token, setToken] = useState(null);
  const requestRef = useRef<any>();

  const closeDialog = () => {
    setShowPopup(false);
    setConfirmEndCall(false);
  };

  const handleBack = () => {
    setConfirmEndCall(true);
  };

  const handleConfirmLeft = () => {
    setConfirmEndCall(false);
    handleLoggedTime();
    handleReadyToClose();
  };

  useEffect(() => {
    setHost(room.host);
  }, [room]);

  useEffect(() => {
    if (host !== undefined) {
      setName(host.name);
    }
  }, [host]);

  useEffect(() => {
    // get the token and then join the room
    if (token === null 
      && !requestRef.current 
      && broadCast 
      && broadCast.topic 
      && host 
      && user) {
      requestRef.current = true;
       api.getRoomToken({
         moderator: host.pk === user.pk ? 'owner' : 'member',
         room: broadCast.topic
       }).then((res) => {
         setToken(res.meeting_token);
         requestRef.current = false;
       })
    }
  },[broadCast, host, user]);

  useEffect(() => () => {
        if (apiRef && apiRef.current) {
          apiRef.current.dispose();
        }
      }, []);

  const endCallModal = () => {
    return (
      <div className={Styles.modalWrapper}>
        <h4 className={Styles.description}>Are you sure you want to close this room?</h4>
        <Button label="Confirm" clickHandler={handleConfirmLeft} />
        <div onClick={closeDialog} onKeyPress={closeDialog} tabIndex={0} role="button">
          <p className="text-uppercase text-decoration-none mt-2 d-inline-block text-blue font-weight-600 fontFourteen">
            Close
          </p>
        </div>
      </div>
    );
  };

  const printEventOutput = (payload: any) => {
    updateLog((items: string[]) => [...items, JSON.stringify(payload)]);
  };

  const handleAudioStatusChange = (payload: any, feature: any) => {
    if (payload.muted) {
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  const handleChatUpdates = (payload: any, ref: any) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    ref.current.executeCommand('toggleChat');
    updateLog((items: string[]) => [...items, `you have ${payload.unreadCount} unread messages`]);
  };

  const handleKnockingParticipant = (payload: participant) => {
    updateLog((items: any) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants: participant[]) => [...participants, payload]);
  };

  const handleParticipantLeft = (payload: participant) => {
    console.log('Left Participant', payload);
    // setNoOfParticipants(noOfParticipants - 1);
  };

  const handleParticipantJoined = (payload: participant) => {
    // setNoOfParticipants(noOfParticipants + 1);
    console.log('Participant Joined', payload, user);
  };

  const resolveKnockingParticipants = async () => {
    const data: any = apiRef.current.getParticipantsInfo();
    // console.log('[resolveKnockingParticipants]', ref, condition);
    // knockingParticipants.forEach((p: participant) => {
    //   ref.current.executeCommand('answerKnockingParticipant', p?.id, condition(p));
    //   updateKnockingParticipants((participants) => participants.filter((item) => item.id === p.id));
    // });
  };

  const handleJitsiIFrameRef1 = (iframeRef: any) => {};

  const handleReadyToClose = () => {
    if (user.pk === host.pk) {
      handleLoggedTime();
      handleClose();
    } else {
      // handleClose();
      handleLoggedTime();
    }
  };

  const handleLoggedTime = () => {
    const startTime = localStorage.getItem('start_time');
    if (startTime !== null) {
      const endTime = +new Date();
      const totalTime =  endTime - parseInt(startTime);
      dispatch(setLoggedTimeRequest({
        total_spend_time:	Math.floor(totalTime / 60000),
        room_id: room.pk.toString(),
      }));
    }
    localStorage.removeItem('start_time');
    // history.push('/conversations-nearby');
    // window.location.reload();
  };

  const handleJoinParticipant = (e: any) => {
    const participants = broadCast.participants;
    const obj = {
      roomId: room.pk,
      user: [...participants,user],
    }
    if (host.pk !== user.pk) {
      updateParticipantsInRoom(obj).then((e) => console.log('Participant Added', e));
    }
  };

  useEffect(() => {
    if(loggedTime) {
      history.push('/conversations-nearby');
      window.location.reload();
    }
  }, [loggedTime])

  const handleVideoConferenceLeft = (e: any) => {
    setShowChat(false);
    if (e.pk) {
      handleClose();
    }
  };

  const handleParticipantKickedOut = (e: any) => {
    handleLoggedTime();
    history.push('/conversations-nearby');
    window.location.reload();
  };

  const handleApiReady = (apiObj: any, ref: any) => {
    const reff: any = ref;
    reff.current = apiObj;
    reff.current.addEventListeners({
      // Listening to events from the external API
      audioMuteStatusChanged: (payload: any) => handleAudioStatusChange(payload, 'audio'),
      videoMuteStatusChanged: (payload: any) => handleAudioStatusChange(payload, 'video'),
      raiseHandUpdated: printEventOutput,
      tileViewChanged: printEventOutput,
      chatUpdated: (payload: any) => handleChatUpdates(payload, ref),
      knockingParticipant: handleKnockingParticipant,
      participantJoined: handleParticipantJoined,
      participantLeft: handleParticipantLeft,
      readyToClose: handleReadyToClose,
      participantKickedOut: handleParticipantKickedOut,
      videoConferenceLeft: handleVideoConferenceLeft,
    });
    apiObj.executeCommands({
      // displayName: user.name,
      avatarUrl: `${IMAGE_URL}${user.profile}`,
    });

    apiObj.addEventListener('videoConferenceJoined', (e: any) => {
      if (host.pk !== user.pk) {
        const checkParticipant = !!broadCast?.participants.find((item: any) => item?.pk === user?.pk);
        if (!checkParticipant){
          handleJoinParticipant(e);
        }
      }
      setNoOfParticipants(Object.keys(apiObj._participants).length);
    });
  };

  useEffect(() => console.log('Local User Joined', noOfParticipants), [noOfParticipants])
  return (
    token ?
    <div className="jitsiMeetingWrapper">
      {(
        <div className="d-flex align-items-center justify-content-between">
          <div className={`d-flex align-items-center`}>
            <i className={`pr-2 fas fa-chevron-left broadcastBackIcon`} onClick={handleBack} onKeyUp={handleBack} tabIndex={0} role="button" />
            <p className="mb-0 broadcastRoomName">{room.topic}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center" onClick={() => setShowPopup(true)} onKeyUp={() => setShowPopup(true)} tabIndex={0} role="button">
              <p className={`mb-0 broadCastInviteFriend`}>INVITE FRIENDS</p>
              <i className={`broadcastPlusIcon fa fa-plus`} />
            </div>
            <div className='line'>|</div>
            <div className="hostWrapper">
              <p className="mb-0 hostName">Host: {name}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center participant-count">
              <img src={participantImg} className="participantIcon" alt="" />
              <p className="mb-0 participants">{noOfParticipants}</p>
            </div>
          </div>
        </div>
      )}
      <JitsiMeeting
        domain="broadcast.socialplay.com"
        roomName={`${room.topic}?jwt=${token}&token=${user.access}&roomId=${room.pk}&userID=${user.pk}`}
        configOverwrite={{
          disableModeratorIndicator: true,
          prejoinPageEnabled: false,
          disableDeepLinking: true,
          disableKick: true,
        }}
        interfaceConfigOverwrite={{ SHOW_JITSI_WATERMARK: false }}
        onApiReady={(externalApi) => handleApiReady(externalApi, apiRef)}
        getIFrameRef={handleJitsiIFrameRef1}
      />
      {showPopup && (
        <CustomModal heightClass="inviteFriendModal">
          <InviteFriend cssClass="inviteFriendPopupWrapper" isPopup={showPopup} closePopup={closeDialog} />
        </CustomModal>
      )}
      {confirmEndCall && (
        <CustomModal heightClass="inviteFriendModal" closeDialog={closeDialog}>
          {endCallModal()}
        </CustomModal>
      )}
    </div> : null
  );
};

const mapStateToProps = (state: any) => {
  const loggedTime = getLoggedTimeStatus(state);
  return { loggedTime };
};

export default connect(mapStateToProps, null)(Jitsi);
