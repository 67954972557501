import React, { FC, useEffect, useState } from 'react';
import replyIcon from 'assets/svg/reply icon.svg';
import deleteIcon from 'assets/svg/delete icon.svg';
import pin from 'assets/svg/pin.svg';
import Styles from 'components/chatContent/messageBox/MessageBox.module.scss';
import { IMAGE_URL } from 'store/services/URL';
import { deleteMessage, getImages, deleteImage, updateMessageStatus } from 'store/services/firebase-broadcast';
import moment from 'moment';

interface MessageBoxProps {
  cssClass?: string;
  item: any;
  index?: number;
  viewReplies?: Function;
  replyItems?: Function;
  actions: boolean;
  selected?: any;
  type: string;
  chatsPin?: any;
  pinCount?: any;
}

const MessageBox: FC<MessageBoxProps> = (props: MessageBoxProps) => {
  const { cssClass, item, viewReplies, replyItems, actions, selected, type, chatsPin, pinCount } = props;
  const [fileType, setFileType] = useState('image');
  const [attachment, setAttachment] = useState('');
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [hover, setHover] = useState(item?.pin ?? false);
  const handleShowReplies = () => {
    if (viewReplies) viewReplies(true);
    if (replyItems) replyItems(item);
  };

  const getTime = (time: string) => {
    // const amPm = time.split(' ');
    // const timeStr = time.split(':');
    // return timeStr[0] + ':' + timeStr[1] + ' ' + amPm[1];
    if (time) {
       return new Date(time).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
       });
    } else {
      return '';
    }
  };

  const handleDelete = () => {
    const msgPath = `${selected.pk}/messages/${item.id}`;
    if (item.attachments.length > 0) {
      const imgPath = `${selected.pk}/${item.attachments[0].id}`;
      deleteImage(imgPath).then();
    }
    deleteMessage(msgPath).then();
    return '';
  };

  const handleRemoveParticipant = () => {
    const msgPath = `${selected.pk}/participants/${item.id}`;
    deleteMessage(msgPath).then();
    return '';
  };

  useEffect(() => {
    if (type !== 'threads') {
      if (item.attachments[0].id !== '') {
        getImages(selected.pk, item.attachments[0].id).then((e) => {
          if (e !== undefined) {
            e.length > 0 && setAttachment(e[0])
          }
        });
      }
    }
  }, [item]);

  useEffect(() => {
    const fileName = item.attachments[0].name;
    // const ext = fileName.substring(fileName.lastIndexOf('.'));
    const ext = fileName.split('.').pop();
    if (ext === 'MP4') {
      setFileType('video');
    }
  }, [item]);

  useEffect(() => {
    setAttachment(attachment);
  }, [attachment]);

  const OnPressPin = () => {
    if (user.pk !== selected.host.pk) {
      return;
    }
    if ( chatsPin?.length < 3 || hover === true ) {
      const data = {
        roomId: selected.pk,
        pin: !hover,
        timeStamp: !hover ? moment().format(): "",
        messageId: item.id
      };
      updateMessageStatus(data)
    };
    setHover(!hover);
  }
  
  return (
    <>
      <div className={`${item.pin === true ? Styles['pinned'+pinCount]: ''}  ${Styles.message} ${cssClass} ${!actions && Styles.padding}`} >
        {!actions && (
          <div className={Styles.actions}>
            <img src={replyIcon} alt="reply icon" onClick={handleShowReplies} onKeyUp={handleShowReplies} role="button" tabIndex={0} />
            {(item.sender.id === user.pk || user.pk === selected.host.pk) && <img src={deleteIcon} alt="reply icon" onClick={handleDelete} onKeyUp={handleDelete} role="button" tabIndex={0} />}
            {/* {(user.pk === selected.host.pk) && <a onClick={handleRemoveParticipant} onKeyUp={handleRemoveParticipant} role="button" tabIndex={0} >Remove</a>} */}
          </div>
        )}
        <img src={`${IMAGE_URL}${item.sender.image}`} alt="user" className={Styles.userImage} />
        <div className={Styles.userDetails}>
          <div className={Styles.detailsInner}>
            <h5 className="my-0">{item.sender.name}</h5>
            <span className="fontFifteen font-weight-normal input-color">{getTime(item.createdTime)}</span>
            {item.sender.isHost && <span className={`badge text-white ${Styles.hostBadge}`}>Host</span>}
            <div onClick={OnPressPin} onKeyPress={OnPressPin} role="button" tabIndex={0}>
                <img className={`${item.pin ? Styles.pin : null} ${user.pk === selected.host.pk && Styles.hoverPin} `} src={pin} alt="pin"/>
            </div>
          </div>
          <p className="fontFifteen text-black font-weight-normal mb-0">{item?.message}</p>
          {item.threads && item.threads.length > 0 && (
            <div onClick={handleShowReplies} onKeyPress={handleShowReplies} role="button" tabIndex={0}>
              <h6 className="text-uppercase fontFourteen mb-0 pt-2 text-blue font-weight-500">View Replies</h6>
            </div>
          )}
          {attachment && (
            <div className="d-flex flex-column">
              <span className={Styles.uploadedImageDetails}>{item.attachments[0].name}</span>
              {fileType === 'image' ? (
                <img src={attachment} alt="attachment" className={Styles.uploadedImage} />
              ) : (
                <video className={Styles.uploadedVideo} controls>
                  <source src={attachment} type="video/mp4" />
                </video>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MessageBox;
