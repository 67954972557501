import { connect } from 'react-redux';
import ConversationsNearby from 'views/conversationsNearby/ConversationsNearby';
import { getAllRoomsRequest } from 'store/actions/conversation.actions';
import { nearbyRoomsRequest } from 'store/actions/conversation.actions';
import { createLocationRequest } from 'store/actions/general.actions';
import { joinRoomRequest } from 'store/actions/conversation.actions';
import { getRooms, getCreatedRoom, getNearByRooms, getLoading } from 'store/selector/conversation.selector';
import { followProfileBegin, getPublicProfileBegin } from 'store/actions/auth.actions';
import { publicProfile } from 'store/selector/auth.selector';

const mapDispatchToProps = {
  getRoom: getAllRoomsRequest,
  createLocation: createLocationRequest,
  nearbyRooms: nearbyRoomsRequest,
  joinRequest: joinRoomRequest,
  getPublicProfile: getPublicProfileBegin,
  followProfile: followProfileBegin,
};

const mapStateToProps = (state: any) => {
  const rooms = getRooms(state);
  const createdRoom = getCreatedRoom(state);
  const allNearByRooms = getNearByRooms(state);
  const loader = getLoading(state);
  const userPublicProfile = publicProfile(state);
  return { rooms, createdRoom, allNearByRooms, loader, userPublicProfile };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsNearby);
