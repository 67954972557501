import { call, put, all, takeEvery } from 'redux-saga/effects';
import { conversationActions, authActions } from 'store/actions';
import { createRoom as roomCreate, updateRoomStatus } from 'store/services/firebase-broadcast';
import ACTIONS from 'store/constants';
import ApiResponse from 'modules/response/response.types';
import {
  ICreateRoomParams,
  INearByRoomsParams, IRoomStatusParams,
  IURLIdParams,
} from 'modules/params/param.type';
import api from 'store/services/conversation.service';
import moment from 'moment';

function* createRoom(state: { type: string; payload: ICreateRoomParams }) {
  try {
    yield put(conversationActions.createRoom.pending);
    const response: ApiResponse = yield call(api.createRoomRequest, state.payload);
    if (response) {
      // const data = JSON.stringify(response.data);
      // const user = JSON.parse(localStorage.getItem('user') || '');
      // const payload = {
      //   profile_id: user.pk.toString(),
      //   room_id: JSON.parse(data).pk.toString(),
      // };
      // yield put(authActions.createPublicProfileRequest(payload));
      const room: any = response.data;
      const obj = {
        timeStamp: "new Date()",
        pk: room.pk,
        topic: room.topic,
        host: {
          pk: room.host.pk,
          name: room.host.name,
          email: room.host.email,
          role: room.host.role,
          profile: room.host.profile,
          latitude: room.host.latitude,
          longitude: room.host.longitude,
          silver_coin: room.host.silver_coin,
          gold_coin: room.host.gold_coin,
          is_super_host: room.host.is_super_host,
        },
        participants: room.participants,
        latitude: room.latitude === null ? "null" : room.latitude,
        longitude: room.longitude === null ? "null" : room.longitude,
        created_at: moment().format(), //room.created_at,
        is_deleted: room.is_deleted,
        status: room.status,
      }
      
      yield roomCreate(obj).then(() => console.log('Created'));
      yield put(conversationActions.createRoom.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.createRoom.error(error.message));
  }
}

function* getAllRooms() {
  try {
    yield put(conversationActions.fetchAllRooms.pending);
    const response: ApiResponse = yield call(api.getAllRoomsRequest);
    if (response) {
      yield put(conversationActions.fetchAllRooms.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.fetchAllRooms.error(error.message));
  }
}

function* getRoom() {
  try {
    yield put(conversationActions.fetchRoom.pending);
    const response: ApiResponse = yield call(api.getRoomRequest);
    if (response) {
      yield put(conversationActions.fetchRoom.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.fetchRoom.error(error.message));
  }
}

function* joinRoom(state: { type: string; payload: IURLIdParams }) {
  try {
    yield put(conversationActions.joinRoom.pending);
    const response: ApiResponse = yield call(api.joinRoomRequest, state.payload);
    if (response) {
      yield put(conversationActions.joinRoom.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.joinRoom.error(error.message));
  }
}

function* nearByRoom(state: { type: string; payload: INearByRoomsParams }) {
  try {
    yield put(conversationActions.nearbyRooms.pending);
    const response: ApiResponse = yield call(api.nearByRoomsRequest, state.payload);
    if (response) {
      yield put(conversationActions.nearbyRooms.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.nearbyRooms.error(error.message));
  }
}

function* roomStatus(state: { type: string; payload: IRoomStatusParams }) {
  try {
    yield put(conversationActions.roomStatus.pending);
    const response: ApiResponse = yield call(api.roomStatusRequest, state.payload);
    if (response) {
      yield updateRoomStatus(state.payload).then(() => console.log('Status updated!'));
      yield put(conversationActions.roomStatus.success(response));
    }
  } catch (error: any) {
    yield put(conversationActions.roomStatus.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.CREATE_ROOM_BEGIN, createRoom),
    takeEvery(ACTIONS.NEARBY_ROOMS_BEGIN, nearByRoom),
    takeEvery(ACTIONS.ROOM_LIST_BEGIN, getAllRooms),
    takeEvery(ACTIONS.CREATE_ROOM_FETCH_BEGIN, getRoom),
    takeEvery(ACTIONS.JOIN_ROOM_BEGIN, joinRoom),
    takeEvery(ACTIONS.ROOM_STATUS_BEGIN, roomStatus),
  ]);
}
