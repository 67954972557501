export const date = [
  { format: 'YYYY/MM/DD', value: 'YYYY/MM/DD', eg: 'eg. 2020/12/25' },
  { format: 'MM/DD/YYYY', value: 'MM/DD/YYYY', eg: 'eg. 12/25/2020' },
  { format: 'DD/MM/YYYY', value: 'DD/MM/YYYY', eg: 'eg. 25/12/2020' },
  { format: 'MMM DD, YYYY', value: 'MMM DD, YYYY', eg: 'eg. Dec 25, 2020' },
];

export const time = [
  { format: 'HH:MM', value: 'HH:MM', detail: 'Time is based on a 24 hour system.' },
  { format: 'HH:MM XM', value: 'HH:MM XM', detail: 'Time is based on a 12 hour system. AM or PM is given' },
];