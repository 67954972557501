import React, { FunctionComponent } from 'react';
import Styles from 'components/customModal/CustomModal.module.scss';
import crossImage from 'assets/images/cross.png'

interface CustomModalProps {
  closeDialog?: (event: React.FormEvent<HTMLDivElement>) => void;
  cssClass?: string;
  wrapperClass?: string;
  heightClass?: string;
  title?: string;
  children: any;
  modalTitleClass?: string;
  modalTitle?: string;
  closeModal?: (event: React.FormEvent<HTMLDivElement>) => void;
  close?: boolean;
}

const CustomModal: FunctionComponent<CustomModalProps> = (props: CustomModalProps) => {
  const { children, cssClass, closeDialog, title, wrapperClass, heightClass, modalTitleClass, modalTitle, closeModal, close } = props;
  return (
    <>
      <section className={`${Styles.modalWrapper} ${wrapperClass}`}>
        <div className={Styles.modalContent}>
          {close && (
            <div
              className="d-flex flex-row-reverse"
              onClick={closeModal}
              onKeyPress={closeModal}
              role="button"
              tabIndex={0}
            >
              <img
                src={crossImage}
                alt="super host"
                className={Styles.superHostImg}
              />
            </div>
          )}
          <div className={Styles.modalHeader}>
            <div className={`font-weight-600 ${Styles.modalFirstTitle} ${modalTitleClass} text-center`}>{modalTitle}</div>
            <h4 className={`font-weight-600 ${Styles.modalTitle} ${modalTitleClass} text-center`}>{title}</h4>
          </div>
          <div className={`${Styles.modalBody} ${cssClass} ${heightClass} customBodyScroll`}>{children}</div>
        </div>
      </section>
      <div className={Styles.modalBgLayer} onClick={closeDialog} onKeyPress={closeDialog} role="button" tabIndex={0} />
    </>
  );
};

export default CustomModal;
