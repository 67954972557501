import {
  IEmailVerificationParams,
  IOTPVerificationParams,
  ILoginParams,
  IPublicProfileParams,
  IRegisterParams,
  IProfileParams,
  IRechargeAccountParams,
  ISubscribeParams,
  ICreateCardParams,
  IFollowProfileParams,
  IUnsubscribeParams,
} from 'modules/params/param.type';
import { User } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/constants';

export const loginAttempt = {
  pending: {
    type: ACTIONS.LOGIN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.LOGIN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOGIN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const verifyEmailRequest = (state: IEmailVerificationParams) => ({
  type: ACTIONS.VERIFY_EMAIL_CALL,
  payload: state,
});

export const verifyEmail = {
  pending: (data: any) => ({
    type: ACTIONS.VERIFY_EMAIL.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.VERIFY_EMAIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.VERIFY_EMAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const verifyOTPRequest = (state: IOTPVerificationParams) => ({
  type: ACTIONS.VERIFY_OTP_CALL,
  payload: state,
});

export const verifyOTP = {
  pending: (data: any) => ({
    type: ACTIONS.VERIFY_OTP.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.VERIFY_OTP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.VERIFY_OTP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const logoutSucceed = () => ({
  type: ACTIONS.AUTH_LOGOUT,
});

export const callLogout = () => ({
  type: ACTIONS.AUTH_LOGOUT_BEGIN,
});

export const authenticatedUser = (state: User) => ({
  type: ACTIONS.AUTHENTICATED_USER,
  data: state,
});

export const getLoginRequest = (state: ILoginParams) => ({
  type: ACTIONS.LOGIN_CALL,
  payload: state,
});

export const userRegistrationBegin = (state: IRegisterParams) => ({
  type: ACTIONS.SIGNUP_CALL,
  payload: state,
});

export const registerUser = {
  pending: {
    type: ACTIONS.SIGNUP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.SIGNUP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SIGNUP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateProfileRequest = (state: IProfileParams) => ({
  type: ACTIONS.UPDATE_PROFILE_BEGIN,
  payload: state,
});

export const updateProfile = {
  pending: (data: any) => ({
    type: ACTIONS.UPDATE_PROFILE.PENDING,
    data,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.UPDATE_PROFILE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteProfileRequest = () => ({
  type: ACTIONS.DELETE_PROFILE_BEGIN,
});

export const deleteProfile = {
  pending: {
    type: ACTIONS.DELETE_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.DELETE_PROFILE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createPublicProfileRequest = (payload: IPublicProfileParams) => ({
  type: ACTIONS.CREATE_PUBLIC_PROFILE_BEGIN,
  payload,
});

export const createPublicProfile = {
  pending: {
    type: ACTIONS.CREATE_PUBLIC_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_PUBLIC_PROFILE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_PUBLIC_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const rechargeAccountBegin = (payload: IRechargeAccountParams) => ({
  type: ACTIONS.RECHARGE_ACCOUNT_BEGIN,
  payload,
});

export const rechargeAccount = {
  pending: {
    type: ACTIONS.RECHARGE_ACCOUNT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.RECHARGE_ACCOUNT.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.RECHARGE_ACCOUNT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPlansRequestBegin = () => ({
  type: ACTIONS.GET_RECHARGE_ACCOUNT_BEGIN,
});

export const getPlansRequest = {
  pending: {
    type: ACTIONS.GET_RECHARGE_ACCOUNT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.GET_RECHARGE_ACCOUNT.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_RECHARGE_ACCOUNT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSubscriptionRequestBegin = () => ({
  type: ACTIONS.GET_SUBSCRIPTION_BEGIN,
});

export const getSubscriptionRequest = {
  pending: {
    type: ACTIONS.GET_SUBSCRIPTION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.GET_SUBSCRIPTION.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_SUBSCRIPTION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const subscribeBegin = (payload: ISubscribeParams) => ({
  type: ACTIONS.SUBSCRIBE_BEGIN,
  payload,
});

export const subscribe = {
  pending: {
    type: ACTIONS.SUBSCRIBE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.SUBSCRIBE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SUBSCRIBE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCardRequestBegin = () => ({
  type: ACTIONS.GET_CARD_BEGIN,
});

export const getCardRequest = {
  pending: {
    type: ACTIONS.GET_CARD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.GET_CARD.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GET_CARD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createCardBegin = (payload: ICreateCardParams) => ({
  type: ACTIONS.CREATE_CARD_BEGIN,
  payload,
});

export const createCard = {
  pending: {
    type: ACTIONS.CREATE_CARD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_CARD.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_CARD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPublicProfileBegin = (payload: ICreateCardParams) => ({
  type: ACTIONS.PUBLIC_PROFILE_BEGIN,
  payload,
});

export const getPublicProfile = {
  pending: {
    type: ACTIONS.PUBLIC_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.PUBLIC_PROFILE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.PUBLIC_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const followProfileBegin = (payload: IFollowProfileParams) => ({
  type: ACTIONS.FOLLOW_PROFILE_BEGIN,
  payload,
});

export const followProfile = {
  pending: {
    type: ACTIONS.FOLLOW_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.FOLLOW_PROFILE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.FOLLOW_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const unsubscribeBegin = () => ({
  type: ACTIONS.UNSUBSCRIBE_BEGIN,
});

export const unsubscribe = {
  pending: {
    type: ACTIONS.UNSUBSCRIBE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UNSUBSCRIBE.SUCCESS,
    data,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UNSUBSCRIBE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
