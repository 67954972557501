import goldPieceIcon from 'assets/svg/gold-piece.svg';
import goldPiecesIcon from 'assets/svg/gold-pieces.svg';

const subscriptions = [
  {
    pk: '2',
    plan: '1 gold Piece',
    image: goldPieceIcon,
    price: '$1.00',
    discount: {
      amount: '',
      isDiscount: false,
    },
  },
  {
    pk: '1',
    plan: '10 gold Pieces',
    image: goldPiecesIcon,
    price: '$9.00',
    discount: {
      amount: '10%',
      isDiscount: true,
    },
  },
];
export default subscriptions;
