import createSelector from 'utils/reselect';

const userStateData = (state: any) => {
  const { general } = state;
  return general || {};
};

export const getLoading = createSelector(userStateData, (general) => general.get('loading') || false);

export const getInvitation = createSelector(userStateData, (general) => general.get('invitation'));

export const getFeedBack = createSelector(userStateData, (general) => general.get('feedback'));

export const getNotificationSetting = createSelector(userStateData, (general) => general.get('notificationSetting'));

export const getUpdatedNotificationSetting = createSelector(userStateData, (general) => general.get('updateNotification'));

export const getDateTimeSetting = createSelector(userStateData, (general) => general.get('dateTimeSetting'));

export const getUpdatedDateTimeSetting = createSelector(userStateData, (general) => general.get('updateDateTime'));

export const getLoggedTimeStatus = createSelector(userStateData, (general) => general.get('loggedTime' || false));

