import React, {FunctionComponent, Suspense, useEffect, useState} from 'react';
import Button from 'components/button/Button';
import superHostIcon from 'assets/svg/superhost-subscription.svg';
import Styles from 'views/subscription/Subscription.module.scss';
import CustomModal from 'components/customModal/CustomModal';
import { Subscription, CardList } from 'modules/types/types';
const Stripe = React.lazy(() => import('views/Stripe/Stripe'));

interface SubscriptionProps {
  getSubscriptionRequest: Function;
  getCardRequest: Function;
  subscriptions: Subscription;
  card: CardList;
  loading: boolean;
  unSubLoading: boolean;
  unsubscribe: Function;
  unsubscribeStatus: string;
}
const Subscriptions: FunctionComponent<SubscriptionProps> = (props: SubscriptionProps) => {
  const { getSubscriptionRequest, getCardRequest, subscriptions, card, loading, unSubLoading, unsubscribe, unsubscribeStatus } = props;
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const [unsubscriptionStatus, setUnsubscriptionStatus] = useState('');
  const user = JSON.parse(localStorage.getItem('user') as string);

  useEffect(() => {}, [user]);

  const subscriptionPopUp = () => {
    document.body.classList.add('modal-open');
    return (
      <div className="text-center">
        <p className="fontSixteen font-weight-normal text-black my-4">
          Proceed to payment to complete your
          <br />
          transaction.
        </p>
        <Button label="PROCEED TO PAYMENT MERCHANT" clickHandler={handlePaymentMerchant} />
        <div
          className="fontSixteen text-blue font-weight-600 text-uppercase mt-3"
          onClick={closeDialog}
          onKeyPress={closeDialog}
          role="button"
          tabIndex={0}
        >
          Close
        </div>
      </div>
    );
  };

  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setShowSubscribe(false);
    setPaymentPopUp(false);
    setUnsubscriptionStatus('');
  };

  const handlePaymentMerchant = () => {
    setShowSubscribe(false);
    setPaymentPopUp(true);
  };

  const stripePaymentModal = () => {
    document.body.classList.add('modal-open');
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Stripe type="subscribe" planId='' card={card} subscription={subscription} closeDialog={closeDialog} />
      </Suspense>
    );
  };

  const handleUnsubscribe = () => {
    unsubscribe({});
  };

  useEffect(() => {
    getSubscriptionRequest({});
  }, []);

  useEffect(() => {
    getCardRequest({});
  }, []);

  useEffect(() => {
    setSubscription(subscriptions);
  }, [subscriptions]);

  useEffect(() => {}, [card]);

  useEffect(() => {
    setUnsubscriptionStatus(unsubscribeStatus);
  }, [unsubscribeStatus]);

  return (
    <>
      <div className="pb-5">
        <div className="borderStyle text-center">
          <img src={superHostIcon} className={Styles.superHostImage} alt="super host icon" />
          {user && user.is_super_host ? (
            <>
              <h2 className="text-black poppins-bold my-4">You are a Super Host!</h2>
              <p className="text-black fontTwenty font-weight-normal mb-0">
                You have a Master Key.
              </p>
            </>
          ) : (
            <>
            <h2 className="text-black poppins-bold my-4">Subscribe to be a Super Host!</h2>
            <p className="text-black fontTwenty font-weight-normal mb-0">
            Subscribing to be a Super Host will get you a Master Key.
            </p>
            </>
          )}
          <ul className={`list-unstyled ${Styles.listingWrapper}`}>
            <li>Waves all fees for guests joining your rooms.</li>
            <li>Join any room for free with host approval.</li>
            <li>Create group chats for your room.</li>
          </ul>
          {/*<Button cssClass="mt-4" label="SUBSCRIBE FOR $10 A MONTH" clickHandler={() => setShowSubscribe(true)} />*/}
          {user && user.is_super_host ? (
            <>
              <Button showLoading={unSubLoading} cssClass="mt-4 unsubscribe" label="UNSUBSCRIBE" clickHandler={handleUnsubscribe} />
              {unsubscribeStatus && <p>{unsubscriptionStatus}</p>}
            </>
            ) : (
            <Button cssClass="mt-4" label="SUBSCRIBE FOR $10 A MONTH" clickHandler={() => setShowSubscribe(true)} />
          )}
        </div>
      </div>
      {showSubscribe && (
        <CustomModal
          title="You’re getting a Super Host Subscription. Nice!"
          wrapperClass={Styles.subscriptionWrapper}
          closeDialog={closeDialog}
        >
          {subscriptionPopUp()}
        </CustomModal>
      )}
      {paymentPopUp && (
        <CustomModal closeDialog={closeDialog} title="Subscribe" wrapperClass={Styles.rechargeAccountWrapper}>
          {stripePaymentModal()}
        </CustomModal>
      )}
    </>
  );
};

export default Subscriptions;
