import React, {FC, useEffect, useState} from 'react';
import Header from 'components/header/index';
import distanceIcon from 'assets/svg/distance.svg';
import joinIcon from 'assets/svg/join button.svg';
import userIcon from 'assets/svg/user_orange.svg';
import superHostKey from 'assets/svg/superhost-user-key-indicator.svg';
import Styles from 'views/profile/publicProfile/PublicProfile.module.scss';
import CardItem from 'views/profile/publicProfile/card/item';
import { IMAGE_URL } from 'store/services/URL';
import Button from 'components/button/Button';

interface PublicProfileProps {
  profile: any;
  test: string;
  getPublicProfile: Function;
  followProfile: Function;
  // loader?: boolean;
}

const PublicProfile: FC<PublicProfileProps> = (props: PublicProfileProps) => {
  const { profile, getPublicProfile, followProfile } = props;
  const [publicProfile, setPublicProfile] = useState<any>();
  const [showToolTip, setShowToolTip] = useState(false);
  const [followToggle, setFollowToggle] = useState(false);
  const profileCardDetails = [
    {
      title: 'Number of Rooms Started',
      description: '',
      total: publicProfile ? publicProfile.rooms_started : '',
    },
    {
      title: 'Number of Hours Logged',
      description: '',
      total: publicProfile ? (publicProfile.logged_hours / 60000).toFixed(2) : '',
    },
    {
      title: 'Evictions',
      description: 'Number of times they have been kicked out of a room',
      total: publicProfile ? publicProfile.number_of_eviction : '',
    },
  ];
  const user = JSON.parse(localStorage.getItem('user') || '');
  const { pk } = user;

  const handleFollow = () => {
    setFollowToggle(!followToggle);
    followProfile({pk});
    console.log('Call');
  };

  useEffect(() => {
    getPublicProfile({pk});
  }, []);

  useEffect(() => {
    setPublicProfile(profile);
  }, [profile]);

  useEffect(() => {}, [followToggle]);

  return (
    <>
      <Header />
      <section className="custom-container">
        <div className={Styles.profileBox}>
          <div className={Styles.profileBoxInner}>
            <div className={Styles.profileImageWrapper}>
              <img src={`${IMAGE_URL}${user.profile}`} loading="lazy" className={Styles.profileImage} alt="profile img" />
              {user.is_super_host && <img src={superHostKey} loading="lazy" alt="is super host" className={Styles.superHostKey} />}
            </div>
            <div>
              <h3>{user.name}</h3>
              <p>ID NUMBER: {user.pk}</p>
            </div>
          </div>
          <div className="position-relative">
            {/*<Button*/}
            {/*  label={`${publicProfile && publicProfile.is_profile_follow ? 'Unfollow' : 'Follow'}`}*/}
            {/*  cssClass={Styles.followBtn}*/}
            {/*  clickHandler={handleFollow}*/}
            {/*  // onMouseEnter={() => setShowToolTip(true)}*/}
            {/*  // onMouseLeave={() => setShowToolTip(false)}*/}
            {/*  showLoading={loader}*/}
            {/*/>*/}
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className={Styles.followBtn}*/}
            {/*  onClick={handleFollow}*/}
            {/*  onKeyUp={handleFollow}*/}
            {/*  onMouseEnter={() => setShowToolTip(true)}*/}
            {/*  onMouseLeave={() => setShowToolTip(false)}*/}
            {/*>*/}
            {/*  {publicProfile && publicProfile.is_profile_follow ? 'Unfollow' : 'Follow'}*/}
            {/*</button>*/}
            {showToolTip && (
              <div className={Styles.followToolTip}>
                <p className={`mb-0 ${Styles.toolTip}`}>
                  Follow to receive notifications when this host starts a room.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={Styles.cardWrapper}>
          {profileCardDetails.map((item, key) => (
            <CardItem title={item.title} description={item.description} total={item.total} key={key} />
          ))}
        </div>
        <hr />
        <div className={Styles.openCardWrapper}>
          <h4>Currently Open Room</h4>
          {(publicProfile && publicProfile.opened_room) && Object.keys(publicProfile.opened_room).length > 0 && (
            <div className={Styles.openRoomCard}>
              <div className={Styles.openCardInner}>
                <div>
                  <div className="d-flex justify-content-start align-items-center">
                    <h5 className="mb-0 text-black font-weight-500 text-uppercase">TOPIC:</h5>
                    <div className={Styles.topicBtn}>{publicProfile.opened_room.topic}</div>
                  </div>
                  <div
                    className={`d-flex justify-content-start align-items-center column-gap-20 mt-4 
                  ${Styles.distanceWrapper}`}
                  >
                    <div>
                      <img src={distanceIcon} alt="distance" />
                      <span>{publicProfile.distance_in_km.toFixed(2)} KM</span>
                    </div>
                    <div>
                      <img src={userIcon} alt="distance" />
                      <span>{publicProfile.total_participants} people are in this room</span>
                    </div>
                  </div>
                </div>
                {/*<div className={Styles.joinBtnWrapper}>*/}
                {/*  <img src={joinIcon} alt="join btn" />*/}
                {/*</div>*/}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default PublicProfile;
