import React, {FunctionComponent, useEffect, useState} from 'react';
import Styles from './OTPVerification.module.scss';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import OtpInput from 'react-otp-input';
import { validateEmail } from 'helpers/utils';
import { useHistory } from 'react-router';

interface OTPInterface {
  verifyOTP: Function;
  isVerified: boolean;
  isLoading: boolean;
  error: string;
}
const OTPVerification: FunctionComponent<OTPInterface> = (props: OTPInterface) => {
  const { verifyOTP, isVerified, isLoading, error } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [otp, setOTP] = useState('');
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const handleValidateOtp = (val: any) => {
    setOTP(val);
  }
  const handleConfirmOtp = () => {
    if (validateEmail(email)) {
      setEmailError(false);
      if (email && otp.length === 6) {
        verifyOTP({
          email,
          otp,
        });
      }
    } else {
      setEmailError(true);
    }
  }
  useEffect(() => {
    if (email.length === 0) {
      setEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    if (isVerified) {
      history.push('/registration/step-three');
    }
  }, [isVerified]);

  useEffect(() => {
    if (email && otp.length === 6) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email,otp]);

  useEffect(() => {
    setEmail(JSON.parse(localStorage.getItem('userObj') as string).email)
  }, []);
  return (
    <section className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h5 className="fontTwenty font-weight-500 text-black">Step 2</h5>
          <p className={`mb-0 ${Styles.profileTitle}`}>Confirm OTP</p>
          <p className={Styles.tagLine}>
            Please check your email and confirm
            <br />
            OTP first.
          </p>
          <div className="form-group">
            <InputField
              type="email"
              placeholder="Enter your email address"
              cssClass="bg-grey"
              value={email}
              handleChange={(e: string) => setEmail(e)}
            />
          </div>
          {emailError && <p className={`mt-2 ${Styles.error}`}>Invalid Email</p>}
          <div className="d-flex justify-content-center">
            <OtpInput
              value={otp}
              onChange={handleValidateOtp}
              numInputs={6}
              containerStyle={Styles.otpContainer}
              inputStyle={Styles.otpInput}
              separator={<span>-</span>}
            />
          </div>
          {error && <p className={`mt-2 ${Styles.error}`}>{error}</p>}
          <Button clickHandler={handleConfirmOtp} cssClass={Styles.confirmEmailBtn} showLoading={isLoading} label="Confirm" disabled={disabled} />
        </div>
      </div>
    </section>
  );
};

export default OTPVerification;