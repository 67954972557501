import { connect } from 'react-redux';
import Notifications from 'views/notifications/Notifications';
import { notificationSettingRequest, updateNotificationSettingRequest } from 'store/actions/general.actions';
import { getLoading, getNotificationSetting, getUpdatedNotificationSetting } from 'store/selector/general.selector';

const mapDispatchToProps = {
  getSettingRequest: notificationSettingRequest,
  updateSetting: updateNotificationSettingRequest,
};

const mapStateToProps = (state: any) => {
  const loading = getLoading(state);
  const setting = getNotificationSetting(state);
  const settingSuccess = getUpdatedNotificationSetting(state);
  return {
    loading,
    setting,
    settingSuccess,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
