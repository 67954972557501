import { connect } from 'react-redux';
import { getCreatedRoom, getLoading, roomCreated } from 'store/selector/conversation.selector';
import { createRoomRequest } from 'store/actions/conversation.actions';
import Header from 'components/header/Header';
import { getSubscriptionRequestBegin } from 'store/actions/auth.actions';
import { getCard, getSubscription } from 'store/selector/auth.selector';

const mapDispatchToProps = {
  createRoomRequest,
  getSubscriptionRequest: getSubscriptionRequestBegin,
};

const mapStateToProps = (state: any) => {
  const isLoading = getLoading(state);
  const createdRoom = getCreatedRoom(state);
  const status = roomCreated(state);
  const subscriptions = getSubscription(state);
  const card = getCard(state);
  return { isLoading, status, createdRoom, card, subscriptions };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
