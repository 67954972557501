import { call, put, all, takeEvery } from 'redux-saga/effects';
import { authActions } from 'store/actions';
import api from 'store/services/auth.services';
import ACTIONS from 'store/constants';
import ApiResponse, {
  loginResponse,
  verifyEmailResponse,
  verifyOTPResponse,
  registrationResponse,
  unsubscribeResponse,
  rechargeAccountResponse,
} from 'modules/response/response.types';
import {
  IEmailVerificationParams,
  IOTPVerificationParams,
  IGetUser,
  ILoginParams,
  IRegisterParams,
  IProfileParams,
  ICreatePublicProfileParams,
  IRechargeAccountParams,
  ISubscribeParams,
  ICreateCardParams,
  IPublicProfileParams,
  IFollowProfileParams,
} from 'modules/params/param.type';
import { User } from 'modules/user/types';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

function* login(state: { type: string; payload: ILoginParams }) {
  try {
    yield put(authActions.loginAttempt.pending);
    const response: loginResponse = yield call(api.loginRequest, state.payload);
    if (response.access) {
      yield localStorage.setItem('accessToken', response.access);
      const userData: User = yield call(api.getProfile);
      userData.access = response.access;
      userData.refresh = response.refresh;
      yield localStorage.setItem('user', JSON.stringify(userData));
      yield put(authActions.loginAttempt.success(response));
    }
  } catch (error: any) {
    yield put(authActions.loginAttempt.error(error));
  }
}

function* signUp(state: { type: string; payload: IRegisterParams }) {
  try {
    yield put(authActions.registerUser.pending);
    const response: registrationResponse = yield call(api.signUpRequest, state.payload);
    const user: User = {
      access: response.access
    }
    yield localStorage.setItem('user', JSON.stringify(user));
    yield put(authActions.registerUser.success(response));
  } catch (error: any) {
    yield put(authActions.registerUser.error(error.message));
  }
}

function* logout() {
  yield call([localStorage, 'removeItem'], 'user');
  yield call([localStorage, 'removeItem'], 'userObj');
  yield call([localStorage, 'removeItem'], 'accessToken');
  yield put(authActions.logoutSucceed());
}

function* getUser(state: { type: string; payload: IGetUser }) {
  try {
    const user: User = yield call(api.getUser, { user_id: state.payload.user_id });
    if (user) {
      const accessTokenUser: User = yield getItemFromLocalStorage('user');
      yield (user.access = accessTokenUser.access);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
    }
  } catch (err: any) {
    yield console.log('err', err.message);
  }
}

function* uploadProfile(state: { type: string; payload: IGetUser }) {
  try {
    const user: User = yield call(api.getUser, { user_id: state.payload.user_id });
    if (user) {
      const accessTokenUser: User = yield getItemFromLocalStorage('user');
      yield (user.access = accessTokenUser.access);
      yield localStorage.setItem('user', JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
    }
  } catch (err: any) {
    yield console.log('err', err.message);
  }
}

function* verifyEmail(state: { type: string; payload: IEmailVerificationParams }) {
  try {
    yield put(authActions.verifyEmail.pending(state.payload.email));
    const response: verifyEmailResponse = yield call(api.emailVerificationRequest, state.payload);
    if (response) {
      yield put(authActions.verifyEmail.success(response));
    }
  } catch (err: any) {
    yield put(authActions.verifyEmail.error(err.message));
  }
}

function* verifyOTP(state: { type: string; payload: IOTPVerificationParams }) {
  try {
    yield put(authActions.verifyOTP.pending(state.payload));
    const response: verifyOTPResponse = yield call(api.otpVerificationRequest, state.payload);
    if (response) {
      yield put(authActions.verifyOTP.success(response));
    }
  } catch (err: any) {
    yield put(authActions.verifyOTP.error(err.message));
  }
}

function* updateProfile(state: { type: string; payload: IProfileParams }) {
  try {
    yield put(authActions.updateProfile.pending(state.payload));
    const response: verifyOTPResponse = yield call(api.updateProfile, state.payload);
    if (response) {
      const userData: User = yield call(api.getProfile);
      const user = JSON.parse(localStorage.getItem('user') || '');
      userData.access = user.access;
      userData.refresh = user.refresh;
      yield localStorage.setItem('user', JSON.stringify(userData));
      yield put(authActions.updateProfile.success(response));

    }
  } catch (err: any) {
    yield put(authActions.updateProfile.error(err.message));
  }
}

function* deleteProfile() {
  try {
    yield put(authActions.deleteProfile.pending);
    const response: ApiResponse = yield call(api.deleteProfile);
    if (response) {
      yield console.log('Res : ', response);
      yield call([localStorage, 'removeItem'], 'user');
      yield call([localStorage, 'removeItem'], 'accessToken');
      yield put(authActions.logoutSucceed());
    }
  } catch (err: any) {
    yield put(authActions.deleteProfile.error(err.message));
  }
}

function* createPublicProfile(state: { type: string; payload: ICreatePublicProfileParams }) {
  try {
    yield put(authActions.createPublicProfile.pending);
    const response: ApiResponse = yield call(api.createPublicProfile, state.payload);
    if (response) {
      yield put(authActions.createPublicProfile.success(response));
    }
  } catch (err: any) {
    yield put(authActions.createPublicProfile.error(err.message));
  }
}

function* rechargeAccount(state: { type: string; payload: IRechargeAccountParams }) {
  try {
    yield put(authActions.rechargeAccount.pending);
    const response: rechargeAccountResponse = yield call(api.rechargeAccountRequest, state.payload);
    const params = {
      id: state.payload.plan_id,
    }
    yield call(api.confirmPaymentRequest, params);
    const data: User = yield call(api.getProfile);
    const user = JSON.parse(localStorage.getItem('user') || '');
    data.access = user.access;
    data.refresh = user.refresh;
    yield localStorage.setItem('user', JSON.stringify(data));
    yield put(authActions.rechargeAccount.success(response));
  } catch (error: any) {
    yield put(authActions.rechargeAccount.error(error.message));
  }
}

function* subscribe(state: { type: string; payload: ISubscribeParams }) {
  try {
    yield put(authActions.subscribe.pending);
    const response: registrationResponse = yield call(api.subscribeRequest, state.payload);
    if (response) {
      let user = JSON.parse(localStorage.getItem('user') as string);
      user.is_super_host = true;
      yield localStorage.setItem('user', JSON.stringify(user));
    }
    yield put(authActions.subscribe.success(response));
  } catch (error: any) {
    yield put(authActions.subscribe.error(error.message));
  }
}

function* createCard(state: { type: string; payload: ICreateCardParams }) {
  try {
    yield put(authActions.createCard.pending);
    const response: registrationResponse = yield call(api.createCardRequest, state.payload);
    yield put(authActions.createCard.success(response));
  } catch (error: any) {
    yield put(authActions.createCard.error(error.message));
  }
}

function* publicProfile(state: { type: string; payload: IPublicProfileParams }) {
  try {
    yield put(authActions.getPublicProfile.pending);
    const response: registrationResponse = yield call(api.publicProfileRequest, state.payload);
    yield put(authActions.getPublicProfile.success(response));
  } catch (error: any) {
    yield put(authActions.getPublicProfile.error(error.message));
  }
}

function* followProfile(state: { type: string; payload: IFollowProfileParams }) {
  try {
    yield put(authActions.followProfile.pending);
    const response: registrationResponse = yield call(api.followProfileRequest, state.payload);
    yield put(authActions.followProfile.success(response));
  } catch (error: any) {
    yield put(authActions.followProfile.error(error.message));
  }
}

function* getCard() {
  try {
    yield put(authActions.getCardRequest.pending);
    const response: registrationResponse = yield call(api.getCardRequest);
    yield put(authActions.getCardRequest.success(response));
  } catch (error: any) {
    yield put(authActions.getCardRequest.error(error.message));
  }
}

function* getRechargeAccount() {
  try {
    yield put(authActions.getPlansRequest.pending);
    const response: registrationResponse = yield call(api.getRechargeAccountRequest);
    yield put(authActions.getPlansRequest.success(response));
  } catch (error: any) {
    yield put(authActions.getPlansRequest.error(error.message));
  }
}

function* getSubscription() {
  try {
    yield put(authActions.getSubscriptionRequest.pending);
    const response: registrationResponse = yield call(api.getSubscriptionRequest);
    yield put(authActions.getSubscriptionRequest.success(response));
  } catch (error: any) {
    yield put(authActions.getSubscriptionRequest.error(error.message));
  }
}

function* unsubscribe() {
  try {
    yield put(authActions.unsubscribe.pending);
    const response: unsubscribeResponse = yield call(api.unsubscribeRequest);
    if (response) {
      let user = JSON.parse(localStorage.getItem('user') as string);
      user.is_super_host = false;
      localStorage.setItem('user', JSON.stringify(user));
    }
    yield put(authActions.unsubscribe.success(response));
  } catch (error: any) {
    yield put(authActions.unsubscribe.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.VERIFY_EMAIL_CALL, verifyEmail),
    takeEvery(ACTIONS.VERIFY_OTP_CALL, verifyOTP),
    takeEvery(ACTIONS.LOGIN_CALL, login),
    takeEvery(ACTIONS.AUTH_LOGOUT_BEGIN, logout),
    takeEvery(ACTIONS.SIGNUP_CALL, signUp),
    takeEvery(ACTIONS.GET_USER, getUser),
    takeEvery(ACTIONS.UPLOAD_PROFILE_BEGIN, uploadProfile),
    takeEvery(ACTIONS.UPDATE_PROFILE_BEGIN, updateProfile),
    takeEvery(ACTIONS.DELETE_PROFILE_BEGIN, deleteProfile),
    takeEvery(ACTIONS.CREATE_PUBLIC_PROFILE_BEGIN, createPublicProfile),
    takeEvery(ACTIONS.RECHARGE_ACCOUNT_BEGIN, rechargeAccount),
    takeEvery(ACTIONS.GET_RECHARGE_ACCOUNT_BEGIN, getRechargeAccount),
    takeEvery(ACTIONS.GET_SUBSCRIPTION_BEGIN, getSubscription),
    takeEvery(ACTIONS.SUBSCRIBE_BEGIN, subscribe),
    takeEvery(ACTIONS.CREATE_CARD_BEGIN, createCard),
    takeEvery(ACTIONS.GET_CARD_BEGIN, getCard),
    takeEvery(ACTIONS.PUBLIC_PROFILE_BEGIN, publicProfile),
    takeEvery(ACTIONS.FOLLOW_PROFILE_BEGIN, followProfile),
    takeEvery(ACTIONS.UNSUBSCRIBE_BEGIN, unsubscribe),
  ]);
}
