import BroadCast from 'views/BroadCast/BroadCast';
import { connect } from 'react-redux';
import { getUser } from 'store/selector/auth.selector';
import { roomStatus } from 'store/selector/conversation.selector';
import { roomStatusRequest } from 'store/actions/conversation.actions';

const mapDispatchToProps = { roomStatus: roomStatusRequest};

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  const roomUpdate = roomStatus(state);

  return { user, roomUpdate };
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadCast);
