import { connect } from 'react-redux';
import Subscription from 'views/subscription/Subscription';
import { getUser, getSubscription, getCard, getLoader, getUnSubLoader, getUnsubscribeStatus } from 'store/selector/auth.selector';
import { getSubscriptionRequestBegin, getCardRequestBegin, unsubscribeBegin } from 'store/actions/auth.actions';

const mapDispatchToProps = {
  getSubscriptionRequest: getSubscriptionRequestBegin,
  getCardRequest: getCardRequestBegin,
  unsubscribe: unsubscribeBegin,
};

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  const unSubLoading = getUnSubLoader(state);
  const loading = getLoader(state);
  const subscriptions = getSubscription(state);
  const unsubscribeStatus = getUnsubscribeStatus(state);
  const card = getCard(state);
  return { user, subscriptions, card, loading, unSubLoading, unsubscribeStatus };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
