import { connect } from 'react-redux';
import DateAndTime from 'views/dateAndTime/DateAndTime';
import { dateTimeSettingRequest, updateDateTimeSettingRequest } from 'store/actions/general.actions';
import { getLoading, getDateTimeSetting, getUpdatedDateTimeSetting } from 'store/selector/general.selector';

const mapDispatchToProps = {
  getSettingRequest: dateTimeSettingRequest,
  updateSetting: updateDateTimeSettingRequest,
};

const mapStateToProps = (state: any) => {
  const loading = getLoading(state);
  const setting = getDateTimeSetting(state);
  const settingSuccess = getUpdatedDateTimeSetting(state);
  return {
    loading,
    setting,
    settingSuccess,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DateAndTime);