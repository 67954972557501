import { db, storage, reference, getDownloadURL, uploadBytesResumable, deleteObject } from 'utils/firebase-config';
import { ref, onValue, set, update, remove } from 'firebase/database';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getDistance } from 'helpers/utils';
// import Interval from '../../utils/Interval';

// export const createRoom = async (room: any) => {
//   const createRoom: any = ref(db, `rooms/${room.roomId}`);
//   try {
//     await set(createRoom, {
//       roomId: room.roomId,
//       topic: room.topic,
//       host: {
//         id: room.host.pk,
//         name: room.host.name,
//         isSuperHost: room.host.is_super_host,
//         image: room.host.profile,
//       },
//       created_at: room.created_at,
//       isDeleted: false,
//       call_status: false,
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

export const createRoom = async (room: any) => {

  const createRoom: any = ref(db, `rooms/${room.pk}`);
  const { host } = room;
  onValue(ref(db, `rooms/${room.pk}`), (querySnapshot: any) => {
    const room = querySnapshot.val();
    if (room !== null && room.hasOwnProperty('pk')) {
      return;
    }
  });
  let timeStamp = new Date().toString()

  try {
    await set(createRoom, {
      timeStamp: timeStamp,
      pk: room.pk,
      topic: room.topic,
      host: {
        pk: host.pk,
        name: host.name,
        email: host.email,
        role: host.role,
        profile: host.profile,
        latitude: host.latitude,
        longitude: host.longitude,
        silver_coin: host.silver_coin,
        gold_coin: host.gold_coin,
        is_super_host: host.is_super_host,
      },
      participants: room.participants,
      latitude: room.latitude === null ? "null" : room.latitude,
      longitude: room.longitude === null ? "null" : room.longitude,
      created_at: room.created_at,
      is_deleted: room.is_deleted,
      status: room.status,
      
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateRoomStatus = async (data: any) => {
  const getRoom: any = ref(db, `rooms/${data.id}`);
  try {
    await update(getRoom, {
      status: data.status
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateMessageStatus = async (room: any) => {
  const getRoom: any = ref(db, `chats/${room.roomId}/messages/${room.messageId}`)
  let pin = room.pin
  try {
    await update(getRoom, {
      pin: pin,
      timeStamp: room.timeStamp,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateNewRoomStatus = async (room: any) => {
  const getRoom: any = ref(db, `rooms/${room.room.pk}`);
  try {
    await update(getRoom, {
      activeRoom: room.activeRoom,
    });
  } catch (err) {
    console.log(err);
  }
};

export const emailNotifications = async (data: any) => {
  const createPinUsers: any = ref(db, `emailnotifications/${data.pk}`);
  try {
    await update(createPinUsers
      , {
        pk: data.pk,
        email: data.email,
        checked: data.checked
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const getEmailNotifications = () => {
  const getRoom: any = ref(db,`emailnotifications/${user.pk}`);
  let emailnotifications;
  onValue(getRoom, (querySnapshot: any) => {
    emailnotifications = querySnapshot.val()
  });
  return emailnotifications;
};

export const getRoomStatus = (roomId: any) => {
  
  // const getRoom: any = ref(db, `rooms/${roomId}`);
  // let aaaa = onValue(getRoom, (querySnapshot: any) => {
  //   console.log("~~~~~~~~~~~~~~~", querySnapshot);
    
  //   return querySnapshot.val()
  // });
  // console.log("0-0-0-0-0", aaaa);
  
  // const [room, setRoom] = useState<any>({});
  // useEffect(() => {
  //   onValue(getRoom, (querySnapshot: any) => {
  //     setRoom(querySnapshot.val());
  //   });
  // }, [roomId]);
  // return getRoom;
};

export const updateParticipantsInRoom = async (room: any) => {
  const getRoom: any = ref(db, `rooms/${room.roomId}`);
  try {
    await update(getRoom, {
      participants: [
        ...room.user
      ]
    });
  } catch (err) {
    console.log(err);
  }
};

export const UpdateHostInRoom = async (room: any) => {
  const getRoom: any = ref(db, `rooms/${room.roomId}`);
  try {
    await update(getRoom, {
      host: room.host
    });
  } catch (err) {
    console.log(err);
  }
}

export const deleteParticipants = async (data: any) => {
  const message: any = ref(db, `rooms/${data.roomId}/participants/${data.participantId}`);
  
  try {  
   await set(message, {});
  } catch (err) {
    console.log(err);
  }
};

export const deleteRoom = async (room: any) => {
  const removeRoom: any = ref(db, `rooms/${room.roomId}`);
  try {
    await set(removeRoom, {});
  } catch (err) {
    console.log(err);
  }
};


export const useGetMessages = (pk: number) => {
  const [chat, setChat] = useState<any>([]);
  useEffect(() => {
    onValue(ref(db, `chats/${pk}/messages`), (querySnapshot: any) => {
      const messages: any = [];
      querySnapshot.forEach((doc: any) => {
        messages.push(doc.val());
      });
      if (messages.length > 0) {
        messages.sort((i: any, j: any) => {
          var iTime = new Date(i.createdTime), jTime = new Date(j.createdTime);
          return (iTime > jTime ? -1 : 0)
        });
        setChat(messages);
      } else {
        setChat([]);
      }
    });
  }, [pk]);
  return chat;
};

const user = JSON.parse(localStorage.getItem("user") as string);
export const useGetRooms = () => {
  const [rooms, setRooms] = useState<any>([]);
  useEffect(() => {
    onValue(ref(db, "rooms"), (querySnapshot: any) => {
      const allRooms: any = [];
      querySnapshot.forEach((doc: any) => {
        const data = doc.val();
        allRooms.push({
          ...data,
          distance: getDistance(
            user?.latitude,
            user?.longitude,
            data.host?.latitude,
            data.host?.longitude
          ),
        });
      });
      allRooms.sort((a: any, b: any) => {
        if (a.distance !== b.distance ) {
          return a.distance - b.distance
        } else {
          return a.created_at > b.created_at ? -1 : 0
        }
      });
      // if (allRooms.length > 0) {
        // allRooms.sort((i: any, j: any) => (i.timeStamp < j.timeStamp ? -1 : 0));
        setRooms(allRooms);
      // } else {
        // rooms([]);
      // }
    });
  }, []);
  return rooms;
};

export const sendFCMMessage = async (message: any) => {
  const roomId = message.roomId;
  const messages: any = ref(db, `chats/${roomId}/messages/${message.id}`);
  try {
    await set(messages, {
      id: message.id,
      sender: message.sender,
      receiver: message.receiver,
      message: message.message,
      time: message.time,
      is_delete: message.is_delete,
      attachments: message.attachments,
      threads: message.threads,
      createdTime: moment().format()
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const sendThreadMessage = async (message: any) => {
  let findMsg: any = {};
  let length = 0;
  const roomId = message.roomId;
  onValue(ref(db, `chats/${roomId}/messages`), (querySnapshot: any) => {
    querySnapshot.forEach((doc: any) => {
      if (doc.val().id === message.parentId) {
        findMsg = doc.val();
      }
    });
  });
  const isThreadsExist = findMsg.hasOwnProperty('threads');
  if (isThreadsExist) {
    onValue(ref(db, `chats/${roomId}/messages/${message.parentId}/threads`), (querySnapshot: any) => {
      const obj = querySnapshot.val();
      length = obj === null ? 0 : obj.length;
    });
  }
  if (length === 0) {
    const messages: any = ref(db, `chats/${roomId}/messages/${message.parentId}`);
    try {
      await update(messages, {
        threads: [
          {
            id: message.id,
            sender: message.sender,
            receiver: message.receiver,
            message: message.message,
            time: message.time,
            is_delete: message.is_delete,
            attachments: message.attachments,
          }
        ]
      });
    } catch (err) {
      console.log(err);
    }
  } else {
    const messages = ref(db, `chats/${roomId}/messages/${message.parentId}/threads/${length}`);
    try {
      await set(messages, {
        id: message.id,
        sender: message.sender,
        receiver: message.receiver,
        message: message.message,
        time: message.time,
        is_delete: message.is_delete,
        attachments: message.attachments,
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
};

export const deleteMessage = async (path: any) => {
  const message: any = ref(db, `chats/${path}`);
  try {
    await set(message, {});
  } catch (err) {
    console.log(err);
  }
};

export const deleteImage = async (path: any) => {
  const desertRef = reference(storage, `${path}`);
  deleteObject(desertRef).then(() => {
    // File deleted successfully
  }).catch((error) => {
    // Uh-oh, an error occurred!
  });
};

export const getImages = async (roomId: string, attachmentId: string) => {
  const images: any = [];
  try {
    const url1 = await getDownloadURL(reference(storage, `/${roomId}/${attachmentId}`));
    if (url1) images[0] = url1;
    return images;
  } catch (err) {}
  return images;
};

export const uploadImage = async (message: any, file: any) => {
  try {
    return await uploadBytesResumable(reference(storage, `/${message.roomId}/${message.attachments[0].id}`), file.file);
  } catch (err) {}
  return '';
};

export const updateCallStatus = async (room: any) => {
  const getRoom: any = ref(db, `rooms/${room.roomId}`);
  try {
    await update(getRoom, {
      call_status: room.status
    });
  } catch (err) {
    console.log(err);
  }
};

export const useGetCallStatus = (roomId: any) => {
  const getRoom: any = ref(db, `rooms/${roomId}`);
  const [room, setRoom] = useState<any>({});
  useEffect(() => {
    onValue(getRoom, (querySnapshot: any) => {
      setRoom(querySnapshot.val());
    });
  }, [roomId]);
  return room;
};

export const sendJoinRequest = async (request: any) => {
  const roomId = request.roomId;
  const requests: any = ref(db, `requests/${roomId}/requests/${request.id}`);
  try {
    await set(requests, {
      id: request.id,
      user: request.user,
      time: request.time,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteRequest = async (path: any) => {
  const request: any = ref(db, `requests/${path}`);
  try {
    await set(request, {});
  } catch (err) {
    console.log(err);
  }
};