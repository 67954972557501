import React, {FunctionComponent, useState} from 'react';
import { checkEmail } from 'utils/FormValidation';
import Styles from 'views/profile/Profile.module.scss';
import InputField from 'components/textField/InputField';
import profileImage from 'assets/images/user.png';
import editWhite from 'assets/svg/edit_white_1.svg';
import Button from 'components/button/Button';
import { LOADER_STATUS } from 'store/constants';
import { IMAGE_URL } from 'store/services/URL';
import { useHistory } from 'react-router';
import Toast from 'components/toast/Tost';

interface ProfileProps {
  updateProfile: Function;
  deleteProfile: Function;
  loading: boolean;
  response: string;
}
const Profile: FunctionComponent<ProfileProps> = (props: ProfileProps) => {
  const { updateProfile, loading, response, deleteProfile } = props;
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [name, setName] = useState<string>(user.name || '');
  const [email, setEmail] = useState<string>(user.email || '');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [file, setFile] = useState<any>('');
  const [image, setImage] = useState<any>(`${IMAGE_URL}${user.profile}` || profileImage);
  const [emailError, setEmailError] = useState<string>('');
  const [requiredField, setRequiredField] = useState<string[]>([]);
  const [isCheckLoading, setIsCheckLoading] = useState<number>(LOADER_STATUS.NA);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [alert, setAlert] = useState(false);
  const history = useHistory();
  const [toast, setToast] = useState(false);
  const [toastValues, setToastValues] = useState({message: '', type: ''});

  const handleBlur = async (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    const error = checkEmail(val);
    setEmailError(error);
    if (error.length === 0 && user.email !== val) {
      removeFromErrorList('email');
      setEmailError('');
      try {
        setIsCheckLoading(LOADER_STATUS.PENDING);
      } catch (err: any) {
        setIsCheckLoading(LOADER_STATUS.COMPLETE);
        console.log(err);
      }
    }
  };

  const handleUpdate = () => {
    const errors: string[] = [];
    if (name.length === 0) errors.push('name');
    if (email.length === 0) errors.push('email');
    if (errors.length > 0) {
      setRequiredField(errors);
      return;
    }
    if ( newPassword !== confirmPassword ) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }
    setRequiredField([]);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    if (oldPassword) formData.append('old_password', oldPassword);
    if (newPassword) formData.append('new_password', newPassword);
    if (file) formData.append('profile_pic', file);
    updateProfile(formData);
    setToast(true);
      setToastValues({
        message: 'Your changes have been saved.',
        type: 'Success',
      });
  };

  const removeFromErrorList = (val: string) => {
    if (!val) return;
    setRequiredField(requiredField.filter((x: string) => x.toLowerCase() !== val.toLowerCase()));
  };

  const isInError = (fieldName: string) => {
    if (requiredField.includes(fieldName)) {
      return `${fieldName} is required`;
    }
    return '';
  };

  const handleCancel = () => {
    console.log('handle Cancel');
  };

  const handleChangeImage = (file: any) => {
    const fileType = file.target.files[0].type;
    const type = fileType.split("/");
    if (type[0] === 'image') {
      setAlert(false);
      const path: any = URL.createObjectURL(file.target.files[0]);
      setImage(path);
      setFile(file.target.files[0]);
    } else {
      setImage(`${IMAGE_URL}${user.profile}` || profileImage);
      setFile('');
      setAlert(true);
    }
  };

  const handleDelete = () => {
    deleteProfile({});
    localStorage.removeItem('user');
    localStorage.clear();
    history.push('/login');
  };

  return (
    <>
      <div className="borderStyle">
        <div className={Styles.imageWrapper}>
          <div className={`position-relative ${Styles.profileImageInner}`}>
            <img src={image} className={Styles.profileImage} alt="profile" />
            <span>
              <img src={editWhite} alt="edit icon" />
              <input onChange={(e) => handleChangeImage(e)} accept="image/*" type="file" className={Styles.fileUpload} />
            </span>
          </div>
          <div>
            <h5 className="text-black fontSixteen font-weight-600">CHANGE PROFILE PICTURE</h5>
            <p className="text-black font-weight-normal fontFifteen mt-3">
              Profile picture should be in the standard format: png, jpg & no more <br /> than 3MB.
              <strong className="font-weight-600"> Must be a selfie.</strong>
            </p>
            {alert && <p className="mb-0 mt-0" style={{color: 'red'}}>Only Accept Image (.jpg, .jpeg, .png) etc</p>}
          </div>
        </div>
        {/*{alert && <p style={{color: 'red'}}>Only Accept Image (.jpg, .jpeg, .png) etc</p>}*/}
        <div className="row mt-5">
          <div className="col-md-12">
            <h5 className="text-black fontSixteen font-weight-600 mb-30">PERSONAL INFO</h5>
            <div className="row personalInfoWrapper">
              <div className="col-md-6">
                <div className="form-group">
                  <InputField
                    label="FULL NAME"
                    handleChange={setName}
                    type="text"
                    cssClass={`poppins-regular ${Styles.inputStyle}`}
                    value={name}
                    placeholder="First Name"
                  />
                  <span className="border-danger">{isInError('name')}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <InputField
                    label="EMAIL"
                    blurHandler={handleBlur}
                    handleChange={setEmail}
                    type="email"
                    cssClass={`poppins-regular ${Styles.inputStyle}`}
                    value={email}
                    placeholder="Email Address"
                    showLoading={isCheckLoading === LOADER_STATUS.PENDING}
                    errorMessage={emailError}
                  />
                  <span className="border-danger">{isInError('email')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <h5 className="text-black fontSixteen font-weight-600 my-30 personalInfoWrapper">CHANGE PASSWORD</h5>
            <div className="row personalInfoWrapper">
              <div className="col-md-4">
                <div className="form-group">
                  <InputField
                    label="CURRENT PASSWORD"
                    handleChange={setOldPassword}
                    type="password"
                    cssClass={`poppins-regular ${Styles.inputStyle}`}
                    value={oldPassword}
                    placeholder="******"
                  />
                  <span className="border-danger">{isInError('password')}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <InputField
                    label="NEW PASSWORD"
                    handleChange={setNewPassword}
                    type="password"
                    cssClass={`poppins-regular ${Styles.inputStyle}`}
                    value={newPassword}
                    placeholder="******"
                  />
                  <span className="border-danger">{isInError('password')}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <InputField
                    label="CONFIRM"
                    handleChange={setConfirmPassword}
                    type="password"
                    cssClass={`poppins-regular ${Styles.inputStyle}`}
                    value={confirmPassword}
                    placeholder="******"
                  />
                  <span className="border-danger">{isInError('password')}</span>
                </div>
              </div>
            </div>
            {isConfirm && <p style={{color: 'red'}}>Password and confirm password not matched!</p>}
            {/*{response && <p style={{color: 'green'}}>{response}</p>}*/}
          </div>
        </div>
        <div className="row deleteAccountSection">
          <div className={`col-md-12 d-flex align-items-center justify-content-between ${Styles.deleteAccountWrapper}`}>
            <div>
              <h5 className="text-black fontSixteen font-weight-600">DELETE ACCOUNT</h5>
              <p className="fontFifteen text-black font-weight-normal">
                By deleting your account, you will lose all your data
              </p>
            </div>
            <div
              onClick={handleDelete}
              onKeyUp={handleDelete}
              role="button"
              tabIndex={0}
              className="text-blue fontFifteen font-weight-bold text-uppercase"
            >
              Delete Account
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} showLoading={loading} clickHandler={handleUpdate} />
      </div>
      {toast && (
        <Toast
          message={toastValues.message}
          position="topRight"
          type={toastValues.type}
          handleCloseToast={setToast}
        />
      )}
    </>
  );
};

export default Profile;
