import { AxiosRequestConfig } from 'axios';
import axios from 'store/services/axios';
import parseError from 'utils/ErrorParse';

function withAPIKeys(requestConfig?: AxiosRequestConfig) {
  const token = localStorage.getItem('accessToken');
  if (token) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        'accept': 'application/json',
        Authorization: `Bearer ${token}` ?? 'A',
        'Content-Type': 'application/json',
        'X-CSRFToken': 'Jkq0aXN0M0cCNnESXEB9nSgVeqHcWwahimEIqXPUHI6iEIqZRGMYQPJvFY7OKSnC'
      },
    };
  }
  return {
    ...requestConfig,
    headers: {
      ...requestConfig?.headers,
      'Content-Type': 'application/json',
    },
  };
}

function withMultipartAPIKeys(requestConfig?: AxiosRequestConfig) {
  const token = localStorage.getItem('accessToken');

  if (token) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        Authorization: `JWT ${token}` ?? 'A',
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': 'ptRQnsW1YGmq6dH9TGVRgH4Ln08HOe7QYv5yDsYVTog6XytgNI6GJExlOyyjCAkb'
      },
    };
  }
  return {
    ...requestConfig,
    headers: {
      ...requestConfig?.headers,
      'Content-Type': 'multipart/form-data',
    },
  };
}

const addPageNumber = (url: string, params: any) => {
  let str = '';
  if (params.user_id) {
    str = `${params.user_id}/`;
    console.log('First : ', `${url}${str}`);
    return `${url}${str}`;
  }
  if (params.checkEmail) {
    str = `email=${params.checkEmail}`;
  }
  if (params.page) {
    str = `page=${params.page}`;
  }
  if (params.limit) {
    str = `${str}${str ? '&' : ''}limit=${params.limit}`;
  }
  return str ? `${url}?${str}` : url;
};

export const apiCall = {
  get: (payload: requestType) =>
    axios
      .get(addPageNumber(payload.url, payload.params), withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  post: (payload: requestType) =>
    axios
      .post(addPageNumber(payload.url, payload.params), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  getURLWithId: (payload: requestType) =>
    axios
      .get(addPageNumber(`${payload.url}${payload.params.pk}`, payload.params.pk), withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  postURLWithId: (payload: requestType) =>
    axios
      .post(addPageNumber(`${payload.url}${payload.params.id}`, payload.params.id), {} , withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  postURLAndBody: (payload: requestType) =>
    axios
      .post(addPageNumber(`${payload.url}${payload.params.id}`, payload.params.id), {"status": payload.params.status} , withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  put: (payload: requestType) =>
    axios
      .put(addPageNumber(payload.url, payload.params), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  patch: (payload: requestType) =>
    axios
      .patch(addPageNumber(payload.url, payload.params), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  delete: (payload: requestType) =>
    axios
      .delete(payload.url, withAPIKeys())
      .then((response) => {
        // if (response) {
        //   let user = JSON.parse(localStorage.getItem('user') as string);
        //   user.is_super_host = false;
        //   localStorage.setItem('user', JSON.stringify(user));
        // }
        return response.data;
        // localStorage.removeItem('user');
        // localStorage.removeItem('accessToken');
      })
      .catch((error) => parseError(error)),
  postFormData: (payload: requestType) =>
    axios
      .post(payload.url, payload.params, withMultipartAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
};

export interface requestType {
  url: string;
  isAuthToken: boolean;
  params: any;
}
