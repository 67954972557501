import React, { FunctionComponent } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Styles from 'components/leftNavBar/LeftNavBar.module.scss';
import logoutIcon from 'assets/svg/logout.svg';
import { useDispatch } from 'react-redux';
import { callLogout } from 'store/actions/auth.actions';

const LeftNavBar: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const dispatch = useDispatch();
  const handleLogOut = () => {
    dispatch(callLogout());
    history.push('/login');
  };
  return (
    <>
      <ul className={`list-unstyled ${Styles.listingWrapper}`}>
        <li className={`fontSixteen text-black poppins-bold ${Styles.listingTitle}`}>GENERAL</li>
        <li className={`${path === '/explainer-video' && Styles.active}`}>
          {/* <a
            className="fontFifteen poppins-regular text-decoration-none"
            href="https://www.youtube.com/watch?v=JQrPpj7b0_g"
            target="_blank"
            rel="noreferrer"
          >
            Explainer Video
          </a> */}
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/explainer-video">
            Explainer Video
          </Link>
        </li>
        <li className={`${path === '/invite-friend' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/invite-friend">
            Invite a Friend
          </Link>
        </li>
        <li className={`fontSixteen text-black poppins-bold ${Styles.listingTitle}`}>ACCOUNT</li>
        <li className={`${path === '/currency' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/currency">
            Currency
          </Link>
        </li>
        <li className={`${path === '/subscription' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/subscription">
            Subscription
          </Link>
        </li>
        <li className={`${path === '/profile/edit' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/profile/edit">
            Edit Profile
          </Link>
        </li>
        <li className={`${path === '/date-and-time' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/date-and-time">
            Date and Time
          </Link>
        </li>
        <li className={`${path === '/notifications' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/notifications">
            Notifications
          </Link>
        </li>
        <li className={`${path === '/email-notifications' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/email-notifications">
            Email Notifications
          </Link>
        </li>
        <li className={`fontSixteen text-black poppins-bold ${Styles.listingTitle}`}>OTHER</li>
        <li className={`${path === '/feedback' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/feedback">
            Send Us Feedback
          </Link>
        </li>
        <li className={`${path === '/terms-and-conditions' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/terms-and-conditions">
            Terms & Conditions
          </Link>
        </li>
        <li className={`${path === '/privacy-policy' && Styles.active}`}>
          <Link className="fontFifteen poppins-regular text-decoration-none" to="/privacy-policy">
            Privacy Policy
          </Link>
        </li>
        <li className={Styles.logOut}>
          <div className={Styles.btnStyle} onClick={handleLogOut} onKeyPress={handleLogOut} role="button" tabIndex={0}>
            LogOut
            <img src={logoutIcon} alt="logout icon" />
          </div>
        </li>
      </ul>
    </>
  );
};

export default LeftNavBar;
