import React, { FunctionComponent, useState, useEffect } from 'react';
import Styles from 'components/toast/Toast.module.scss';
import checkIcon from 'assets/svg/check.svg';
import errorIcon from 'assets/svg/error.svg';
import infoIcon from 'assets/svg/info.svg';
import warningIcon from 'assets/svg/warning.svg';

interface ToastProps {
  handleCloseToast: Function;
  message: string;
  type: string;
  position: string;
}
const Toast: FunctionComponent<ToastProps> = (props: ToastProps) => {
  const { type, message, position, handleCloseToast } = props;

  const getPosition = () => {
    switch (position) {
      case 'topLeft': return `${Styles.topLeft}`;
      case 'topRight': return `${Styles.topRight}`;
      case 'bottomLeft': return `${Styles.bottomLeft}`;
      case 'bottomRight': return `${Styles.bottomRight}`;
      default : return Styles.topRight;
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'Success': return checkIcon;
      case 'Error': return errorIcon;
      case 'Warning': return warningIcon;
      case 'Info': return infoIcon;
      default : return checkIcon;
    }
  };

  const getBgColor = () => {
    switch (type) {
      case 'Success': return `${Styles.bgSuccess}`;
      case 'Error': return `${Styles.bgDanger}`;
      case 'Warning': return `${Styles.bgWarning}`;
      case 'Info': return `${Styles.bgInfo}`;
      default : return `${Styles.bgSuccess}`;
    }
  };

  return (
    <div className={`${Styles.notificationContainer} ${getPosition()} ${getBgColor()}`}>
      <div className={`${Styles.notification} ${Styles.toast}`}>
        <button className={Styles.closeButton} onClick={() => handleCloseToast(false)}>
          X
        </button>
        <div className="d-flex align-items-center">
          <div className={`${Styles.notificationImage}`}>
            <img src={getIcon()} alt="" />
          </div>
          <div>
            <p className={`${Styles.notificationTitle}`}>{type}</p>
          </div>
        </div>
        <div>
          <p className={`${Styles.notificationMessage} ml-5`}>{message}</p>
        </div>
      </div>
    </div>
  );
};
export default Toast;
