import React, { FunctionComponent } from 'react';
import logo from 'assets/images/social-play-white.png';
import Styles from 'views/register/Register.module.scss';

const LoginLeftSection: FunctionComponent = () => (
  <>
    <img src={logo} alt="logo" className={`img-fluid ${Styles.logoImage}`} />
    <p className={`${Styles.descriptionLine} text-white`}>Audio and Video chat with people nearby.</p>
  </>
);

export default LoginLeftSection;
