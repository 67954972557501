import { connect } from 'react-redux';
import Currency from 'views/currency/Currency';
import { getUser, getRechargePlans } from 'store/selector/auth.selector';
import { getPlansRequestBegin } from 'store/actions/auth.actions';

const mapDispatchToProps = {getPlansRequest: getPlansRequestBegin };

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  const getPlans = getRechargePlans(state);
  return { user, getPlans };
};
export default connect(mapStateToProps, mapDispatchToProps)(Currency);
