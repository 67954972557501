import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
// import socialPlayImg from 'assets/images/social-play-logo-vertical.png';
// import playIcon from 'assets/images/play-icon.png';
import upArrowIcon from 'assets/images/up-arrow-icon.png';
import { demoImages } from 'helpers/data/general';
import Styles from 'views/about/About.module.scss';
import SliderWrapper from "components/controls/Slider/SliderWrapper";
import {AboutPageSlider} from "components/controls/Slider/SliderResponsiveness";
import Button from "../../components/button/Button";
import Infographics from 'assets/images/Infographics.svg';

const About = () => {
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        }else if(scrolled <= 300) {
            setVisible(false);
        }
    };
    const handleCreateAccount = () => history.push('/registration');
    const scrollToTop = () => {
        window.scroll({top: 0, behavior: 'smooth'});
    };
    window.addEventListener('scroll', toggleVisible);
  return (
    <section className={Styles.wrapper}>
        <div className={Styles.topHeader}>
            <Link to="/Login">Login</Link>
        </div>
        <div className="container">
            <p className={Styles.feature}>FEATURES</p>
            <p className={Styles.heading}>How does <span className={Styles.headingSpan}>Social Play</span> work?</p>
            <div className={Styles.videoSection}>
                <video controls={true} autoPlay={true} width="908" height="510"
                    poster="https://ucb99db7d979add0f92ec97d22de.previews.dropboxusercontent.com/p/thumb/ABrl7--pShxNPo21tjjPuwfD2_Be9s4ONYIIAZhcxEtjxulC4qeAILuJ3g6cWWiGe50yYUprHVTdFohYPbumosQu9pV-3wifM0Sq0XQw_RV5ciy1iTCUYSr16vgkP3aHfLSV7BIKhFAbnKuZ2E-GKuwaMew6GClwCKOuuyktxPYn-THPQvFTGNsrgQdN-NdQH-WWRtu7SsvT_BLHbER6uID79BE3BF0F-6sMSbsexfAYCucEyAMI2_QHLH8sxZzNQM-EYApl63pOOZLywX9x2F8aQaY4OMDVtpWM9fqqkicDGK5LjACU8MT8xBa4vZhNGgqMUV8M_Uk0LVvuhbYMQisMhrEXeHqEoI1BeLUrCOFjn1V2hxcg215A6DQab9a9nYG6nsibpCUXnGPRwGn-heRVpvej9eD-ZZ75L2WUMhD7vAhfEiAiGEveG5VB2-UCK3SCSlraOApvPyIUg9QZP9hg5qATuA5I2Z3bjC8ChBj3QO0k5ww5l0kfvGz6sk__G8GAf2FgksTKEoDIAR1G-WbihNDBFb7qQptSV-fh10A9dtwtR0mnz8J2_QdhwGDBP5Gk8Jl5YW-SNYBeTbbm4DpwXW1wC6GLgYaIqxTZBryEaJNzuSyUTxY6qTr6-Xv6GgoJKLng_UrExvojM0mKaaxkP6uNURmzlTHh9nnpUSYjgVGyakSRPVvHpmQIhUJQlP-WCiqXy6xPnutP-yv229wEqNHMqlra9Kqg4ZUgQQDi_Tdxw6msAHSzjHIjxbphsvz7jTLzGY0hgUSkgig7yRSFqEG8YFoEgbFt1B1ZFmFJ-KaJpRNbZzmam1H3t2zpOFcmNrZxKnUcNRZhUJzBxZpaVWrd1JMsd1u-TirsFLrjZ1Mpoh_dV0wHbGBxYavhrjqygZl1Y-j3PYGQ1tgCNbji73B93tOq2Cz7IQEXwHxzYE-ko9E8vxjTGmcsJ0eciAoYTskdUs4HWG5ebcX_Ma6RXTZ2sgOhH_rF_MROqfr91A/p.jpeg?psid=979c5553-d5a2-447b-a8b9-266640796726&size=1024x768&size_mode=2">
                    <source src="https://www.dropbox.com/s/c9xcee48e5kzdve/Social%20Play%20720p%20Final.mp4?raw=1" type="video/mp4"/>
                </video>
            </div>
        </div>
        <div className={Styles.carouselWrapper}>
            <div className="px-3 demo-slider">
                <SliderWrapper slideToShow={1} sliderSetting={AboutPageSlider}>
                  {demoImages.map((item: any, index) => (
                    <div className={Styles.sliderItem} key={index}>
                      <img src={item.image} className="w-100" alt="demo"/>
                    </div>
                  ))}
                </SliderWrapper>
                <div className="mt-30">
                    <h5 className={`m-0 font-weight-600 text-black text-center ${Styles.sliderItemCaption}`}>Audio and Video chat with people nearby. View the <br />
                        conversation topics and join in.</h5>
                </div>
            </div>
        </div>
        <div className="container">
            <div className={Styles.learnMoreWrapper}>
                <div className={Styles.videoImage}>
                    <img src={Infographics} className="w-100" alt="demo"/>
                </div>
                <p className={Styles.learnMore}>LEARN MORE</p>
                <p className={Styles.heading}>About <span className={Styles.headingSpan}>Social Play</span></p>
                <p className={Styles.paragraph}>
                    {`Social Play > is an app made by Open build Co, the first customer owned company. As a customer using Social Play > you can invest. Follow this `}
                    <a className={Styles.link} href="">link </a>
                    to our crowd funding campaign.
                </p>
                <p className={`mt-3 ${Styles.paragraph}`}>
                    {`Also check out another app we made called SoundBird. With this app you can use your phone as a microphone and the phone’s of your audience as speakers. Check it out at `}
                    <a className={Styles.link} href="">soundbirdapp.com</a>
                </p>
            </div>
        </div>
      <div className={`text-center w-100 mt-5 ${Styles.signUpMarginBLock}`}>
        <Button clickHandler={handleCreateAccount} label="CREATE AN ACCOUNT NOW" />
      </div>
        <div className={Styles.backToTopIcon} onClick={scrollToTop} style={{display: visible ? 'block' : 'none'}}>
            <img src={upArrowIcon} alt="play icon" className={Styles.playIcon} />
            BACK TO TOP
        </div>
        <footer className={Styles.footerSection}>
            <div className={`${Styles.copyRightText} text-black fontFourteen`}>© 2022. All Rights Reserved.</div>
        </footer>
    </section>
  );
};

export default About;
