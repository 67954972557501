import {
  IEmailVerificationParams,
  IOTPVerificationParams,
  IForgetPasswordParams,
  IGetUser,
  ILoginParams,
  IRegisterConfirmParams,
  IRegisterParams,
  IResendCode,
  IProfileParams,
  ICreatePublicProfileParams,
  IRechargeAccountParams,
  ISubscribeParams,
  ICreateCardParams,
  IPublicProfileParams,
  IFollowProfileParams,
  IConfirmPayment,
} from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import {
  URL_SEND_VERIFICATION_EMAIL,
  URL_VERIFY_EMAIL,
  URL_PROFILE,
  URL_LOGIN,
  URL_FORGOT_PASSWORD,
  URL_SIGNUP,
  URL_CONFIRM_REGISTRATION,
  URL_RESEND_REGISTRATION_CODE,
  URL_GET_USER,
  URL_PUBLIC_PROFILE,
  URL_RECHARGE_ACCOUNT,
  URL_SUBSCRIBE,
  URL_ACCOUNTS_CARD,
  URL_FOLLOW_PROFILE,
  URL_UNSUBSCRIBE,
  URL_CONFIRM_PAYMENT
} from 'store/services/URL';

export const api = {
  emailVerificationRequest(verificationParams: IEmailVerificationParams) {
    return apiCall.post({ url: URL_SEND_VERIFICATION_EMAIL, isAuthToken: false, params: verificationParams });
  },
  otpVerificationRequest(verificationParams: IOTPVerificationParams) {
    return apiCall.post({ url: URL_VERIFY_EMAIL, isAuthToken: false, params: verificationParams });
  },
  loginRequest(loginParams: ILoginParams) {
    return apiCall.post({ url: URL_LOGIN, isAuthToken: false, params: loginParams });
  },
  signUpRequest(user: IRegisterParams) {
    return apiCall.postFormData({ url: URL_SIGNUP, isAuthToken: false, params: user });
  },
  resendRegistrationCode(params: IResendCode) {
    return apiCall.post({ url: URL_RESEND_REGISTRATION_CODE, isAuthToken: false, params });
  },
  confirmRegistration(params: IRegisterConfirmParams) {
    return apiCall.post({ url: URL_CONFIRM_REGISTRATION, isAuthToken: false, params });
  },
  forgetPassword(params: IForgetPasswordParams) {
    return apiCall.post({ url: URL_FORGOT_PASSWORD, isAuthToken: false, params });
  },
  getUser(params: IGetUser) {
    return apiCall.get({ url: URL_GET_USER, isAuthToken: true, params });
  },
  getProfile() {
    return apiCall.get({ url: URL_PROFILE, isAuthToken: true, params: {} });
  },
  updateProfile(params: IProfileParams) {
    return apiCall.put({ url: URL_PROFILE, isAuthToken: true, params });
  },
  deleteProfile() {
    return apiCall.delete({ url: URL_PROFILE, isAuthToken: true, params: {} });
  },
  createPublicProfile(params: ICreatePublicProfileParams) {
    return apiCall.post({ url: URL_PUBLIC_PROFILE, isAuthToken: true, params });
  },
  getSubscriptionRequest() {
    return apiCall.get({ url: URL_SUBSCRIBE, isAuthToken: true, params: {} });
  },
  getRechargeAccountRequest() {
    return apiCall.get({ url: URL_RECHARGE_ACCOUNT, isAuthToken: true, params: {} });
  },
  getCardRequest() {
    return apiCall.get({ url: URL_ACCOUNTS_CARD, isAuthToken: true, params: {} });
  },
  rechargeAccountRequest(params: IRechargeAccountParams) {
    return apiCall.post({ url: URL_RECHARGE_ACCOUNT, isAuthToken: true, params });
  },
  confirmPaymentRequest(params: IConfirmPayment) {
    return apiCall.postURLWithId({ url: URL_CONFIRM_PAYMENT, isAuthToken: true, params });
  },
  subscribeRequest(params: ISubscribeParams) {
    return apiCall.post({ url: URL_SUBSCRIBE, isAuthToken: true, params });
  },
  createCardRequest(params: ICreateCardParams) {
    return apiCall.post({ url: URL_ACCOUNTS_CARD, isAuthToken: true, params });
  },
  publicProfileRequest(params: IPublicProfileParams) {
    return apiCall.getURLWithId({ url: URL_PUBLIC_PROFILE, isAuthToken: true, params });
  },
  followProfileRequest(params: IFollowProfileParams) {
    return apiCall.getURLWithId({ url: URL_FOLLOW_PROFILE, isAuthToken: true, params });
  },
  unsubscribeRequest() {
    return apiCall.delete({ url: URL_UNSUBSCRIBE, isAuthToken: true, params: {} });
  },
};

export default api;
