import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputField from 'components/textField/InputField';
import Button from 'components/button/Button';
import Styles from 'views/register//Register.module.scss';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';
import { checkEmail } from 'utils/FormValidation';
import LoginLeftSection from 'components/loginLeftSection/LoginLeftSection';
import { useSelector } from 'react-redux';
import { User } from 'modules/user/types';
import { getUser } from 'store/selector/auth.selector';

interface LoginProps {
  isLoading: boolean;
  authError: any;
  loginRequest: Function;
}

const Login: FunctionComponent<LoginProps> = (props: LoginProps) => {
  const { isLoading, loginRequest, authError } = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [emptyFields, setEmptyFields] = useState(false);
  const history = useHistory();
  const userToken: User = useSelector(getUser);
  const user: any = localStorage.getItem('user');

  useEffect(() => {
    if (user && JSON.parse(user).access) {
      history.push('/conversations-nearby');
    }
    // if (userToken && userToken.access) {
    //   history.push('/conversations-nearby');
    // }
  }, [user]);

  const handleLogin = () => {
    if (email.length === 0 || password.length === 0) {
      setEmptyFields(true);
      return;
    } else {
      setEmptyFields(false);
    }
    isButtonEnabled();
    if (email.length < 1 || password.length < 1 || emailError.length > 0 || isLoading) {
      return;
    }

    const user = getItemFromLocalStorage('user');
    if (user === null) {
      loginRequest({
        email,
        password,
      });
    }
    else {
      history.go(0);
    }
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const isButtonEnabled = () => {
    setEmailError(checkEmail(email));
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setEmailError(checkEmail(val));
  };

  return (
    <div className={Styles.customContainer}>
      <div className={Styles.leftSectionWrapper}>
        <LoginLeftSection />
      </div>
      <div className={Styles.rightSectionWrapper}>
        <div className={Styles.loginArea}>
          <h4 className={Styles.accountHeading}>Login to your account</h4>
          <p className={`mb-0 ${Styles.profileTitle}`}>Welcome back!</p>
          <div className={`mb-3 ${Styles.formWrapper}`}>
            <div className="form-group">
              <InputField
                errorMessage={emailError}
                blurHandler={handleBlur}
                handleChange={setEmail}
                type="email"
                placeholder="Email Address"
              />
              {emptyFields && <p className={Styles.error}>Please fill all fields</p>}
            </div>
            <div className="form-group">
              <InputField
                handleKeyDown={handleKeyDownEvent}
                handleChange={setPassword}
                type="password"
                placeholder="Password"
              />
              {authError && <p className={Styles.error}>{authError}</p>}
            </div>
          </div>
          <p className="mb-0 fontFifteen">
            <Link
              to="/forgot-password"
              className="text-blue text-decoration-none d-inline-block fontFifteen font-weight-bolder"
            >
              Forgot password?
            </Link>
          </p>
          <div className={`text-center w-100 ${Styles.signUpMarginBLock}`}>
            <Button showLoading={isLoading} clickHandler={handleLogin} label="Log in" />
          </div>
          <p className="fontFifteen font-weight-normal text-black mb-0">
            Don’t have an account? &nbsp;
            <Link
              to="/registration"
              className="text-blue text-decoration-none d-inline-block fontFifteen font-weight-bolder"
            >
              CREATE AN ACCOUNT
            </Link>
          </p>
        </div>
      </div>
      {/* <EmailConfirmModal handleClose={handleClose} email={email} name="dev" /> */}
    </div>
  );
};

export default Login;
