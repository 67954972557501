import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL, uploadBytesResumable, listAll, deleteObject } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDoeK5LjNOYpFLzQlGGMv8uO-dw7Zbwmxg",
  authDomain: "socialplay-b3831.firebaseapp.com",
  projectId: "socialplay-b3831",
  storageBucket: "socialplay-b3831.appspot.com",
  messagingSenderId: "520567936029",
  appId: "1:520567936029:web:8ab4a03edc4e84fb2322f4",
  measurementId: "G-HB5JEDPM96",
  databaseURL: 'https://socialplay-b3831-default-rtdb.firebaseio.com',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getDatabase(app);
const reference = ref;
export { db, storage, reference, getDownloadURL, uploadBytesResumable, listAll, deleteObject };

