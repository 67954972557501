import React, {FunctionComponent, Suspense, useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from 'assets/images/social_play_logo_horizontal.png';
import subscriptionIcon from 'assets/svg/superhost-subscription.svg';
import dashboardIcon from 'assets/svg/dashboard.svg';
import userIcon from 'assets/svg/user_blue.svg';
import settingIcon from 'assets/svg/settings_1.svg';
import logoutIcon from 'assets/svg/logout.svg';
import Button from 'components/button/Button';
import Styles from 'components/header/Header.module.scss';
import CustomModal from 'components/customModal/CustomModal';
import InputField from 'components/textField/InputField';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import { IMAGE_URL } from 'store/services/URL';
import { callLogout } from 'store/actions/auth.actions';
import { useDispatch } from 'react-redux';
import { getCurrentDate } from 'helpers/utils';
import { createRoom as roomCreate } from 'store/services/firebase-broadcast';
import { ref, onValue } from 'firebase/database';
import { db } from 'utils/firebase-config';
import { CardList, Subscription } from 'modules/types/types';
const Stripe = React.lazy(() => import('views/Stripe/Stripe'));

interface HeaderProps {
  getSubscriptionRequest: Function;
  createRoomRequest: Function;
  showPopup?: boolean;
  isLoading: boolean;
  status: boolean;
  createdRoom: any;
  subscriptions: Subscription;
  card: CardList;
  callStatus?: Function;
}
const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { getSubscriptionRequest, createRoomRequest, isLoading, showPopup, createdRoom, subscriptions, card, callStatus } = props;
  const history = useHistory();
  const [topic, setTopic] = useState('');
  const [showDropDown, setShowDropdown] = useState(false);
  const [createRoom, setCreateRoom] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [limit, setLimit] = useState<any>(28);
  const [length, setLength] = useState<any>(0);
  const [error, setError] = useState(false);
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const [moveCallScreen, setMoveCallScreen] = useState<boolean>(false);
  const [conversations, setConversations] = useState<any>([]);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user') as string);

  const handleChange = (event: any) => {
    setTopic(event);
    if (length > event.length) {
      setLimit(limit + 1);
      setLength(event.length);
    } else {
      setLimit(limit - 1);
      setLength(length);
    }
  };

  const handleNext = () => {
    if (topic.length > 0 ) {
      createRoomRequest({
        topic
      });
      setCreateRoom(false);
      !user.is_super_host && setSubscribed(true);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (createdRoom.hasOwnProperty('pk')) {
      setMoveCallScreen(true);
      // roomCreate(createdRoom).then(() => setMoveCallScreen(true));
    }
  }, [createdRoom]);

  useEffect(() => {
    onValue(ref(db, `rooms`), (querySnapshot: any) => {
      const rooms: any = [];
      querySnapshot.forEach((doc: any) => {
        rooms.push(doc.val());
      });
      setConversations(rooms);
    });
  }, []);

  useEffect(() => {
    if (user.is_super_host && moveCallScreen) {
      history.push({pathname: '/chat-room', state: {conversations: conversations, selected: conversations[conversations.length - 1]}});
      // history.push({ pathname: '/call', state: { selectedBroadCast: createdRoom}});
    }
  }, [moveCallScreen]);

  useEffect(() => {}, [isLoading]);

  useEffect(() => {
    setCreateRoom(showPopup || createRoom);
  }, [showPopup]);

  const handleCreateRoom = () => {
    document.body.classList.add('modal-open');
    return (
      <>
        <p className="fontSixteen font-weight-600 text-black">Enter your topic for this room</p>
        <p>
          Character limit:
          <strong> 28</strong>
        </p>
        <InputField placeholder="Topic" type="text" handleChange={(e: any) => handleChange(e)} />
        <Button label="Next" cssClass={`${Styles.nextBtn} ${error && 'errorButton'}`} showLoading={isLoading} clickHandler={handleNext} icon />
        {error && <TextErrorMessage cssClass="text-center" message="Enter a topic to continue." />}
        <div
          className="text-uppercase text-blue font-weight-600 mt-3"
          onClick={closeDialog}
          onKeyPress={closeDialog}
          role="button"
          tabIndex={0}
        >
          Close
        </div>
      </>
    );
  };

  const handleSubscribeSuperHost = () => {
    setSubscribed(false);
    setPaymentPopUp(true);
  };

  const stripePaymentModal = () => {
    document.body.classList.add('modal-open');
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Stripe type="subscribe" planId='' card={card} subscription={subscription} closeDialog={paymentDone} />
      </Suspense>
    );
  };

  const handleSubscribed = () => (<div className="text-center">
      <img src={subscriptionIcon} alt="subscription super host" className={Styles.superHostImage} />
      <h4 className="mt-4 font-weight-600">Subscribe to be a Super Host!</h4>
      <p className="mt-30 mb-0">
        To join a room with more than 3 people will cost your guest a gold piece. A premium super host subscription will
        wave all of the fees for your guests.
      </p>
      <Button label="Subscribe" cssClass={Styles.subscribeBtn} clickHandler={handleSubscribeSuperHost} />
      <div
        className="text-uppercase text-blue font-weight-600 mt-3"
        onClick={handleSkip}
        onKeyPress={handleSkip}
        role="button"
        tabIndex={0}
      >
        Skip
      </div>
    </div>);

  const paymentDone = () => {
    document.body.classList.remove('modal-open');
    setCreateRoom(false);
    setSubscribed(false);
    setPaymentPopUp(false);
    history.push({ pathname: '/call', state: { selectedBroadCast: createdRoom}});
  };

  const handleSkip = () => {
    document.body.classList.remove('modal-open');
    setCreateRoom(false);
    setSubscribed(false);
    setPaymentPopUp(false);
    history.push({pathname: '/chat-room', state: {conversations: conversations, selected: conversations[conversations.length - 2]}});
    // history.push({ pathname: '/call', state: { selectedBroadCast: createdRoom}});
  };

  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setCreateRoom(false);
    setSubscribed(false);
    setPaymentPopUp(false);
    setError(false);
  };

  const handleDropDown = () => {
    setShowDropdown(!showDropDown);
  };

  const handleBgDropDown = () => {
    setShowDropdown(!showDropDown);
  };

  const showPublicProfile = () => {
    history.push('/profile');
  };

  const handleLogOut = () => {
    dispatch(callLogout());
    history.push('/login');
  };

  useEffect(() => {
    if (topic.length > 0) {
      setError(false);
    }
  }, [topic.length])

  useEffect(() => {
    setSubscription(subscriptions);
  }, [subscriptions]);

  useEffect(() => {}, [card]);

  useEffect(() => {
    getSubscriptionRequest({});
  }, []);

  return (
    <header>
      <div className="custom-container h-100">
        <div className={Styles.headerWrapper}>
          <Link to="/conversations-nearby" className={`d-inline-block ${Styles.logoWrapper}`}>
            <img src={logo} alt="logo" className={Styles.logo} />
          </Link>
          <div className={Styles.profileName}>
            <Button
              label="CREATE ROOM"
              clickHandler={() => setCreateRoom(true)}
              cssClass={Styles.createRoomWidth}
              iconClass="fas fa-plus"
              icon
            />
            <span className={Styles.divider} />
            <div
              className="d-flex column-gap-20 align-items-center position-relative"
              onClick={handleDropDown}
              role="button"
              onKeyPress={handleDropDown}
              tabIndex={0}
            >
              <div className={`d-inline-block ${Styles.profileLogoWrapper}`}>
                <img src={`${IMAGE_URL}${user.profile}`} alt="profile" className={Styles.profileIcon} />
              </div>
              <p className={`mb-0 fontSixteen text-blue font-weight-bolder text-capitalize ${Styles.userNameWrapper}`}>
                <span>{user && user.name}</span>
                <i className="fas fa-angle-down ml-2" />
              </p>
            </div>
            {showDropDown && (
              <>
                <div className={Styles.dropDownWrapper}>
                  <ul className={`${Styles.dropDownInner} list-unstyled`}>
                    <li>
                      <Link
                        className="text-decoration-none text-black fontSixteen font-weight-normal"
                        to="/conversations-nearby"
                      >
                        <img className="pr-2" src={dashboardIcon} alt="dashboard" />
                        Home
                      </Link>
                    </li>
                    <li>
                      <div
                        className="d-flex"
                        onClick={showPublicProfile}
                        onKeyPress={showPublicProfile}
                        role="button"
                        tabIndex={0}
                      >
                        <img className="pr-2" src={userIcon} alt="user" />
                        <p className="text-decoration-none mb-0 text-black fontSixteen font-weight-normal">
                          View Public Profile
                        </p>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="text-decoration-none text-black fontSixteen font-weight-normal"
                        to="/profile/edit"
                      >
                        <img className="pr-2" src={settingIcon} alt="settings" />
                        Settings
                      </Link>
                    </li>
                    <li>
                      <div onClick={handleLogOut} onKeyPress={handleLogOut} role="button" tabIndex={0}>
                        <img className="pr-2" src={logoutIcon} alt="logout" />
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className={Styles.bgDropDown}
                  onClick={handleBgDropDown}
                  role="button"
                  onKeyPress={handleBgDropDown}
                  tabIndex={0}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {createRoom && (
        <CustomModal wrapperClass={Styles.createRoomWrapper} title="Create Room" closeDialog={closeDialog}>
          {handleCreateRoom()}
        </CustomModal>
      )}
      {subscribed && (
        <CustomModal wrapperClass={Styles.createRoomWrapper} closeDialog={closeDialog}>
          {handleSubscribed()}
        </CustomModal>
      )}
      {paymentPopUp && (
        <CustomModal closeDialog={closeDialog} title="Subscribe" wrapperClass={Styles.rechargeAccountWrapper}>
          {stripePaymentModal()}
        </CustomModal>
      )}
    </header>
  );
};

export default Header;