import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Jitsi from 'components/Jitsi/Jitsi';
import { broadCastStorage } from 'helpers/utils';

interface IBroadCast {
  roomStatus: Function;
  roomUpdate: boolean;
}

const BroadCast: FunctionComponent<IBroadCast> = (props: IBroadCast) => {
  const { roomStatus, roomUpdate } = props;
  const user = JSON.parse(localStorage.getItem('user') || '');
  const history = useHistory();
  const location: any = useLocation();
  const [broadCastItem, setBroadCastItem] = useState<any>({});

  useEffect(() => {
    setBroadCastItem(location.state.selectedBroadCast);
  }, []);

  const handleClose = () => {
    broadCastStorage.clear();
    roomStatus({
      id: broadCastItem.pk,
      status: 'ENDED',
    });
    if (user.pk !== broadCastItem.host.pk) {
      history.push('/conversations-nearby');
      window.location.reload();
    }
  };

  useEffect(() => {
    if (user.pk !== location.state.selectedBroadCast.host.pk) {
      if (roomUpdate) {
        history.push('/conversations-nearby');
        window.location.reload();
      }
    }
  }, [roomUpdate]);

  useEffect(() => {
    const time = localStorage.getItem('start_time');
    if(time === null || time === '') {
      localStorage.setItem('start_time', JSON.stringify(+new Date()));
    }
  }, []);

  return (
    <Jitsi handleClose={handleClose} broadCast={broadCastItem} user={user} />
  );
};

export default BroadCast;
