import { connect } from 'react-redux';
import OTPVerification from 'views/register/otpVerification/OTPVerification';
import { getOTPVerified, otpVerificationError, getLoader } from 'store/selector/auth.selector';
import { verifyOTPRequest } from 'store/actions/auth.actions';

const mapDispatchToProps = { verifyOTP: verifyOTPRequest };

const mapStateToProps = (state: any) => {
  const isVerified = getOTPVerified(state);
  const isLoading = getLoader(state);
  const error = otpVerificationError(state);
  return { isVerified, error, isLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(OTPVerification);