import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from 'components/header/Header.container';
import InputField from 'components/textField/InputField';
import searchIcon from 'assets/images/search.png';
import groupChatImage from 'assets/svg/group-chat-icon.svg';
import distanceImage from 'assets/svg/distance.svg';
import userImage from 'assets/svg/user_orange.svg';
import sadImage from 'assets/svg/sad-icon.svg';
import goldPieceImage from 'assets/svg/gold-piece.svg';
import superHostImage from 'assets/svg/superhost-subscription.svg';
import superHostKey from 'assets/images/superhost_user_key.png';
import NearbyItem from 'views/conversationsNearby/nearbyItem/index';
import SliderWrapper from 'components/controls/Slider/SliderWrapper';
import { broadCastNearby } from 'components/controls/Slider/SliderResponsiveness';
import CustomModal from 'components/customModal/CustomModal';
import Button from 'components/button/Button';
import Footer from 'components/footer/Footer';
import Styles from './ConversationsNearby.module.scss';
import ProfileStyles from 'views/profile/publicProfile/PublicProfile.module.scss';
import { conversation } from 'helpers/data/conversations';
import { IMAGE_URL } from 'store/services/URL';
import { getDistance } from 'helpers/utils';
import Loader from 'components/loader/loader';
import { useGetCallStatus } from 'store/services/firebase-broadcast';
import CardItem from '../profile/publicProfile/card/item';
import distanceIcon from 'assets/svg/distance.svg';
import userIcon from 'assets/svg/user_orange.svg';
import Cookies from 'js-cookie';
import { ref, onValue } from 'firebase/database';
import { db, storage, reference, getDownloadURL, listAll } from 'utils/firebase-config';
interface ConversationsNearbyProps {
  nearbyRooms: Function;
  joinRequest: Function;
  getRoom: Function;
  createLocation: Function;
  rooms: any;
  createdRoom: any;
  allNearByRooms: any;
  loader: boolean;
  getPublicProfile: Function;
  userPublicProfile: any;
  followProfile: Function;
}

const ConversationsNearby: FunctionComponent<ConversationsNearbyProps> = (props: ConversationsNearbyProps) => {
  const { rooms, createLocation, nearbyRooms, allNearByRooms, loader, getPublicProfile, userPublicProfile, followProfile, createdRoom } = props;
  const user = JSON.parse(localStorage.getItem('user') as string);
  const [isExpand, setIsExpand] = useState(false);
  const [isJoinRoom, setIsJoinRoom] = useState(false);
  const [isNoGoldPiece, setIsNoGoldPiece] = useState(false);
  const [isNoFees, setIsNoFees] = useState(false);
  const [joinedRoom, setJoinRoom] = useState('');
  const [createRoomPopup, setCreateRoomPopup] = useState(false);
  const [searchTopic, setSearchTopic] = useState('');
  const [conversationNearby, setConversationNearby] = useState<any>([]);
  const [conversations, setConversations] = useState<any>([]);
  const [newRoom, setNewRoom] = useState<any>({});
  const [joined, setJoined] = useState<boolean>(false);
  const [callStatus, setCallStatus] = useState<boolean>(false);
  const [viewProfile, setViewProfile] = useState<boolean>(false);
  const [profile, setProfile] = useState(user);
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
  });
  const history = useHistory();
  const [expandedRoom, setExpandedRoom] = useState(conversation);
  const status = useGetCallStatus(expandedRoom.pk);
  const token = localStorage.getItem('accessToken');
  const [publicProfile, setPublicProfile] = useState<any>();
  const [showToolTip, setShowToolTip] = useState(false);
  const [followToggle, setFollowToggle] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);

  const profileCardDetails = [
    {
      title: 'Number of Rooms Started',
      description: '',
      total: publicProfile ? publicProfile.rooms_started : '',
    },
    {
      title: 'Number of Hours Logged',
      description: '',
      total: publicProfile ? (publicProfile.logged_hours / 60000).toFixed(2) : '',
    },
  ];

  const closeModal = () => {
    setModalToggle(false)
  }

  const handleFollow = () => {
    setFollowToggle(!followToggle);
    const { pk } = profile;
    followProfile({pk});
  };

  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setIsExpand(false);
    setIsJoinRoom(false);
    setIsNoGoldPiece(false);
    setIsNoFees(false);
    setViewProfile(false);
  };

  const joinRoom = () => {
    setIsExpand(false);
    document.body.classList.add('modal-open');
    if (expandedRoom.host.is_super_host) {
      if (joined) {
        history.push({ pathname: '/call', state: { selectedBroadCast: expandedRoom} });
      } else {
        setIsNoFees(true);
      }
    } else {
      if (joined) {
        history.push({ pathname: '/call', state: { selectedBroadCast: expandedRoom} });
      } else {
        if (expandedRoom.participants.length === 3) {
          setIsJoinRoom(true);
        } else {
          history.push({ pathname: '/call', state: { selectedBroadCast: expandedRoom} });
        }
      }
    }
  };

  const joinGoldPiece = () => {
    if (user.gold_coin === "0") {
      setIsJoinRoom(false);
      document.body.classList.add('modal-open');
      setIsNoGoldPiece(true);
    } else {
      setIsJoinRoom(false);
      document.body.classList.add('modal-open');
      history.push({ pathname: '/call', state: { selectedBroadCast: joinedRoom} });
    }
  };

  const handleBrowseRooms = () => {
    setIsJoinRoom(false);
    document.body.classList.add('modal-open');
    setIsNoGoldPiece(false);
    history.push('/conversations-nearby');
  };

  const handleStartRoom = () => {
    setIsJoinRoom(false);
    document.body.classList.add('modal-open');
    setCreateRoomPopup(true);
  };

  const handleSuperHost = () => {
    history.push({ pathname: '/call', state: { selectedBroadCast: joinedRoom} });
    document.body.classList.add('modal-open');
    setIsNoFees(false);
  };

  const handleRechargeAccount = () => {
    setIsNoGoldPiece(false);
    document.body.classList.add('modal-open');
    setIsNoFees(false);
    history.push('/currency');
  };

  const handleChat = () => {
    history.push({pathname: '/chat-room', state: {conversations: conversations, selected: conversations[conversations.length - 2]}});
  };

  const getLocation = () => {
    if (location.latitude === '' && location.longitude === '') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            setLocation({
              longitude: position.coords.longitude.toString(),
              latitude: position.coords.latitude.toString()
            });
        }, function(e) {
            setModalToggle(true)
        },{timeout:5000});
      } else {
        alert('no geolocation support');
      }
    }
  };

  const handleSearch = (topic: string) => {
    setSearchTopic(topic);
    if (topic !== '') {
      nearbyRooms({topic});
    } else {
      nearbyRooms({});
    }
  };

  useEffect(() => {
    const { pk } = profile;
    getPublicProfile({pk});
  }, [profile]);

  useEffect(() => {
    setPublicProfile(userPublicProfile);
  }, [userPublicProfile]);

  useEffect(() => {
    if (searchTopic) {
      const newArr = conversationNearby.filter((topic: string) =>
        Object.values(topic).some(
          (val) => typeof val === 'string' && val.toLowerCase().includes(searchTopic.toLowerCase()),
        ),
      );
      setConversationNearby([...newArr]);
    } else {
      if (allNearByRooms.length > 0) {
        setConversationNearby(rooms);
      }
      setConversationNearby(rooms);
    }
  }, [searchTopic]);

  useEffect(() => {
    if (expandedRoom.participants.length > 0) {
      expandedRoom.participants.map((i: any) => {
        if (i.pk === user.pk || expandedRoom.host.pk === user.pk) {
          setJoined(true);
          return '';
        }
        return '';
      });
    } else {
      if (expandedRoom.host.pk === user.pk) {
        setJoined(true);
      }
    }
  }, [expandedRoom]);

  useEffect(() => {
    setConversationNearby(rooms)
  }, [rooms]);

  useEffect(() => {
    if (newRoom.hasOwnProperty('pk')) {
      setConversationNearby([...conversationNearby, newRoom]);
    }
  }, [newRoom]);

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (location.latitude && location.longitude) {
      createLocation(location);
      setTimeout(() => {
        nearbyRooms({});
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    if (allNearByRooms.length > 0) {
      const allRooms: any = [];
      allNearByRooms.forEach((doc: any) => {
        const data = doc;
        if (data.host?.latitude && data.host?.longitude) {
          allRooms.push({
            ...data,
            distance: getDistance(
              user?.latitude,
              user?.longitude,
              data.host?.latitude,
              data.host?.longitude
            ),
          });
        }
      });
      if (allRooms?.length > 0) {
        allRooms.sort((a: any, b: any) => {
          if((a.status === 'VIDEO' && b.status === 'VIDEO') || (a.status !== 'VIDEO' && b.status !== 'VIDEO')) {
            if (a.distance !== b.distance ) {
              return a.distance - b.distance;
            } else {
              return a.created_at > b.created_at ? -1 : 0
            }
          } else {
            return a.status === 'VIDEO' && b.status !== 'VIDEO' ? -1 : 0;
          }
        });

      }
      setConversationNearby(allRooms);
    }
  }, [allNearByRooms]);

  useEffect(() => {
    if(!user && token === '') {
      history.push('/login');
    }
  }, [user, token]);

  useEffect(() => {
    onValue(ref(db, `rooms`), (querySnapshot: any) => {
      const rooms: any = [];
      querySnapshot.forEach((doc: any) => {
        doc.val().hasOwnProperty('host') && rooms.push(doc.val());
      });
      setConversations(rooms);
    });
  }, []);
  
  const handleExpandedRoom = () => (<>
      <div className={Styles.modalWrapper}>
        <div className={Styles.modalHeader}>
          <h5 className={`m-0 ${Styles.modalTitle}`}>
            TOPIC:
            <span className={Styles.topicButton}>{expandedRoom.topic}</span>
          </h5>
          <div className={Styles.modalRightHeader}>
            <div className="d-flex align-items-center column-gap-10">
              <img src={distanceImage} alt="location" />
              <span className="fontFifteen font-weight-normal text-black">{getDistance(user.latitude, user.longitude, expandedRoom.host.latitude, expandedRoom.host.longitude)} KM</span>
            </div>
            <div className="d-flex align-items-center column-gap-10">
              <img src={userImage} alt="user" />
              <span className="fontFifteen font-weight-normal text-black">
                {`${expandedRoom.participants.length} people are in this room`}
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.modalBodyWrapper}>
          <div className={Styles.hostWrapper}>
            <div
              className={Styles.hostInner}
              onClick={() => {
                setProfile(expandedRoom.host)
                setViewProfile(true)
              }}
              onKeyPress={() => {
                setProfile(expandedRoom.host)
                setViewProfile(true)
              }}
              role="button"
              tabIndex={0}
            >
              <img src={`${IMAGE_URL}${expandedRoom.host.profile}`} alt="host img" className={Styles.hostImage} />
              {expandedRoom.host.is_super_host && <img src={superHostKey} alt="key img" className={Styles.superKeyImage} />}
            </div>
            <div>
              <h5 className={Styles.hostName}>{expandedRoom.host.name}</h5>
              <span className="fontFifteen font-weight-normal input-color">Host</span>
            </div>
          </div>
          <div className={Styles.expandRoomListing}>
            {expandedRoom.participants
              .map((i: any, key: number) => (
                <div
                  className={`text-center ${Styles.expandRoomDiv}`}
                  key={key}
                  onClick={() => {
                    setProfile(i)
                    setViewProfile(true)
                  }}
                  onKeyPress={() => {
                    setProfile(i)
                    setViewProfile(true)
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <img src={`${IMAGE_URL}${i.profile}`} alt="expand room img" />
                  <span className="fontThirteen font-weight-normal text-black d-inline-block">{i.name}</span>
                </div>
              ))}
          </div>
          <div className="text-center mt-4">
            {/*{expandedRoom.host.pk === user.pk && <Button label="Join Room" cssClass={Styles.joinRoomButton} clickHandler={joinRoom} />}*/}
            {/*{(expandedRoom.host.pk !== user.pk && status.call_status) && <Button label="Join Room" cssClass={Styles.joinRoomButton} clickHandler={joinRoom} />}*/}
            <div className="" onKeyPress={closeDialog} onClick={closeDialog} role="button" tabIndex={0}>
              <p
                className="m-0 text-blue fontFourteen font-weight-600
              d-inline-block pt-2 pb-4 text-uppercase"
              >
                Close
              </p>
            </div>
          </div>
        </div>
      </div>
    </>);

  const goldPieceModal = () => (<div className={Styles.goldPieceWrapper}>
      <img src={goldPieceImage} alt="gold piece" />
      <p className="fontSixteen text-black front-weight-400">Spend 1 Gold Piece and join the room?</p>
      <Button label="Join Room" clickHandler={joinGoldPiece} cssClass={Styles.joinRoomButton} />
      <div>
        <a onClick={handleStartRoom} className="cursor-pointer text-decoration-none mt-2 d-inline-block text-blue font-weight-600 fontFourteen">
          START YOUR OWN ROOM
        </a>
      </div>
    </div>);

  const noGoldPiece = () => (<div className={Styles.noGoldPiece}>
      <img src={sadImage} alt="gold piece" />
      <h4 className={Styles.description}>Uh oh! It looks like you don’t have any gold pieces.</h4>
      <Button label="RECHARGE ACCOUNT" clickHandler={handleRechargeAccount} cssClass={Styles.joinRoomButton} />
      <div>
        <a onClick={handleBrowseRooms} className="cursor-pointer text-decoration-none mt-2 d-inline-block text-blue font-weight-600 fontFourteen">
          BROWSE ROOMS &nbsp;
          <i className="fas fa-long-arrow-alt-right" />
        </a>
      </div>
    </div>);

  const superHostModal = () => (<div className={Styles.noGoldPiece}>
      <img src={superHostImage} alt="gold piece" />
      <h4 className={Styles.description}>This is a Super Host room and has no fees. Thank your host!</h4>
      <Button label="Join Room" clickHandler={handleSuperHost} showLoading={loader} cssClass={Styles.joinRoomButton} />
      <div onClick={closeDialog} onKeyPress={closeDialog} tabIndex={0} role="button">
        <p className="text-uppercase text-decoration-none mt-2 d-inline-block text-blue font-weight-600 fontFourteen">
          Close
        </p>
      </div>
    </div>);

  const publicProfileModal = () => (<>
      <section>
        <section className={`${ProfileStyles.profileBox} mt-0`}>
          <div className={ProfileStyles.profileBoxInner}>
            <div className={ProfileStyles.profileImageWrapper}>
              <img src={`${IMAGE_URL}${profile.profile}`} loading="lazy" className={ProfileStyles.profileImage} alt="profile img" />
              {user.is_super_host && <img src={superHostKey} loading="lazy" alt="is super host" className={ProfileStyles.superHostKey} />}
            </div>
            <div>
              <h3>{profile.name}</h3>
              <p>ID NUMBER: {profile.pk}</p>
            </div>
          </div>
          <div className="position-relative">
            {user.pk !== profile.pk && (
              <button
              type="button"
              className={ProfileStyles.followBtn}
              onClick={handleFollow}
              onKeyUp={handleFollow}
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
              >
              {publicProfile && publicProfile.is_profile_follow ? 'Unfollow' : 'Follow'}
              </button>
            )}
            {showToolTip && (
              <div className={`${ProfileStyles.followToolTip} ${Styles.leftZero}`}>
                <p className={`mb-0 ${ProfileStyles.toolTip}`}>
                  Follow to receive notifications when this host starts a room.
                </p>
              </div>
            )}
          </div>
        </section>
        <div className={ProfileStyles.cardWrapper}>
          {profileCardDetails.map((item, key) => (
            <CardItem cssClass={Styles.profileCardHeading} title={item.title} description={item.description} total={item.total} key={key} />
          ))}
        </div>
        <hr />
        <div className={`pb-0 ${ProfileStyles.openCardWrapper}`}>
          {(publicProfile && publicProfile.opened_room) && Object.keys(publicProfile.opened_room).length > 0 && (
            <>
            <h4>Currently Open Room</h4>
            <div className={`${ProfileStyles.openRoomCard} ${Styles.openRoomCardPublic}`}>
              <div className={ProfileStyles.openCardInner}>
                <div>
                  <div className="d-flex justify-content-start align-items-center">
                    <h5 className="mb-0 text-black font-weight-500 text-uppercase">TOPIC:</h5>
                    <div className={ProfileStyles.topicBtn}>{publicProfile.opened_room.topic}</div>
                  </div>
                  <div
                    className={`d-flex justify-content-start align-items-center column-gap-20 mt-4 
                  ${ProfileStyles.distanceWrapper}`}
                  >
                    <div>
                      <img src={distanceIcon} alt="distance" />
                      <span>{publicProfile.distance_in_km.toFixed(2)} KM</span>
                    </div>
                    <div>
                      <img src={userIcon} alt="distance" />
                      <span>{publicProfile.total_participants} people are in this room</span>
                    </div>
                  </div>
                </div>
                {/*<div className={ProfileStyles.joinBtnWrapper} onClick={joinRoom} onKeyPress={joinRoom} tabIndex={0} role="button">*/}
                {/*  <img src={joinIcon} alt="join btn" />*/}
                {/*</div>*/}
              </div>
            </div>
            </>
          )}
        </div>
      </section>
      <div className="text-center" onClick={closeDialog} onKeyPress={closeDialog} tabIndex={0} role="button">
        <p className="text-uppercase text-decoration-none mt-2 d-inline-block text-blue font-weight-600 fontFourteen">
          Close
        </p>
      </div>
    </>);
    
  return (
    <>
      <div className={Styles.wrapper}>
        <Header showPopup={createRoomPopup} callStatus={setCallStatus} />
        <div className={Styles.body}>
          <div
            className={Styles.groupChat}
            onClick={handleChat}
            onKeyUp={handleChat}
            role="button"
            tabIndex={0}
          >
            {/*<span className={Styles.chatIcon}>2</span>*/}
            <img src={groupChatImage} alt="group chat" />
          </div>
          <div className={Styles.converNearByBg}>
            <div className="custom-container">
              <div className={Styles.searchbarWrapper}>
                <p className="fontTwenty font-weight-600 text-black mb-0">Conversations Nearby</p>
                <div className={`${Styles.searchBox} form-group mb-0`}>
                  <InputField
                    placeholder="Search for topics near you"
                    cssClass={Styles.searchField}
                    type="txt"
                    icon={searchIcon}
                    handleChange={(e: any) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${Styles.nearbyItemWrapper}`}>
            <div className="custom-container">
              {searchTopic !== '' && (
                  <div className='text-center'>
                    <h6 className={Styles.searchResultsTopic}>Search results for the topic {`"${searchTopic}"`}</h6>
                  </div>
              )}
              {loader ? <Loader spinner={Styles.loaderIcon} aligned /> : (
                conversationNearby.length === 0 && searchTopic === '' ? (
                  <div className='text-center'>
                    <h6>Enable browser location and refresh page</h6>
                  </div>
                ) : (
                  <>
                    {nearbyRooms.length > 0 && <p>{`Search results for the topic ${searchTopic}`}</p>}
                    <SliderWrapper sliderSetting={broadCastNearby} slideToShow={6} goto={6}>
                      {conversationNearby.map((item: any) => (
                        <NearbyItem
                          item={item}
                          conversations={conversationNearby}
                          handleExpand={() => {
                            setIsExpand(true);
                            setExpandedRoom(item);
                          }}
                          key={Date.now()}
                          joinRoom={setJoinRoom}
                          roomWithFees={setIsJoinRoom}
                          roomWithNoFees={setIsNoFees}
                          viewProfile={setViewProfile}
                          profile={setProfile}
                        />
                      ))}
                    </SliderWrapper>
                  </>
                )
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {isExpand && (
        <CustomModal closeDialog={closeDialog} wrapperClass={Styles.modalWrapperClass}>
          {handleExpandedRoom()}
        </CustomModal>
      )}
      {isJoinRoom && (
        <CustomModal
          wrapperClass={Styles.wrapperGoldModal}
          title="Joining a room with more than 3 people will cost 1 Gold Piece."
          closeDialog={closeDialog}
        >
          {goldPieceModal()}
        </CustomModal>
      )}
      {isNoGoldPiece && (
        <CustomModal wrapperClass={Styles.wrapperNoGoldPiece} closeDialog={closeDialog}>
          {noGoldPiece()}
        </CustomModal>
      )}
      {isNoFees && (
        <CustomModal wrapperClass={Styles.wrapperNoPiece} closeDialog={closeDialog}>
          {superHostModal()}
        </CustomModal>
      )}
      {viewProfile && (
        <CustomModal wrapperClass={Styles.publicProfileWrapper} heightClass={Styles.publicProfileBody} closeDialog={closeDialog}>
          {publicProfileModal()}
        </CustomModal>
      )}
      {modalToggle && (
        <CustomModal
          modalTitle={"Social Play needs your location to load nearby rooms.  Please go to settings and enable location sharing for socialplay.com."}
          wrapperClass={Styles.publicProfileWrapper}
          heightClass={Styles.publicProfileBody}
          closeDialog={closeDialog}
          modalTitleClass={Styles.modalTitleClass}
          closeModal={closeModal}
          close
        >
        </CustomModal>
      )}
    </>
  );
};
export default ConversationsNearby;
