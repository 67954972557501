import React, {FC, useEffect, useState, useRef} from 'react';
import { connect, useDispatch } from 'react-redux';
import chatInfoImage from 'assets/svg/chat-info.svg';
import joinImage from 'assets/svg/join button.svg';
import socialPlayFavIcon from 'assets/images/socialPlayFavIcon.png';

import Styles from 'components/chatContent/chatHeader/ChatHeader.module.scss';
import {
  deleteRoom,
  deleteRequest,
  sendJoinRequest,
  updateParticipantsInRoom,
} from 'store/services/firebase-broadcast';
import { useHistory } from "react-router";
import deleteIcon from 'assets/svg/delete icon.svg';
import userRequestIcon from 'assets/images/user-request.png';
import CustomModal from 'components/customModal/CustomModal';
import { IMAGE_URL } from 'store/services/URL';
import { getCurrentDate, randomString, setTime } from 'helpers/utils';
import { onValue, ref } from "firebase/database";
import { db } from 'utils/firebase-config';
import Button from 'components/button/Button';
import Toast from 'components/toast/Tost';
import { invitePeopleRequest } from 'store/actions/general.actions';
import { roomStatusRequest } from 'store/actions/conversation.actions';
import api from 'store/services/conversation.service';

interface ChatHeaderProps {
  cssClass?: string;
  name: string;
  chatInfo?: Function;
  selected: any;
  isJoined: boolean;
  invitePeople: Function;
}

const ChatHeader: FC<ChatHeaderProps> = (props: ChatHeaderProps) => {
  const { cssClass, name, chatInfo, selected, isJoined, invitePeople } = props;
  const [modalToggle, setModalToggle] = useState(false);

  const [toast, setToast] = useState(false);
  const [toastValues, setToastValues] = useState({message: '', type: ''});
  const [joinRequest, setJoinRequest] = useState(false);
  const [requests, setRequests] = useState([]);
  const dispatch = useDispatch();
  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setJoinRequest(false);
  };

  const closeModal = () => {
      setModalToggle(false)
  }

  const history = useHistory();

  const showChatInfo = () => {
    if (chatInfo) chatInfo();
  };

  const handleDeleteRoom = () => {
    const obj = {
        roomId: selected.pk,
    }
    deleteRoom(obj).then(async (e) => {
      dispatch(roomStatusRequest({
        id: selected.pk,
        status: 'DELETED',
      }));
      setTimeout(() => {
        history.push('conversations-nearby');
        window.location.reload();
      }, 500);
    }).catch(() => {
      setToastValues({
        message: 'Something went wrong!',
        type: 'Error',
      });
      setToast(true);
    });
  };

  const user = JSON.parse(localStorage.getItem('user') || '');

  const handleJoinRoom = () => {
    const request = {
      roomId: selected.pk,
      id: `${selected.pk}-${randomString()}`,
      user: user,
      time: getCurrentDate(),
    };

    sendJoinRequest(request).then((e) => {
      setToastValues({
        message: 'Join request has been sent',
        type: 'Success',
      });
      setModalToggle(true)
      setToast(true);
    }).catch(() => {
      setToastValues({
        message: 'Something went wrong!',
        type: 'Error',
      });
      setToast(true);
    });
  };

  const handleAccept = (id: string, user: any) => {
    const participants = selected?.participants?.length > 0 ? selected?.participants : [];
    const obj = {
      roomId: selected.pk,
      user: [...participants,user],
    }
    updateParticipantsInRoom(obj).then((e) => {
      handleReject(id);
    }).catch(() => {
      setToastValues({
        message: 'Something went wrong!',
        type: 'Error',
      });
      setToast(true);
    });
  };

  const handleReject = (id: string) => {
    const msgPath = `${selected.pk}/requests/${id}`;
    deleteRequest(msgPath).then(() => {
      setToastValues({
        message: 'Request has been rejected!',
        type: 'Success',
      });
      setToast(true);
    }).catch(() => {
      setToastValues({
        message: 'Something went wrong!',
        type: 'Error',
      });
      setToast(true);
    });
    return '';
  };

  const joinRequests = () => (
    <div className="px-3">
      {requests.length === 0 ? (
        <div className="text-center">
          <p>No Request Found!</p>
        </div>
      ) : (
        requests.map((item: any, index: number) => (
            <div className={`mb-3 ${Styles.userDetails}`} key={index}>
              <img src={`${IMAGE_URL}${item.user.profile}`} alt="user" className={Styles.userImage} />
              <div className={Styles.detailsInner}>
                <div>
                  <h5 className="my-0">{item.user.name}</h5>
                  <span className="mb-0 fontFifteen font-weight-normal input-color">{item.user.email}</span><br/>
                  <span className="mb-0 fontFifteen font-weight-normal input-color">{item.time}</span>
                </div>
                <div>
                  <button type="button" onClick={() => handleAccept(item.id, item.user)} className="btn btn-sm btn-outline-primary">Accept</button>
                  <button type="button" onClick={() => handleReject(item.id)} className="ml-3 btn btn-sm btn-outline-danger">Reject</button>
                </div>
              </div>
            </div>
        ))
      )}
      <div className="text-center">
        <Button label="Close" clickHandler={closeDialog} cssClass={Styles.closeButton} />
      </div>
    </div>
  );

  const joinToggleMsg = () => (
    <div className={Styles.modalTopConatiner}>
      <div>
        <img
          src={socialPlayFavIcon}
          alt="chat info"
          className={Styles.joiImage}
        />
        <div className={`${Styles.modalBottomContent} text-center`}>
          We'll notify you when they accept. Until then browse some more room
          topics.
        </div>
        <div className="text-center">
        <Button
          label="BROWSE ROOM TOPICS"
          clickHandler={() => history.push("conversations-nearby")}
          cssClass={`${Styles.createRoomWidth}`}
        />
        </div>
      </div>
    </div>
  );

  useEffect(() => {}, []);

  useEffect(() => {
    if (selected?.pk) {
      onValue(ref(db, `requests/${selected.pk}/requests`), (querySnapshot: any) => {
        const requests: any = [];
        querySnapshot.forEach((doc: any) => {
          requests.push(doc.val());
        });
        if (requests.length > 0) {
          setRequests(requests);
        } else {
          setRequests([]);
        }
      });
    } 
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setToast(false);
    }, 6000);

    return () => {
      clearInterval(interval);
    }

  }, []);

  const handleJoin = async () => {
    // If i am the owner of the room
    if (selected?.host?.pk === user?.pk) {
      if (selected?.participants?.length > 0) {
        const emails = [];
        for (const item of selected?.participants) {
          item && emails.push(item.email);
        }

        await api.sendNotificationEmail({
          emailTo: emails.join(','),
          notification: `The video has been started by the Host (${user.name}) for the room ${selected.topic}.
          Please join the room.`,
          subject: `${selected.topic}'s video started by host`
        });
      }
      dispatch(roomStatusRequest({
        id: selected.pk,
        status: 'VIDEO',
      }));
    }
    if (selected?.host?.is_super_host) {
      if (isJoined) {
        history.push({ pathname: '/call', state: { selectedBroadCast: selected} });
      } else {
        // roomWithNoFees(true);
        // joinRoom(item);
      }
    } else {
      if (isJoined) {
        history.push({ pathname: '/call', state: { selectedBroadCast: selected} });
      } else {
        if (selected?.participants.length >= 3) {
          // roomWithFees(true);
          // joinRoom(item);
        } else {
          history.push({ pathname: '/call', state: { selectedBroadCast: selected} });
        }
      }
    }
  }
  return (
    <>
      <div className={`${cssClass} ${Styles.chatHeader}`}>
        <h4 className={`my-0 ${Styles.userName}`}>{name}</h4>
        <div className="d-flex align-items-center">
        {(selected?.host?.pk === user?.pk || (isJoined && selected.status === 'VIDEO')) && (
          <Button
            label="START VIDEO CHAT"
            cssClass={Styles.createRoomWidth}
            src={socialPlayFavIcon}
            alt="chat info"
            imageClass={Styles.chatHeaderImg}
            clickHandler={handleJoin}
            image
          />)}

          {isJoined && (
            <div
              onClick={showChatInfo}
              onKeyPress={showChatInfo}
              role="button"
              tabIndex={0}
            >
              <img
                src={chatInfoImage}
                alt="chat info"
                className={Styles.chatInfoImage}
              />
            </div>
          )}
          {selected?.host?.pk === user?.pk && (
            <>
              <div
                onClick={handleDeleteRoom}
                onKeyPress={handleDeleteRoom}
                role="button"
                tabIndex={0}
              >
                <img
                  src={deleteIcon}
                  alt="delete icon"
                  className={Styles.deleteImage}
                />
              </div>
              <div
                className="position-relative"
                onClick={() => setJoinRequest(true)}
                onKeyPress={() => setJoinRequest(true)}
                role="button"
                tabIndex={0}
              >
                <img
                  src={userRequestIcon}
                  alt="delete icon"
                  className={Styles.requestImage}
                />
                <div className={Styles.userRequestCounter}>
                  {requests.length}
                </div>
              </div>
            </>
          )}
          {selected?.pk && !isJoined && (
            <div
              onClick={handleJoinRoom}
              onKeyPress={handleJoinRoom}
              role="button"
              tabIndex={0}
            >
              <img
                src={joinImage}
                alt="chat info"
                className={Styles.chatInfoImage}
              />
            </div>
          )}
        </div>

        {modalToggle && (
          <CustomModal
            modalTitle={
              "Your request to join this room has been sent to the host"
            }
            wrapperClass={Styles.publicProfileWrapper}
            heightClass={Styles.publicProfileBody}
            closeDialog={closeDialog}
            modalTitleClass={Styles.modalTitleClass}
            closeModal={closeModal}
            close
          >
            {joinToggleMsg()}
          </CustomModal>
        )}
        {joinRequest && (
        <CustomModal
          title="Join Requests"
          wrapperClass={Styles.publicProfileWrapper}
          heightClass={Styles.publicProfileBody}
          closeDialog={closeDialog}
        >
          {joinRequests()}
        </CustomModal>
      )}

        {/* {toast && (
          <Toast
            message={toastValues.message}
            position="topRight"
            type={toastValues.type}
            handleCloseToast={setToast}
          />
        )} */}
      </div>
    </>
  );
};

const mapDispatchToProps = { invitePeople: invitePeopleRequest };

export default connect(null, mapDispatchToProps)(ChatHeader);