import React, {FunctionComponent, useEffect, useState} from 'react';
import Button from 'components/button/Button';
import InputField from 'components/textField/InputField';
import Styles from 'views/feedBack/FeedBack.module.scss';
import TextArea from 'components/textArea/TextArea';

interface FeedBackParams {
  feedBackRequest: Function;
  loading: boolean;
  response: string;
}
const FeedBack: FunctionComponent<FeedBackParams> = (props: FeedBackParams) => {
  const { feedBackRequest, loading, response } = props;
  const user: any = JSON.parse(localStorage.getItem('user') || '');
  const [name, setName] = useState<string>(user.name || '');
  const [email, setEmail] = useState<string>(user.email || '');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResponse, setShowResponse] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleRequest = async () => {
    if (name === '') {
      setEmail(user.name);
    }
    if (email === '') {
      setEmail(user.email);
    }
    feedBackRequest({
      name,
      email,
      message
    });
  };

  useEffect(() => {
    if (message !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [message]);

  useEffect(() => {setIsLoading(loading)}, [loading]);

  useEffect(() => {
    if (response !== '') {
      setShowResponse(true);

      setTimeout(function(){
        setShowResponse(false);
      }, 5000);
    }
  }, [response]);

  return (
    <div className="borderStyle">
      <div className={Styles.wrapper}>
        <h4 className={Styles.heading}>Send feedback</h4>
        <p className={`mb-0 ${Styles.detail}`}>
          We would like to know your feedback to help us improve <br /> the desktop version or our app and your experience <br/> using it.
        </p>
        <div className={`mb-0 ${Styles.formWrapper}`}>
          <div className="form-group">
            <InputField
              handleChange={setName}
              type="text"
              value={name}
              placeholder="Name"
            />
          </div>
          <div className="form-group">
            <InputField
              handleChange={setEmail}
              type="email"
              value={email}
              placeholder="Email"
            />
          </div>
          <div className="form-group">
            <TextArea placeholder="Message" handleValue={setMessage} />
            {showResponse && <p style={{color: 'green'}}>{response}</p>}
          </div>
        </div>
        <div className={`text-center ${Styles.sendButton}`}>
          <Button clickHandler={handleRequest} disabled={disabled} showLoading={isLoading} label="Send" />
        </div>
      </div>
    </div>
  );
};

export default FeedBack;
