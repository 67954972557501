import React, {FunctionComponent, useEffect, useState} from 'react';
import joinButtonImage from 'assets/svg/join button.svg';
import Button from 'components/button/Button';
import superHostKey from 'assets/images/superhost_user_key.png';
import { useHistory } from 'react-router';
import Styles from './NearbyItem.module.scss';
import userAvatar from 'assets/images/user.png';
import { IMAGE_URL } from 'store/services/URL';
import { useGetRooms } from 'store/services/firebase-broadcast';

interface NearbyItemProps {
  item: any;
  conversations: any;
  handleExpand: (e: React.FormEvent<HTMLButtonElement>) => void;
  joinRequest: Function;
  isJoined: boolean;
  roomWithFees: Function;
  roomWithNoFees: Function;
  joinRoom: Function;
  viewProfile: Function;
  profile: Function;
}

const NearbyItem: FunctionComponent<NearbyItemProps> = (props: NearbyItemProps) => {
  const { handleExpand, item, roomWithFees, roomWithNoFees, joinRoom, viewProfile, profile, conversations } = props;
  const history = useHistory();
  const participants = item.participants;
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [joined, setJoined] = useState<boolean>(false);
  const host = item.host;

  let allRooms = useGetRooms()

  const handleClick = () => {
    // history.push({ pathname: '/chat-room', state: { conversations, selected: item } });
  };

  const handleViewProfile = (item: any) => {
    viewProfile(true);
    profile(item);
  };

  const handleJoin = () => {
    if (item.host.is_super_host) {
      if (joined) {
        history.push({ pathname: '/call', state: { selectedBroadCast: item} });
      } else {
        roomWithNoFees(true);
        joinRoom(item);
      }
    } else {
      if (joined) {
        history.push({ pathname: '/call', state: { selectedBroadCast: item} });
      } else {
        if (item.participants.length >= 3) {
          roomWithFees(true);
          joinRoom(item);
        } else {
          history.push({ pathname: '/call', state: { selectedBroadCast: item} });
          //   joinRequest({
          //     id: user.pk,
          //   });
        }
      }
    }
  };

  useEffect(() => {
    if (participants.length > 0) {
      participants.map((i: any) => {
        if (i.pk === user.pk || host.pk === user.pk) {
          setJoined(true);
          return '';
        }
        return '';
      });
    } else {
      if (host.pk === user.pk) {
        setJoined(true);
      }
    }
  }, []);

  const chatBtn = () => {
      let filterItem = allRooms.find((r: any) => r.pk === item.pk)
      history.push({pathname: '/chat-room', state: {conversations: conversations, selected: filterItem}});
  }

  return (
    <>
      <div className={Styles.itemWrapper}>
        <div className={Styles.itemInner}>
          <div className={item.status === "VIDEO" ?`${Styles.cardHeaderVideo}` : `${Styles.cardHeader}`} onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={0}>
            <h4 className="text-white fontSixteen m-0">{item.topic}</h4>
          </div>
          <div className={Styles.userImageWrapper} onClick={() => handleViewProfile(host)} onKeyPress={() => handleViewProfile(host)} role="button" tabIndex={0}>
            <img src={`${IMAGE_URL}${host.profile}`} alt="card img" className={Styles.userImage} />
            {host.is_super_host && <img src={superHostKey} alt="super host" className={Styles.superHostImg} />}
          </div>
          <div className="text-center">
            <h5 className={`text-black fontSixteen font-weight-600 my-0 ${Styles.hostName}`}>{host.name}</h5>
            <p className="input-color fontThirteen font-weight-500 mb-0">Host</p>
          </div>
          <div className={Styles.divider} />
          <div className={`customScrollBar ${Styles.participantsWrapper}`}>
            {participants.slice(0, 5).map((participant: any, index: number) => (
              <div className={Styles.participantsInner} key={index} onClick={() => handleViewProfile(participant)} onKeyPress={() => handleViewProfile(participant)} role="button" tabIndex={0}>
                {participant.profile === null ?
                  <img src={userAvatar} alt="participants" className={Styles.userImage} />
                  :
                  <img src={`${IMAGE_URL}${participant.profile}`} alt="participants" className={Styles.userImage} />
                }
                <p className="mb-0 text-black fontThirteen font-weight-500">{participant.name}</p>
              </div>
            ))}
          </div>
          <div className={Styles.divider} />
          {host.pk === user.pk && (
          <div className="text-center" onClick={handleJoin} onKeyUp={handleJoin} role="button" tabIndex={0}>
            <img src={joinButtonImage} alt="join button" className={Styles.joinButton} />
          </div>
          )}
          {host.pk !== user.pk && (item.status === 'VIDEO' ? (
          <div className="text-center" onClick={handleJoin} onKeyUp={handleJoin} role="button" tabIndex={0}>
            <img src={joinButtonImage} alt="join button" className={Styles.joinButton} />
          </div>
          ) : (
            <Button
              label="Chat"
              // cssClass={Styles.expandButton}
              cssClass={Styles.chatButton}
              clickHandler={chatBtn}
            />))}
          {participants.length > 5 && (
            <Button
              label="Expand Room"
              cssClass={Styles.expandButton}
              clickHandler={handleExpand}
              icon
              iconClass="fas fa-plus"
            />
          )}
        </div>
      </div>
    </>
  );
};
export default NearbyItem;
