import {
  ICreateRoomParams,
  IURLIdParams,
  INearByRoomsParams, IRoomStatusParams,
  IRoomTokenParams,
  IRoomNotiEmailParams
} from 'modules/params/param.type';
import { apiCall } from 'store/services/service';
import {
  URL_CREATE_ROOM,
  URL_ROOM_LIST,
  URL_JOIN_ROOM,
  URL_ROOMS_NEARBY,
  URL_ROOM_STATUS,
  URL_ROOM_TOKEN,
  URL_NOTIFICATION_EMAIL
} from 'store/services/URL';

export const api = {
  createRoomRequest(params: ICreateRoomParams) {
    return apiCall.post({ url: URL_CREATE_ROOM, isAuthToken: true, params });
  },
  getRoomRequest() {
    return apiCall.get({ url: URL_CREATE_ROOM, isAuthToken: true, params: {} });
  },
  joinRoomRequest(params: IURLIdParams) {
    return apiCall.postURLWithId({ url: URL_JOIN_ROOM, isAuthToken: true, params: params });
  },
  getAllRoomsRequest() {
    return apiCall.get({ url: URL_ROOM_LIST, isAuthToken: true, params: {} });
  },
  nearByRoomsRequest(params: INearByRoomsParams) {
    return apiCall.post({ url: URL_ROOMS_NEARBY, isAuthToken: true, params: params });
  },
  roomStatusRequest(params: IRoomStatusParams) {
    return apiCall.postURLAndBody({ url: URL_ROOM_STATUS, isAuthToken: true, params: params });
  },
  getRoomToken(params: IRoomTokenParams) {
    return apiCall.post({ url: URL_ROOM_TOKEN, isAuthToken: true, params: params });
  },
  sendNotificationEmail(params: IRoomNotiEmailParams) {
    return apiCall.post({url: URL_NOTIFICATION_EMAIL, isAuthToken: true, params: params });
  }
};

export default api;
