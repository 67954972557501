import React, {FunctionComponent, useEffect, useState} from 'react';
import Styles from 'views/dateAndTime/DateAndTime.module.scss';
import Button from 'components/button/Button';
import SwitchButton from 'components/button/switchButton/SwitchButton';
import arrowRight from 'assets/svg/right arrow_blue.svg';
import TimeFormat from 'components/timeFormat/TimeFormat';
import DateFormat from 'components/dateFormat/DateFormat';
import CustomModal from 'components/customModal/CustomModal';
import Toast from 'components/toast/Tost';

interface DateAndTimeProps {
  getSettingRequest: Function;
  updateSetting: Function;
  loading: boolean;
  setting: boolean;
  settingSuccess: string;
}
const DateAndTime: FunctionComponent<DateAndTimeProps> = (props: DateAndTimeProps) => {
  const { getSettingRequest, updateSetting, loading, setting, settingSuccess } = props;
  // @ts-ignore
  const { is_default, date_format, time_format } = setting;
  const [isDefault, setIsDefault] = useState<boolean>(is_default || false);
  const [date, setDate] = useState<string>(date_format || 'MM/DD/YY');
  const [time, setTime] = useState<string>(time_format || 'HH:MM XM');
  const [show, setShow] = useState<boolean>(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastValues, setToastValues] = useState({message: '', type: ''});
  const handleCancel = () => {};
  const handleChanges = () => {
    setShow(true);
    updateSetting({
      date_format: date,
      time_format: time,
      is_default: isDefault,
    });
    setToast(true);
    setToastValues({
      message: "Your changes have been saved.",
      type: "Success",
    });
  };
  const closeDialog = () => {
    setShowTimeModal(false);
    setShowDateModal(false);
  };

  const handleDateValue = (e: string) => {
    setShowDateModal(false);
    if (e) {
      setDate(e);
    }
  };

  const handleTimeValue = (e: string) => {
    setShowTimeModal(false);
    if (e) {
      setTime(e);
    }
  };

  const handleDefault = () => {
    setIsDefault(!isDefault);
  };

  useEffect(() => {
    getSettingRequest({});
  }, []);

  useEffect(() => {
    setIsDefault(is_default);
    setDate(date_format);
    setTime(time_format);
  }, [setting]);

  useEffect(() => {
    if (settingSuccess) {
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  }, [loading]);

  return (
    <>
      <div className="borderStyle">
        <div className="d-flex justify-content-between">
          <div>
            <p className="mb-0 fontSixteen poppins-regular text-black">Automatic date and time</p>
            <span className="fontThirteen poppins-regular input-color">Use network-provided date and time</span>
          </div>
          <SwitchButton isChecked={isDefault} handleChange={handleDefault} />
        </div>
        <div className="d-flex justify-content-between mt-60">
          <div>
            <p className="mb-0 fontSixteen poppins-regular text-black">Date Format</p>
            <span className="fontThirteen poppins-regular input-color">{date}</span>
          </div>
          <div
            onClick={() => setShowDateModal(true)}
            onKeyPress={() => setShowDateModal(true)}
            role="button"
            tabIndex={0}
          >
            <img src={arrowRight} alt="arrow right" className={Styles.iconWidth} />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-60">
          <div>
            <p className="mb-0 fontSixteen poppins-regular text-black">Time Format</p>
            <span className="fontThirteen poppins-regular input-color">{time}</span>
          </div>
          <div
            onClick={() => setShowTimeModal(true)}
            onKeyPress={() => setShowTimeModal(true)}
            role="button"
            tabIndex={0}
          >
            <img src={arrowRight} alt="arrow right" className={Styles.iconWidth} />
          </div>
        </div>
      </div>
      {/*{show && <p className="mt-2" style={{color: 'green'}}>{settingSuccess}</p>}*/}
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} showLoading={loading} clickHandler={handleChanges} />
      </div>
      {showDateModal && (
        <CustomModal closeDialog={closeDialog} wrapperClass={Styles.wrapperModal}>
          <DateFormat title="Date Format" handleClose={(e: string) => handleDateValue(e)} />
        </CustomModal>
      )}
      {showTimeModal && (
        <CustomModal closeDialog={closeDialog} wrapperClass={Styles.wrapperModal}>
          <TimeFormat title="Time Format" handleClose={(e: string) => handleTimeValue(e)} />
        </CustomModal>
      )}
      {toast && (
        <Toast
          message={toastValues.message}
          position="topRight"
          type={toastValues.type}
          handleCloseToast={setToast}
        />
      )}
    </>
  );
};

export default DateAndTime;
