import React, { FC, useEffect, useState, Suspense } from 'react';
import superHostKey from 'assets/images/superhost_user_key.png';
import goldPieceIcon from 'assets/svg/gold-piece.svg';
import silverPieceIcon from 'assets/svg/silver-piece.svg';
import Styles from 'views/currency/Currency.module.scss';
import Button from 'components/button/Button';
import CustomModal from 'components/customModal/CustomModal';
import RechargeAccountItem from 'components/rechargeAccountItem/RechargeAccountItem';
import subscriptions from 'helpers/data/subscriptions';
import { IMAGE_URL } from 'store/services/URL';
import { Plans } from 'modules/types/types';
const Stripe = React.lazy(() => import('views/Stripe/Stripe'));

interface CurrencyProps {
  cssClass?: string;
  getPlansRequest: Function;
  getPlans: Plans;
}

const Currency: FC<CurrencyProps> = (props: CurrencyProps) => {
  const { cssClass, getPlansRequest, getPlans } = props;
  const [rechargeAccount, setRechargeAccount] = useState(false);
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [selected, setSelected] = useState('');
  const [plans, setPlans] = useState<Plans>();
  const user = JSON.parse(localStorage.getItem('user') || '');
  const handleCancel = () => {
    console.log('handle Cancel');
  };
  const handleChanges = () => {
    console.log('handle Changes');
  };
  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setRechargeAccount(false);
  };
  const rechargeAccountModal = () => {
    document.body.classList.add('modal-open');
    return (
      <>
        <div className={Styles.rechargeCard}>
          {subscriptions.map((item, key) => (
            <RechargeAccountItem item={item} selected={selected} handleSelect={setSelected} key={key} />
          ))}
        </div>
        <div className="text-center">
          <Button label="PROCEED TO PAYMENT MERCHANT" disabled={selected === ''} cssClass="mt-5 mb-3" clickHandler={handlePaymentMerchant} />
          <div
            className="fontFourteen text-blue font-weight-bold text-uppercase"
            onClick={() => closeDialog()}
            onKeyPress={() => closeDialog()}
            role="button"
            tabIndex={0}
          >
            Close
          </div>
        </div>
      </>
    );
  };

  const stripePaymentModal = () => {
    document.body.classList.add('modal-open');
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Stripe type="recharge" planId={selected} closeDialog={setPaymentPopUp} />
      </Suspense>
    );
  };

  const handlePaymentMerchant = () => {
    setRechargeAccount(false);
    setPaymentPopUp(true);
  };

  useEffect(() => {
    getPlansRequest({});
  }, []);

  useEffect(() => {
    setPlans(getPlans);
  }, [getPlans]);

  return (
    <>
      <div className={`borderStyle ${Styles.currencyWrapper}`}>
        <div className={`${Styles.userImageWrapper} ${cssClass}`}>
          <img src={`${IMAGE_URL}${user?.profile}`} alt="profile" className={Styles.userImage} />
          {user?.is_super_host && (
            <img src={superHostKey} alt="super host" className={Styles.superHostImg} />
          )}
        </div>
        <div className="text-center">
          <h3>{user?.name}</h3>
          {user?.is_super_host && <p>SUPER HOST</p>}
          <p className={Styles.accountBalance}>ACCOUNT BALANCE</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className={Styles.cardWrapper}>
              <h3 className="mb-0">
                {user?.gold_coin} Gold
                <br />
                Pieces
              </h3>
              <img src={goldPieceIcon} className={Styles.goldImage} alt="gold icon" />
            </div>
          </div>
          <div className="col-md-6">
            <div className={Styles.cardWrapper}>
              <h3 className="mb-0">
                {user?.silver_coin} Silver
                <br />
                Piece
              </h3>
              <img src={silverPieceIcon} className={Styles.silverImage} alt="gold icon" />
            </div>
          </div>
        </div>
        <div
          className="fontFourteen text-blue font-weight-bold text-center
        text-uppercase mt-5"
          onClick={() => setRechargeAccount(true)}
          onKeyPress={() => setRechargeAccount(true)}
          role="button"
          tabIndex={0}
        >
          + RECHARGE ACCOUNT
        </div>
      </div>
      <div className={Styles.btnWrapper}>
        <Button label="Cancel" cssClass={Styles.cancelBtn} clickHandler={handleCancel} />
        <Button label="Save Changes" cssClass={`${Styles.saveBtn}`} clickHandler={handleChanges} />
      </div>
      {rechargeAccount && (
        <CustomModal closeDialog={closeDialog} title="Recharge Account" wrapperClass={Styles.rechargeAccountWrapper}>
          {rechargeAccountModal()}
        </CustomModal>
      )}
      {paymentPopUp && (
        <CustomModal closeDialog={closeDialog} title="Recharge Account" wrapperClass={Styles.rechargeAccountWrapper}>
          {stripePaymentModal()}
        </CustomModal>
      )}
    </>
  );
};
export default Currency;
