import React, { FunctionComponent } from 'react';
import Styles from 'views/explainerVideo/ExplainerVideo.module.scss';

const ExplainerVideo: FunctionComponent = () => {

    return (
        <div className="pb-5">
      <div className={`borderStyle`}>
        <div className={Styles.explainerVideo}>
        <p className={Styles.heading}>How does <span className={Styles.headingSpan}>Social Play</span> work?</p>
            <video controls={true} autoPlay={true} width="100%" height="100%"
                poster="https://ucb99db7d979add0f92ec97d22de.previews.dropboxusercontent.com/p/thumb/ABrl7--pShxNPo21tjjPuwfD2_Be9s4ONYIIAZhcxEtjxulC4qeAILuJ3g6cWWiGe50yYUprHVTdFohYPbumosQu9pV-3wifM0Sq0XQw_RV5ciy1iTCUYSr16vgkP3aHfLSV7BIKhFAbnKuZ2E-GKuwaMew6GClwCKOuuyktxPYn-THPQvFTGNsrgQdN-NdQH-WWRtu7SsvT_BLHbER6uID79BE3BF0F-6sMSbsexfAYCucEyAMI2_QHLH8sxZzNQM-EYApl63pOOZLywX9x2F8aQaY4OMDVtpWM9fqqkicDGK5LjACU8MT8xBa4vZhNGgqMUV8M_Uk0LVvuhbYMQisMhrEXeHqEoI1BeLUrCOFjn1V2hxcg215A6DQab9a9nYG6nsibpCUXnGPRwGn-heRVpvej9eD-ZZ75L2WUMhD7vAhfEiAiGEveG5VB2-UCK3SCSlraOApvPyIUg9QZP9hg5qATuA5I2Z3bjC8ChBj3QO0k5ww5l0kfvGz6sk__G8GAf2FgksTKEoDIAR1G-WbihNDBFb7qQptSV-fh10A9dtwtR0mnz8J2_QdhwGDBP5Gk8Jl5YW-SNYBeTbbm4DpwXW1wC6GLgYaIqxTZBryEaJNzuSyUTxY6qTr6-Xv6GgoJKLng_UrExvojM0mKaaxkP6uNURmzlTHh9nnpUSYjgVGyakSRPVvHpmQIhUJQlP-WCiqXy6xPnutP-yv229wEqNHMqlra9Kqg4ZUgQQDi_Tdxw6msAHSzjHIjxbphsvz7jTLzGY0hgUSkgig7yRSFqEG8YFoEgbFt1B1ZFmFJ-KaJpRNbZzmam1H3t2zpOFcmNrZxKnUcNRZhUJzBxZpaVWrd1JMsd1u-TirsFLrjZ1Mpoh_dV0wHbGBxYavhrjqygZl1Y-j3PYGQ1tgCNbji73B93tOq2Cz7IQEXwHxzYE-ko9E8vxjTGmcsJ0eciAoYTskdUs4HWG5ebcX_Ma6RXTZ2sgOhH_rF_MROqfr91A/p.jpeg?psid=979c5553-d5a2-447b-a8b9-266640796726&size=1024x768&size_mode=2">
                <source src="https://www.dropbox.com/s/c9xcee48e5kzdve/Social%20Play%20720p%20Final.mp4?raw=1" type="video/mp4"/>
            </video>
        </div>
      </div>
    </div>
    )

}

export default ExplainerVideo;