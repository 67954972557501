import { connect } from 'react-redux';
import FeedBack from 'views/feedBack/FeedBack';
import { feedBackRequest } from 'store/actions/general.actions';
import { getLoading, getFeedBack } from 'store/selector/general.selector';

const mapDispatchToProps = { feedBackRequest }

const mapStateToProps = (state: any) => {
  const loading = getLoading(state);
  const response = getFeedBack(state);
  return { loading, response };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);