import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Profile from 'views/profile';
import Header from 'components/header/index';
import DashboardLink from 'components/DashboardLink/DashboardLink';
import LeftNavBar from 'components/leftNavBar/LeftNavBar';
import Styles from 'views/profile/landing/MenuLanding.module.scss';
import PrivacyPolicy from 'views/privacyPolicy/PrivacyPolicy';
import TermAndConditions from 'views/termAndConditions/TermAndConditions';
import Subscription from 'views/subscription';
import FeedBack from 'views/feedBack';
import Notifications from 'views/notifications';
import DateAndTime from 'views/dateAndTime';
import Currency from 'views/currency';
import InviteFriend from 'views/inviteFriend';
import ExplainerVideo from 'views/explainerVideo/ExplainerVideo';
import EmailNotifications from 'views/emailNotifications';

const MenuLanding: FunctionComponent = () => {
  return (
    <div>
      <Header />
      <div className="custom-container">
        <DashboardLink iconClass="fas fa-chevron-left" cssClass="mt-40 mb-45" />
        <div className={Styles.menuLandingWrapper}>
          <div className={Styles.leftSide}>
            <LeftNavBar />
          </div>
          <div className={Styles.rightSide}>
            <Switch>
              <Route exact path="/subscription">
                <Subscription />
              </Route>
              <Route exact path="/explainer-video">
                <ExplainerVideo />
              </Route>
              <Route exact path="/invite-friend">
                <InviteFriend />
              </Route>
              <Route exact path="/currency">
                <Currency />
              </Route>
              <Route exact path="/profile/edit">
                <Profile />
              </Route>
              <Route exact path="/date-and-time">
                <DateAndTime />
              </Route>
              <Route exact path="/notifications">
                <Notifications />
              </Route>
              <Route exact path="/email-notifications">
                <EmailNotifications />
              </Route>
              <Route exact path="/feedback">
                <FeedBack />
              </Route>
              <Route exact path="/terms-and-conditions">
                <TermAndConditions />
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuLanding;
