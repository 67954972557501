import { connect } from 'react-redux';
import PublicProfile from 'views/profile/publicProfile/PublicProfile';
import { getPublicProfileBegin, followProfileBegin } from 'store/actions/auth.actions';
import { getLoader, getUser, publicProfile } from 'store/selector/auth.selector';

const mapDispatchToProps = { getPublicProfile: getPublicProfileBegin, followProfile: followProfileBegin };

const mapStateToProps = (state: any) => {
  const user = getUser(state);
  const loader = getLoader;
  const profile = publicProfile(state);
  return { user, profile, loader };
};
export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile);
