import React, { FC,useEffect, useState } from 'react';
import superHostImage from 'assets/svg/superhost-user-key-indicator.svg';
import Styles from 'components/chatContent/chatInfo/ChatInfo.module.scss';
import CustomModal from 'components/customModal/CustomModal';
import Button from 'components/button/Button';
import LightBox from '../../lightBox/LightBox';
import { IMAGE_URL } from 'store/services/URL';
import { deleteParticipants } from 'store/services/firebase-broadcast';
import minus from 'assets/images/minus.png';
import Toast from 'components/toast/Tost';

interface ChatInfoProps {
  cssClass?: string;
  info: any;
  setSelected?: any;
  allRooms?: any;
}

const ChatInfo: FC<ChatInfoProps> = (props: ChatInfoProps) => {
  const { cssClass, info, allRooms, setSelected } = props;
  const [viewParticipants, setViewParticipants] = useState(false);
  const [viewMedia, setViewMedia] = useState(false);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [index, setIndex] = useState(0);
  const [toast, setToast] = useState(false);
  const [toastValues, setToastValues] = useState({message: '', type: ''});
  const [deleteRoom, setDeleteRoom] = useState(false);

  const user = JSON.parse(localStorage.getItem('user') || '');
  const openParticipants = () => {
    document.body.classList.add('modal-open');
    setViewParticipants(true);
  };

  const photosAndMedia = () => {
    document.body.classList.add('modal-open');
    setViewMedia(true);
  };

  const closeDialog = () => {
    document.body.classList.remove('modal-open');
    setViewParticipants(false);
    setViewMedia(false);
    setOpenLightBox(false);
  };

  const viewAllParticipants = () => (<>
      <div className={Styles.viewAllWrapper}>
        {info ? info?.participants?.map((item: any, index: number) => (
          <div className={`text-center ${Styles.viewAllInner}`} key={index}>
            <img src={`${IMAGE_URL}${item.profile}`} alt="participants" loading="lazy" />
            <p className="my-0 fontThirteen font-weight-normal text-black">{item.name}</p>
          </div>
        )) : null}
      </div>
      <div className="text-center">
        <Button label="close" cssClass={Styles.closeBtn} clickHandler={closeDialog} />
      </div>
    </>);

  const ViewAllMedia = () => (<>
      <div className={Styles.customHeader}>
        <h3>Photos & Media</h3>
        <p className={Styles.tagLine}>{info.attachments.length} photos & 0 video</p>
      </div>
      <div className={Styles.allMedia}>
        {info ? info.attachments.map((item: any, i: number) => (
          <div id ={`potot-${i}`} className={Styles.allMediaInner} key={i}>
            <div
              className={Styles.mediaTileWrapper}
              onClick={() => {
                setOpenLightBox(true);
                setIndex(i);
              }}
              onKeyPress={() => {
                setOpenLightBox(true);
                setIndex(i);
              }}
              role="button"
              tabIndex={0}
            >
              <img src={item} alt="media" />
            </div>
          </div>
        )) : null}
      </div>
      <div className="text-center mt-5">
        <Button label="close" cssClass={Styles.closeBtn} clickHandler={closeDialog} />
      </div>
    </>);

    useEffect(() => {
      if (deleteRoom) {
        let updatedRoom = allRooms.find(function(e: any) {
          return e.pk == info.pk;
        })
        setSelected(updatedRoom)
        setDeleteRoom(true)
      }
    }, [deleteRoom])

  const handleDelete = (item: any, index: number) => {
    
    const data = {
      roomId: info?.pk,
      participantId: index,
    };
    deleteParticipants(data).then((e) => {
      setDeleteRoom(true)
      setToast(true);
      setToastValues({
        message: "User has been removed.",
        type: "Success",
      });
    }).catch(() => {
      setToastValues({
        message: 'Something went wrong!',
        type: 'Error',
      });
      setToast(true);
    });
    return
  };

  return (
    <div className={Styles.messageThreadContainer}>
      <div className={`${Styles.messageThreadWrapper} ${cssClass} customScrollBar`}>
        <div className={`text-center ${Styles.userDetails}`}>
          <div className={`position-relative ${Styles.userImageWrapper}`}>
            <img src={`${IMAGE_URL}${info?.host?.profile}`} alt="user img" loading="lazy" />
            {info?.host?.is_super_host && <img src={superHostImage} alt="super host" className={Styles.superHostImage} />}
          </div>
          <h5 className="text-black fontSixteen font-weight-600 my-0">{info?.topic}</h5>
          <span className="text-uppercase input-color fontFifteen font-weight-normal">{info?.host?.name}&apos;s Room</span>
        </div>
        <div className={Styles.chatParticipants}>
          <h6 className="fontSixteen font-weight-normal text-black">Chat Participants ({Object.keys(info.participants ?? {}).length > 0 ? info.participants.length : 0})</h6>
          <div className={Styles.participantsWrapper}>
            { info?.participants?.length > 0 && info.participants.map((item: any, index: number) => (
              <div>
              <img src={`${IMAGE_URL}${item.profile}`} alt="participants" loading="lazy" key={index}   role="button"/>
              {
                info?.host?.pk === user?.pk && (
                  <img
                  onClick={() => handleDelete(item, index)} onKeyUp={() => handleDelete(item, index)}
                  src={minus}
                  alt="super host"
                  className={Styles.superHostImg}/>
                )
              }
            </div>
            ))} 
          </div>
          {info?.participants?.length > 0 && (
            <div onClick={openParticipants} onKeyPress={openParticipants} role="button" tabIndex={0}>
              <p className="text-blue font-weight-bold my-3 fontThirteen">View All</p>
            </div>
          )}
        </div>
        <div className={Styles.photosAndMedia}>
          <h6 className="fontSixteen font-weight-normal text-black">Photos & Media</h6>
          <div className={Styles.photosMediaWrapper}>
            {info.hasOwnProperty('attachments') && info.attachments.length > 0 && (
              info.attachments.map((item: any, i: number) => (
                  <div id ={`potot-${i}`} className={Styles.allMediaInner} key={i}>
                    <div
                      className={Styles.mediaTileWrapper}
                      onClick={() => {
                        setOpenLightBox(true);
                        setIndex(i);
                      }}
                      onKeyPress={() => {
                        setOpenLightBox(true);
                        setIndex(i);
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <img src={item} alt="media" loading="lazy" key={index}/>
                    </div>
                  </div>
              ))
            )}
          </div>
        </div>
        {info.hasOwnProperty('attachments') && info.attachments.length > 0 && (
          <div onClick={photosAndMedia} onKeyPress={photosAndMedia} role="button" tabIndex={0}>
            <p className="text-blue font-weight-bold my-3 fontThirteen">View All</p>
          </div>
        )}
      </div>
      {viewParticipants && (
        <CustomModal title="Chat Participants" wrapperClass={Styles.wrapperClass} closeDialog={closeDialog}>
          {viewAllParticipants()}
        </CustomModal>
      )}
      {viewMedia && (
        <CustomModal wrapperClass={Styles.viewMediaWrapper} closeDialog={closeDialog}>
          {ViewAllMedia()}
        </CustomModal>
      )}
      {openLightBox && <div>Marjao</div> && <LightBox data={info.attachments} index={index} closeDialog={closeDialog} />}
      {toast && (
        <Toast
          message={toastValues.message}
          position="topRight"
          type={toastValues.type}
          handleCloseToast={setToast}
        />
      )}
    </div>
  );
};
export default ChatInfo;
