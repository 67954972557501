import { fromJS } from 'immutable';
import ACTIONS from '../constants';

const initialState: any = fromJS({
  selectedBroadCast: {},
  isRoomCreated: false,
  isJoined: false,
  createdRoom: {},
  loading: false,
  statusUpdated: false,
});

export default function conversationReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.SET_SELECTED_BROADCAST: {
      return state.set('selectedBroadCast', action.payload);
    }

    case ACTIONS.CREATE_ROOM.PENDING: {
      return state.set('error', '').set('loading', true).set('isRoomCreated', false);
    }
    case ACTIONS.CREATE_ROOM.SUCCESS: {
      return state.set('error', '').set('loading', false).set('createdRoom', action.data.data).set('isRoomCreated', true);
    }
    case ACTIONS.CREATE_ROOM.ERROR: {
      return state.set('error', action.error).set('loading', false).set('isRoomCreated', false);
    }

    case ACTIONS.ROOM_LIST.PENDING: {
      return state.set('error', '').set('loading', true);
    }
    case ACTIONS.ROOM_LIST.SUCCESS: {
      return state.set('error', '').set('loading', false).set('rooms', action.data.data);
    }
    case ACTIONS.ROOM_LIST.ERROR: {
      return state.set('error', action.error).set('loading', false);
    }

    case ACTIONS.JOIN_ROOM.PENDING: {
      return state.set('error', '').set('loading', true).set('isJoined', false);
    }
    case ACTIONS.JOIN_ROOM.SUCCESS: {
      return state.set('error', '').set('loading', false).set('isJoined', true);
    }
    case ACTIONS.JOIN_ROOM.ERROR: {
      return state.set('error', action.error).set('loading', false).set('isJoined', false);
    }

    case ACTIONS.CREATE_ROOM_FETCH.PENDING: {
      return state.set('error', '').set('loading', true);
    }
    case ACTIONS.CREATE_ROOM_FETCH.SUCCESS: {
      return state.set('error', '').set('loading', false).set('users_rooms', action.data.data);
    }
    case ACTIONS.CREATE_ROOM_FETCH.ERROR: {
      return state.set('error', action.error).set('loading', false);
    }

    case ACTIONS.NEARBY_ROOMS.PENDING: {
      return state.set('error', '').set('loading', true);
    }
    case ACTIONS.NEARBY_ROOMS.SUCCESS: {
      return state.set('error', '').set('loading', false).set('nearByRooms', action.data);
    }
    case ACTIONS.NEARBY_ROOMS.ERROR: {
      return state.set('error', action.error).set('loading', false);
    }

    case ACTIONS.ROOM_STATUS.PENDING: {
      return state.set('error', '').set('loading', true);
    }
    case ACTIONS.ROOM_STATUS.SUCCESS: {
      return state.set('error', '').set('loading', false).set('statusUpdated', true);
    }
    case ACTIONS.ROOM_STATUS.ERROR: {
      return state.set('error', action.error).set('loading', false).set('statusUpdated', false);
    }

    default: {
      return state;
    }
  }
}
