import { connect } from 'react-redux';
import EmailNotifications from 'views/emailNotifications/EmailNotifications';
import { notificationSettingRequest, updateNotificationSettingRequest } from 'store/actions/general.actions';
import { getLoading, getNotificationSetting, getUpdatedNotificationSetting } from 'store/selector/general.selector';
import { getEmailNotifications } from 'store/services/firebase-broadcast';

const mapDispatchToProps = {
  getSettingRequest: notificationSettingRequest,
  updateSetting: updateNotificationSettingRequest,
};

const mapStateToProps = (state: any) => {
  const loading = getLoading(state);
  const setting = getNotificationSetting(state);
  const settingSuccess = getUpdatedNotificationSetting(state);
  const checkEmailNotify = getEmailNotifications()
  return {
    loading,
    setting,
    settingSuccess,
    checkEmailNotify
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailNotifications);
