import React, {FC, useEffect, useRef, useState} from 'react';
import userImage from 'assets/images/image1.png';
import closeImage from 'assets/svg/close.svg';
import ChatHeader from 'components/chatContent/chatHeader/ChatHeader';
import ChatFooter from 'components/chatContent/chatFooter/ChatFooter';
import Styles from 'components/chatContent/ChatContent.module.scss';
import MessageBox from './messageBox/MessageBox';
import { updateParticipantsInRoom, UpdateHostInRoom } from 'store/services/firebase-broadcast';
// import Loader from 'components/loader/loader';

interface ChatContentProps {
  viewReplies: Function;
  replyItems: Function;
  chatInfo: Function;
  chats: any;
  selected: any;
  actions: boolean;
  activateRoom?: any;
}
const ChatContent: FC<ChatContentProps> = (props: ChatContentProps) => {
  const { viewReplies, replyItems, chatInfo, chats, selected, actions, activateRoom } = props;
  const [typing, setTyping] = useState(false);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [images, setImages] = useState<any>([]);
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [chatContent] = useState(chats);
  const latestMsgId: any = useRef(null);
  useEffect(() => {}, [images]);
  useEffect(() => {
    if (selected) {
      if (selected.hasOwnProperty('participants') && selected.participants.length > 0) {
        selected.participants.map((i: any) => {
          if (i?.pk === user?.pk || selected?.host?.pk === user?.pk) {
            setIsJoined(true);
            return '';
          }
          // setIsJoined(false);
          // return '';
        });
      } else {
        if (selected?.host?.pk === user?.pk) {
          setIsJoined(true);
        } else {
          setIsJoined(false);
        }
      }
    }
  }, [selected]);
  
  let chatsPin = chats?.length > 0 ? chats.filter((r : any) => r.pin) : [];
  let pinnedCount = 0;
  const reverseChat = () => {
    console.log("SOOOOOOOOORTED =>>>>>>>>");
    chats.slice().reverse();
  
    chats.sort((a: any, b: any) => {
      return b.pin ? 1 : -1;
    });
  }
  
  useEffect(() => {
    reverseChat();
  },[chats]);
  useEffect(() => {
    reverseChat();
  },[])

  const removeImage = (imageId: any) => {
    setImages(images?.filter((i:any) => i.id !== imageId));
  }

  const updateLastSeen = (id: any) => {
    // Check if i am the host of the room =>>>
    const ImHost = selected?.host?.pk === user?.pk;
    if (ImHost) {
      if (latestMsgId.current !== null && ImHost && selected?.host?.lastSeenMessage !== id) {
        selected.host.lastSeenMessage = id;
        const obj = {
          roomId: selected?.pk,
          host: selected?.host,
        }
        UpdateHostInRoom(obj);
      }
    } else {
      const meUser = selected?.participants?.findIndex((p: any) => p?.pk == user?.pk) ?? -1;
      if (latestMsgId.current !== null && meUser !== -1 && selected?.participants[meUser]?.lastSeenMessage !== id) {
        selected.participants[meUser].lastSeenMessage = id;
        const obj = {
          roomId: selected?.pk,
          user: selected?.participants,
        }
        updateParticipantsInRoom(obj);
      }
    }
    return true;
  }

  return (
    <>
      <ChatHeader
        name={selected?.topic ?? 'Chat room'}
        chatInfo={chatInfo}
        selected={selected}
        isJoined={isJoined}
      />
      {!isJoined ? (
        <div className={Styles.joinRoom}>
          <p>Join a room to start chatting</p>
        </div>
      ) : (
        <>
          <div className={`customScrollBar ${Styles.chatContentWrapper}`}>
            {/*{chats.length === 0 && <div className={Styles.loader}><Loader /></div>}*/}
            {chats.length > 0 &&
              chats !== null &&
              chats.map((item: any, index: number) => {
                // if (!item) {
                //   return null;
                // }
                if (item.pin === true) {
                  pinnedCount +=1;
                }
                if (latestMsgId.current === null) {
                  latestMsgId.current = index;
                } else {
                  if (latestMsgId.current !== null && item && chats[latestMsgId.current] && (new Date(item.createdTime) > new Date(chats[latestMsgId.current].createdTime)))
                    latestMsgId.current = index;
                }
                return <MessageBox
                  selected={selected}
                  item={item}
                  replyItems={replyItems}
                  type=""
                  viewReplies={viewReplies}
                  key={index}
                  actions={actions}
                  chatsPin={chatsPin}
                  pinCount = { pinnedCount }
                />
              })}
            {// update the last seen status for this room.
                latestMsgId.current && chats[latestMsgId.current] && updateLastSeen(chats[latestMsgId.current].id) ? null : null
            }
          </div>
          {images.length > 0 && (
            <div className={Styles.attachedMedia}>
              {images.map((i: any, index: number) => (
                <div
                  className={`position-relative ${Styles.attachedInnerMedia}`}
                  key={index}
                >
                  {i.type === "video" ? (
                    <video
                      width="320"
                      height="240"
                      className={Styles.attachedFile}
                      controls
                    >
                      <source src={i.path} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={i.path}
                      className={Styles.attachedFile}
                      alt="attached-file"
                    />
                  )}
                  <div className={Styles.closeIconWrapper}
                    onClick={() => removeImage(i.id)}>
                    <img src={closeImage} alt="close-icon" />
                  </div>
                </div>
              ))}
            </div>
          )}
          {typing ? (
            <span
              className={`px-3 fontFifteen input-color font-weight-normal ${Styles.typingUser}`}
            >{`${user.name} is typing…`}</span>
          ) : (
            <div className="pb-2">&nbsp;</div>
          )}
          <ChatFooter
            images={(e: string) => setImages([...images, e])}
            attachedImages={images}
            chats={chatContent}
            typing={setTyping}
            selected={selected}
            activateRoom={activateRoom}
          />
        </>
      )}
    </>
  );
};
export default ChatContent;
