import React, {FC, useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from 'components/header/Header.container';
import Footer from 'components/footer/Footer';
import { User } from 'modules/user/types';
import Styles from 'views/chatScreen/ChatScreen.module.scss';
import backToDashboardImage from 'assets/svg/back-to-dashboard.svg';
import ChatSideBar from 'components/chatSideBar/ChatSideBar';
import ChatContent from 'components/chatContent/ChatContent';
import MessageThread from 'components/chatContent/messageThread/MessageThread';
import ChatInfo from 'components/chatContent/chatInfo/ChatInfo';
import { createRoom, getRoomStatus, updateRoomStatus, useGetMessages, useGetRooms } from 'store/services/firebase-broadcast';
import { ref, onValue } from 'firebase/database';
import { db, storage, reference, getDownloadURL, listAll } from 'utils/firebase-config';
import { getCurrentDate } from 'helpers/utils';
import Interval from '../../utils/Interval';

interface StartScreenProps {
  user: User;
}

const ChatScreen: FC<StartScreenProps> = (props: StartScreenProps) => {
  const [showReplies, setShowReplies] = useState(false);
  const [activateRoom, setActiveRoom] = useState<any>(null);
  const [chatInfo, setChatInfo] = useState(false);
  const [showThreads, setShowThreads] = useState<any>({});
  const location: any = useLocation();
  const user = JSON.parse(localStorage.getItem('user') || '');
  const [selected, setSelected] = useState<any>(location?.state?.selected ?? {});
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [chatContent, setChatContent] = useState<any>([]);
  const [myRooms, setMyRooms] = useState<any>([]);
  const [key, setKey] = useState(0);
  const [room, setRoom] = useState();
  const [files, setFiles] = useState<any>([]);
  const [roomStatus, setRoomStatus] = useState<any>([]);
  const [hover, setHover] = useState(false);
  const { pk }: any = selected ?? {};

  useEffect(() => {
      if (location?.state?.selected) {
        setSelected(location?.state?.selected)
      }
    }, [location?.state?.selected])

  useEffect(() => {
    if (location?.state?.conversations?.length > 0) {
      let roomList = location?.state?.conversations.filter(
        (rrr: any) => rrr?.host?.email === user.email
      );
      setMyRooms(roomList);
    }
  }, []);

  useEffect(() => {
    let checkRoomStatus = async () => {
      if (myRooms.length > 0) {
        for (const item of myRooms) {
          let _roomStatus = await getRoomStatus(item.pk);
          setRoomStatus(_roomStatus);
        };
      };
    };
    checkRoomStatus();
  }, [setMyRooms])

  const handleThreads = (e: any) => {
    setShowThreads(e);
  };

  const chat = useGetMessages(key);
  let allRooms = useGetRooms()

  useEffect(() => {
    if (pk !== undefined ) {
      setKey(pk);
    }
  }, [pk]);

  useEffect(() => {
    setChatContent(chat);
    const { id } = showThreads;
      chat.filter((i: any) => {
        if (i.id === id) {
          setShowThreads(i);
        }
      })
  }, [chat]);

  useEffect(() => {
    if (selected) {
      if (selected.hasOwnProperty('participants') && selected.participants.length > 0) {
        selected.participants.map((i: any) => {
          if (i?.pk === user?.pk || selected.host?.pk === user?.pk) {
            setIsJoined(true);
            return '';
          }
          setIsJoined(false);
          return '';
        });
      } else {
        if (selected.host?.pk === user?.pk) {
          setIsJoined(true);
        } else {
          setIsJoined(false);
        }
      }
    }
  }, [selected]);

  useEffect(() => {
    if (selected) {
      if (Object.keys(selected).length > 0 ) {
        onValue(ref(db, `rooms/${selected?.pk}`), (querySnapshot: any) => {
          setRoom(querySnapshot.val());
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    if (room === null) {
      const room = {
        // timeStamp: new Date(),
        roomId: selected?.pk,
        topic: selected.topic,
        host: selected.host,
        created_at: getCurrentDate(),
      };
      createRoom(room).then();
    }
  }, [room]);

  useEffect(() => {}, [chatContent]);

  useEffect(() => {
    if (selected && isJoined) {

      setChatInfo(true);
      setShowReplies(false);
      const listRef = reference(storage, `/${selected?.pk}`);
      selected.attachments = [];
      listAll(listRef)
        .then((res) => {
          res.prefixes.forEach((folderRef) => {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
  
          res.items.forEach(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            setFiles([url]);
            selected.attachments.push(url);
          });
        }).catch((error) => {
          console.log('Error : ', error);
      });
    }
  }, [selected]);

  const callBack =(item: any) => {
    setActiveRoom(item)
  }
  
  return (
    <>
      <Header />
      <div className="custom-container">
        <section className={Styles.bodyContent}>
          <div className={Styles.startScreenWrapper}>
            <ChatSideBar selected={selected} conversations={allRooms} setSelected={setSelected} activateRoom={activateRoom}  setActiveRoom={setActiveRoom} callBack={callBack} />
            <section className={`${Styles.chatSection} ${!isJoined && Styles.notJoinedWrapper} ${showReplies || chatInfo ? Styles.threadSection : ''} `}>
              <ChatContent
                key={Date.now()}
                replyItems={(e: any) => handleThreads(e)}
                viewReplies={() => {
                  setShowReplies(true);
                  setChatInfo(false);
                }}
                chatInfo={() => {
                  setChatInfo(!chatInfo);
                  setShowReplies(false);
                }}
                chats={chatContent}
                selected={selected}
                actions={showReplies}
                activateRoom={activateRoom}
              />
            </section>
            {chatInfo && <ChatInfo info={selected} setSelected={setSelected} allRooms={allRooms}/>}
            {showReplies && <MessageThread actions={showReplies} selected={selected} item={showThreads} hideReplies={(e: any) => setShowReplies(e)} />}
          </div>
        </section>
      </div>
      <Footer />
      <div className={Styles.backToDashboard}>
        <Link to="/conversations-nearby">
          <img src={backToDashboardImage} alt="back to dashboard" />
        </Link>
      </div>
    </>
  );
};
export default ChatScreen;
